.prag-mit-zuza-fr-schulen-und-klassenfahrten-mageschneiderte-reisen-nach-prag-mit-komplettem-programm-unterkunft-und-verpflegung-container {
  width: 100%;
  display: flex;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
  background-color: var(--dl-color-gray-black);
}
.prag-mit-zuza-fr-schulen-und-klassenfahrten-mageschneiderte-reisen-nach-prag-mit-komplettem-programm-unterkunft-und-verpflegung-container01 {
  width: 100%;
  height: 88px;
  display: flex;
  align-items: center;
  flex-direction: column;
  background-color: var(--dl-color-primary-main1);
}
.prag-mit-zuza-fr-schulen-und-klassenfahrten-mageschneiderte-reisen-nach-prag-mit-komplettem-programm-unterkunft-und-verpflegung-navbar {
  width: 1187px;
  display: flex;
  padding: var(--dl-space-space-doubleunit);
  max-width: 1000px;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.prag-mit-zuza-fr-schulen-und-klassenfahrten-mageschneiderte-reisen-nach-prag-mit-komplettem-programm-unterkunft-und-verpflegung-logo {
  fill: var(--dl-color-theme-secondary1);
  color: var(--dl-color-theme-secondary1);
  font-family: "Pano-Regular";
}
.prag-mit-zuza-fr-schulen-und-klassenfahrten-mageschneiderte-reisen-nach-prag-mit-komplettem-programm-unterkunft-und-verpflegung-right-side {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.prag-mit-zuza-fr-schulen-und-klassenfahrten-mageschneiderte-reisen-nach-prag-mit-komplettem-programm-unterkunft-und-verpflegung-links-container {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.prag-mit-zuza-fr-schulen-und-klassenfahrten-mageschneiderte-reisen-nach-prag-mit-komplettem-programm-unterkunft-und-verpflegung-navlink {
  transition: 0.3s;
  margin-right: var(--dl-space-space-doubleunit);
  text-decoration: none;
}
.prag-mit-zuza-fr-schulen-und-klassenfahrten-mageschneiderte-reisen-nach-prag-mit-komplettem-programm-unterkunft-und-verpflegung-navlink:hover {
  color: var(--dl-color-primary-100);
}
.prag-mit-zuza-fr-schulen-und-klassenfahrten-mageschneiderte-reisen-nach-prag-mit-komplettem-programm-unterkunft-und-verpflegung-container02 {
  display: flex;
  position: relative;
}
.prag-mit-zuza-fr-schulen-und-klassenfahrten-mageschneiderte-reisen-nach-prag-mit-komplettem-programm-unterkunft-und-verpflegung-link {
  color: var(--dl-color-gray-white);
  border: none;
  transition: 0.3s;
  padding-top: var(--dl-space-space-halfunit);
  padding-left: var(--dl-space-space-doubleunit);
  border-radius: 50px;
  padding-right: var(--dl-space-space-doubleunit);
  padding-bottom: var(--dl-space-space-halfunit);
  text-decoration: none;
  background-color: var(--dl-color-primary-main1);
}
.prag-mit-zuza-fr-schulen-und-klassenfahrten-mageschneiderte-reisen-nach-prag-mit-komplettem-programm-unterkunft-und-verpflegung-link:hover {
  color: var(--dl-color-primary-100);
  border: 2px;
  border-color: var(--dl-color-primary-108a14);
  background-color: var(--dl-color-theme-secondary2);
}
.prag-mit-zuza-fr-schulen-und-klassenfahrten-mageschneiderte-reisen-nach-prag-mit-komplettem-programm-unterkunft-und-verpflegung-burger-menu {
  display: none;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.prag-mit-zuza-fr-schulen-und-klassenfahrten-mageschneiderte-reisen-nach-prag-mit-komplettem-programm-unterkunft-und-verpflegung-burger-menu1 {
  width: 24px;
  height: 24px;
}
.prag-mit-zuza-fr-schulen-und-klassenfahrten-mageschneiderte-reisen-nach-prag-mit-komplettem-programm-unterkunft-und-verpflegung-mobile-menu {
  top: 0px;
  flex: 0 0 auto;
  left: 0px;
  width: 100%;
  height: 100%;
  display: none;
  padding: var(--dl-space-space-doubleunit);
  z-index: 100;
  position: absolute;
  flex-direction: column;
  justify-content: space-between;
  background-color: #fff;
}
.prag-mit-zuza-fr-schulen-und-klassenfahrten-mageschneiderte-reisen-nach-prag-mit-komplettem-programm-unterkunft-und-verpflegung-nav {
  flex: 0 0 auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.prag-mit-zuza-fr-schulen-und-klassenfahrten-mageschneiderte-reisen-nach-prag-mit-komplettem-programm-unterkunft-und-verpflegung-top {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-doubleunit);
  flex-direction: row;
  justify-content: space-between;
}
.prag-mit-zuza-fr-schulen-und-klassenfahrten-mageschneiderte-reisen-nach-prag-mit-komplettem-programm-unterkunft-und-verpflegung-close-menu {
  flex: 0 0 auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.prag-mit-zuza-fr-schulen-und-klassenfahrten-mageschneiderte-reisen-nach-prag-mit-komplettem-programm-unterkunft-und-verpflegung-icon03 {
  width: 24px;
  height: 24px;
}
.prag-mit-zuza-fr-schulen-und-klassenfahrten-mageschneiderte-reisen-nach-prag-mit-komplettem-programm-unterkunft-und-verpflegung-right-side1 {
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: space-between;
}
.prag-mit-zuza-fr-schulen-und-klassenfahrten-mageschneiderte-reisen-nach-prag-mit-komplettem-programm-unterkunft-und-verpflegung-links-container1 {
  display: flex;
  align-items: flex-start;
  margin-bottom: 16px;
  flex-direction: column;
  justify-content: space-between;
}
.prag-mit-zuza-fr-schulen-und-klassenfahrten-mageschneiderte-reisen-nach-prag-mit-komplettem-programm-unterkunft-und-verpflegung-text02 {
  margin-bottom: 8px;
}
.prag-mit-zuza-fr-schulen-und-klassenfahrten-mageschneiderte-reisen-nach-prag-mit-komplettem-programm-unterkunft-und-verpflegung-text03 {
  margin-bottom: 8px;
}
.prag-mit-zuza-fr-schulen-und-klassenfahrten-mageschneiderte-reisen-nach-prag-mit-komplettem-programm-unterkunft-und-verpflegung-text04 {
  margin-bottom: 8px;
}
.prag-mit-zuza-fr-schulen-und-klassenfahrten-mageschneiderte-reisen-nach-prag-mit-komplettem-programm-unterkunft-und-verpflegung-follow-container {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: space-between;
}
.prag-mit-zuza-fr-schulen-und-klassenfahrten-mageschneiderte-reisen-nach-prag-mit-komplettem-programm-unterkunft-und-verpflegung-text06 {
  padding-bottom: var(--dl-space-space-halfunit);
}
.prag-mit-zuza-fr-schulen-und-klassenfahrten-mageschneiderte-reisen-nach-prag-mit-komplettem-programm-unterkunft-und-verpflegung-icons-container {
  width: 100px;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  justify-content: space-between;
}
.prag-mit-zuza-fr-schulen-und-klassenfahrten-mageschneiderte-reisen-nach-prag-mit-komplettem-programm-unterkunft-und-verpflegung-link1 {
  display: contents;
}
.prag-mit-zuza-fr-schulen-und-klassenfahrten-mageschneiderte-reisen-nach-prag-mit-komplettem-programm-unterkunft-und-verpflegung-icon05 {
  width: 24px;
  height: 24px;
}
.prag-mit-zuza-fr-schulen-und-klassenfahrten-mageschneiderte-reisen-nach-prag-mit-komplettem-programm-unterkunft-und-verpflegung-link2 {
  display: contents;
}
.prag-mit-zuza-fr-schulen-und-klassenfahrten-mageschneiderte-reisen-nach-prag-mit-komplettem-programm-unterkunft-und-verpflegung-icon07 {
  width: 24px;
  height: 24px;
}
.prag-mit-zuza-fr-schulen-und-klassenfahrten-mageschneiderte-reisen-nach-prag-mit-komplettem-programm-unterkunft-und-verpflegung-link3 {
  display: contents;
}
.prag-mit-zuza-fr-schulen-und-klassenfahrten-mageschneiderte-reisen-nach-prag-mit-komplettem-programm-unterkunft-und-verpflegung-icon09 {
  width: 24px;
  height: 24px;
}
.prag-mit-zuza-fr-schulen-und-klassenfahrten-mageschneiderte-reisen-nach-prag-mit-komplettem-programm-unterkunft-und-verpflegung-container03 {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.prag-mit-zuza-fr-schulen-und-klassenfahrten-mageschneiderte-reisen-nach-prag-mit-komplettem-programm-unterkunft-und-verpflegung-pricing23 {
  width: 100%;
  height: auto;
  display: flex;
  overflow: hidden;
  position: relative;
  align-items: center;
  flex-shrink: 0;
  flex-direction: column;
  background-color: #31009a;
}
.prag-mit-zuza-fr-schulen-und-klassenfahrten-mageschneiderte-reisen-nach-prag-mit-komplettem-programm-unterkunft-und-verpflegung-max-width {
  gap: 48px;
  width: 100%;
  display: flex;
  z-index: 50;
  align-items: center;
  flex-direction: column;
  padding-bottom: 66px;
  background-color: #131842;
}
.prag-mit-zuza-fr-schulen-und-klassenfahrten-mageschneiderte-reisen-nach-prag-mit-komplettem-programm-unterkunft-und-verpflegung-container04 {
  width: 100%;
  display: flex;
  z-index: 100;
  box-shadow: 5px 5px 30px 0px #000000;
  align-items: center;
  flex-direction: column;
  padding-bottom: 34px;
  background-color: var(--dl-color-primary-main1);
}
.prag-mit-zuza-fr-schulen-und-klassenfahrten-mageschneiderte-reisen-nach-prag-mit-komplettem-programm-unterkunft-und-verpflegung-section-title {
  gap: var(--dl-space-space-unit);
  width: 100%;
  display: flex;
  max-width: 800px;
  align-items: center;
  flex-shrink: 0;
  flex-direction: column;
}
.prag-mit-zuza-fr-schulen-und-klassenfahrten-mageschneiderte-reisen-nach-prag-mit-komplettem-programm-unterkunft-und-verpflegung-image {
  width: 1152px;
  height: 510px;
  opacity: 0.7;
  object-fit: cover;
  border-radius: var(--dl-radius-radius-radius8);
}
.prag-mit-zuza-fr-schulen-und-klassenfahrten-mageschneiderte-reisen-nach-prag-mit-komplettem-programm-unterkunft-und-verpflegung-content {
  gap: 24px;
  width: 100%;
  display: flex;
  max-width: 800px;
  align-self: stretch;
  align-items: center;
  flex-direction: column;
}
.prag-mit-zuza-fr-schulen-und-klassenfahrten-mageschneiderte-reisen-nach-prag-mit-komplettem-programm-unterkunft-und-verpflegung-text07 {
  fill: var(--dl-color-theme-secondary1);
  color: var(--dl-color-theme-secondary1);
  font-size: 3rem;
  text-align: center;
  font-family: "Poppins";
  font-weight: 700;
  line-height: 1.55;
  text-transform: none;
  text-decoration: none;
}
.prag-mit-zuza-fr-schulen-und-klassenfahrten-mageschneiderte-reisen-nach-prag-mit-komplettem-programm-unterkunft-und-verpflegung-text09 {
  font-style: italic;
  text-decoration: underline;
}
.prag-mit-zuza-fr-schulen-und-klassenfahrten-mageschneiderte-reisen-nach-prag-mit-komplettem-programm-unterkunft-und-verpflegung-text10 {
  font-style: italic;
}
.prag-mit-zuza-fr-schulen-und-klassenfahrten-mageschneiderte-reisen-nach-prag-mit-komplettem-programm-unterkunft-und-verpflegung-text12 {
  fill: var(--dl-color-theme-secondary2);
  color: var(--dl-color-theme-secondary2);
  text-align: center;
}
.prag-mit-zuza-fr-schulen-und-klassenfahrten-mageschneiderte-reisen-nach-prag-mit-komplettem-programm-unterkunft-und-verpflegung-text13 {
  font-style: italic;
}
.prag-mit-zuza-fr-schulen-und-klassenfahrten-mageschneiderte-reisen-nach-prag-mit-komplettem-programm-unterkunft-und-verpflegung-text14 {
  font-style: italic;
}
.prag-mit-zuza-fr-schulen-und-klassenfahrten-mageschneiderte-reisen-nach-prag-mit-komplettem-programm-unterkunft-und-verpflegung-container05 {
  gap: 32px;
  width: 600px;
  display: flex;
  align-self: center;
  transition: 0.3s;
  align-items: flex-start;
  flex-shrink: 0;
  animation-name: fadeIn;
  padding-bottom: 62px;
  animation-delay: 0s;
  justify-content: center;
  animation-duration: 300ms;
  animation-direction: normal;
  animation-iteration-count: 1;
  animation-timing-function: ease;
}
.prag-mit-zuza-fr-schulen-und-klassenfahrten-mageschneiderte-reisen-nach-prag-mit-komplettem-programm-unterkunft-und-verpflegung-container05:hover {
  width: 620px;
}
.prag-mit-zuza-fr-schulen-und-klassenfahrten-mageschneiderte-reisen-nach-prag-mit-komplettem-programm-unterkunft-und-verpflegung-column {
  gap: 32px;
  flex: 1;
  width: 600px;
  display: flex;
  flex-grow: 1;
  align-self: stretch;
  transition: 0.3s;
  align-items: center;
  padding-top: var(--dl-space-space-doubleunit);
  border-color: var(--dl-color-theme-neutral-dark);
  border-style: solid;
  border-width: 1px;
  padding-left: var(--dl-space-space-tripleunit);
  padding-right: var(--dl-space-space-tripleunit);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-doubleunit);
  background-size: cover,auto;
  background-image: linear-gradient(90deg, rgb(246, 246, 243) 0.00%,rgb(255, 255, 255) 100.00%);
  background-repeat: cover;
  background-position: bottom;
}
.prag-mit-zuza-fr-schulen-und-klassenfahrten-mageschneiderte-reisen-nach-prag-mit-komplettem-programm-unterkunft-und-verpflegung-column:hover {
  width: 620px;
  background-color: rgba(255, 255, 255, 0.94);
}
.prag-mit-zuza-fr-schulen-und-klassenfahrten-mageschneiderte-reisen-nach-prag-mit-komplettem-programm-unterkunft-und-verpflegung-body {
  gap: 32px;
  display: flex;
  flex-grow: 1;
  align-self: stretch;
  align-items: center;
  flex-direction: column;
}
.prag-mit-zuza-fr-schulen-und-klassenfahrten-mageschneiderte-reisen-nach-prag-mit-komplettem-programm-unterkunft-und-verpflegung-icon11 {
  width: 89px;
  height: 89px;
}
.prag-mit-zuza-fr-schulen-und-klassenfahrten-mageschneiderte-reisen-nach-prag-mit-komplettem-programm-unterkunft-und-verpflegung-price {
  gap: var(--dl-space-space-halfunit);
  display: flex;
  align-self: stretch;
  align-items: center;
  flex-direction: column;
}
.prag-mit-zuza-fr-schulen-und-klassenfahrten-mageschneiderte-reisen-nach-prag-mit-komplettem-programm-unterkunft-und-verpflegung-text15 {
  font-family: "Montserrat";
}
.prag-mit-zuza-fr-schulen-und-klassenfahrten-mageschneiderte-reisen-nach-prag-mit-komplettem-programm-unterkunft-und-verpflegung-text16 {
  font-size: 48px;
  font-family: "Montserrat";
}
.prag-mit-zuza-fr-schulen-und-klassenfahrten-mageschneiderte-reisen-nach-prag-mit-komplettem-programm-unterkunft-und-verpflegung-list {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-direction: column;
}
.prag-mit-zuza-fr-schulen-und-klassenfahrten-mageschneiderte-reisen-nach-prag-mit-komplettem-programm-unterkunft-und-verpflegung-list-item {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-shrink: 0;
}
.prag-mit-zuza-fr-schulen-und-klassenfahrten-mageschneiderte-reisen-nach-prag-mit-komplettem-programm-unterkunft-und-verpflegung-text18 {
  font-weight: 700;
}
.prag-mit-zuza-fr-schulen-und-klassenfahrten-mageschneiderte-reisen-nach-prag-mit-komplettem-programm-unterkunft-und-verpflegung-text19 {
  font-weight: 700;
}
.prag-mit-zuza-fr-schulen-und-klassenfahrten-mageschneiderte-reisen-nach-prag-mit-komplettem-programm-unterkunft-und-verpflegung-list-item1 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-shrink: 0;
}
.prag-mit-zuza-fr-schulen-und-klassenfahrten-mageschneiderte-reisen-nach-prag-mit-komplettem-programm-unterkunft-und-verpflegung-text22 {
  font-weight: 700;
}
.prag-mit-zuza-fr-schulen-und-klassenfahrten-mageschneiderte-reisen-nach-prag-mit-komplettem-programm-unterkunft-und-verpflegung-text24 {
  font-style: italic;
}
.prag-mit-zuza-fr-schulen-und-klassenfahrten-mageschneiderte-reisen-nach-prag-mit-komplettem-programm-unterkunft-und-verpflegung-list-item2 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-shrink: 0;
}
.prag-mit-zuza-fr-schulen-und-klassenfahrten-mageschneiderte-reisen-nach-prag-mit-komplettem-programm-unterkunft-und-verpflegung-text26 {
  font-weight: 700;
}
.prag-mit-zuza-fr-schulen-und-klassenfahrten-mageschneiderte-reisen-nach-prag-mit-komplettem-programm-unterkunft-und-verpflegung-list-item3 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-shrink: 0;
}
.prag-mit-zuza-fr-schulen-und-klassenfahrten-mageschneiderte-reisen-nach-prag-mit-komplettem-programm-unterkunft-und-verpflegung-text29 {
  font-weight: 700;
}
.prag-mit-zuza-fr-schulen-und-klassenfahrten-mageschneiderte-reisen-nach-prag-mit-komplettem-programm-unterkunft-und-verpflegung-list-item4 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-shrink: 0;
}
.prag-mit-zuza-fr-schulen-und-klassenfahrten-mageschneiderte-reisen-nach-prag-mit-komplettem-programm-unterkunft-und-verpflegung-text30 {
  text-decoration: underline;
}
.prag-mit-zuza-fr-schulen-und-klassenfahrten-mageschneiderte-reisen-nach-prag-mit-komplettem-programm-unterkunft-und-verpflegung-list-item5 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-shrink: 0;
}
.prag-mit-zuza-fr-schulen-und-klassenfahrten-mageschneiderte-reisen-nach-prag-mit-komplettem-programm-unterkunft-und-verpflegung-button {
  fill: var(--dl-color-primary-main1);
  color: var(--dl-color-primary-main1);
  width: 100%;
  transition: 0.3s;
  padding-top: var(--dl-space-space-unit);
  border-color: #e68369;
  border-width: 0px;
  padding-left: var(--dl-space-space-unit);
  border-radius: var(--dl-radius-radius-buttonradius);
  padding-right: var(--dl-space-space-unit);
  padding-bottom: var(--dl-space-space-unit);
  text-decoration: none;
  background-color: var(--dl-color-theme-secondary1);
}
.prag-mit-zuza-fr-schulen-und-klassenfahrten-mageschneiderte-reisen-nach-prag-mit-komplettem-programm-unterkunft-und-verpflegung-button:hover {
  color: var(--dl-color-primary-main1);
  box-shadow: 5px 5px 10px 0px #d4d4d4;
}
.prag-mit-zuza-fr-schulen-und-klassenfahrten-mageschneiderte-reisen-nach-prag-mit-komplettem-programm-unterkunft-und-verpflegung-text32 {
  font-family: "Montserrat";
}
.prag-mit-zuza-fr-schulen-und-klassenfahrten-mageschneiderte-reisen-nach-prag-mit-komplettem-programm-unterkunft-und-verpflegung-text33 {
  display: none;
}
.prag-mit-zuza-fr-schulen-und-klassenfahrten-mageschneiderte-reisen-nach-prag-mit-komplettem-programm-unterkunft-und-verpflegung-panel-3 {
  box-shadow: 5px 5px 30px 0px #000000;
}
.prag-mit-zuza-fr-schulen-und-klassenfahrten-mageschneiderte-reisen-nach-prag-mit-komplettem-programm-unterkunft-und-verpflegung-max-width1 {
  gap: 32px;
  width: 2952px;
  display: flex;
  z-index: 60;
  position: relative;
  box-shadow: -10px -10px 30px 0px #000000,10px 10px 30px 0px rgba(6, 13, 23, 0.85);
  align-items: flex-start;
  padding-top: 83px;
  border-radius: var(--dl-radius-radius-radius4);
  flex-direction: column;
  background-color: var(--dl-color-primary-main1);
}
.prag-mit-zuza-fr-schulen-und-klassenfahrten-mageschneiderte-reisen-nach-prag-mit-komplettem-programm-unterkunft-und-verpflegung-timeline {
  flex: 1;
  width: 1164px;
  height: 100%;
  display: flex;
  align-self: center;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.prag-mit-zuza-fr-schulen-und-klassenfahrten-mageschneiderte-reisen-nach-prag-mit-komplettem-programm-unterkunft-und-verpflegung-step {
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-direction: row;
  justify-content: flex-start;
}
.prag-mit-zuza-fr-schulen-und-klassenfahrten-mageschneiderte-reisen-nach-prag-mit-komplettem-programm-unterkunft-und-verpflegung-container06 {
  gap: var(--dl-space-space-unit);
  width: 50%;
  display: flex;
  align-items: flex-end;
  padding-top: 0px;
  padding-left: var(--dl-space-space-unit);
  padding-right: var(--dl-space-space-unit);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-unit);
  justify-content: flex-start;
}
.prag-mit-zuza-fr-schulen-und-klassenfahrten-mageschneiderte-reisen-nach-prag-mit-komplettem-programm-unterkunft-und-verpflegung-text34 {
  fill: var(--dl-color-primary-mud);
  color: var(--dl-color-primary-mud);
  font-family: "Roboto Mono";
}
.prag-mit-zuza-fr-schulen-und-klassenfahrten-mageschneiderte-reisen-nach-prag-mit-komplettem-programm-unterkunft-und-verpflegung-asd {
  fill: var(--dl-color-theme-secondary2);
  color: var(--dl-color-theme-secondary2);
  text-align: right;
  font-family: "Roboto Mono";
}
.prag-mit-zuza-fr-schulen-und-klassenfahrten-mageschneiderte-reisen-nach-prag-mit-komplettem-programm-unterkunft-und-verpflegung-text37 {
  text-align: right;
  font-family: "Montserrat";
}
.prag-mit-zuza-fr-schulen-und-klassenfahrten-mageschneiderte-reisen-nach-prag-mit-komplettem-programm-unterkunft-und-verpflegung-text38 {
  font-weight: 700;
}
.prag-mit-zuza-fr-schulen-und-klassenfahrten-mageschneiderte-reisen-nach-prag-mit-komplettem-programm-unterkunft-und-verpflegung-text39 {
  color: #fff4e8;
  font-style: italic;
}
.prag-mit-zuza-fr-schulen-und-klassenfahrten-mageschneiderte-reisen-nach-prag-mit-komplettem-programm-unterkunft-und-verpflegung-text40 {
  color: #fff4e8;
  font-style: italic;
}
.prag-mit-zuza-fr-schulen-und-klassenfahrten-mageschneiderte-reisen-nach-prag-mit-komplettem-programm-unterkunft-und-verpflegung-text42 {
  font-weight: 700;
}
.prag-mit-zuza-fr-schulen-und-klassenfahrten-mageschneiderte-reisen-nach-prag-mit-komplettem-programm-unterkunft-und-verpflegung-text43 {
  color: var(--dl-color-theme-secondary2);
  font-style: italic;
}
.prag-mit-zuza-fr-schulen-und-klassenfahrten-mageschneiderte-reisen-nach-prag-mit-komplettem-programm-unterkunft-und-verpflegung-text46 {
  font-weight: 700;
}
.prag-mit-zuza-fr-schulen-und-klassenfahrten-mageschneiderte-reisen-nach-prag-mit-komplettem-programm-unterkunft-und-verpflegung-text47 {
  color: var(--dl-color-theme-secondary2);
  font-style: italic;
  font-weight: 700;
}
.prag-mit-zuza-fr-schulen-und-klassenfahrten-mageschneiderte-reisen-nach-prag-mit-komplettem-programm-unterkunft-und-verpflegung-text48 {
  color: var(--dl-color-theme-secondary2);
  font-style: italic;
}
.prag-mit-zuza-fr-schulen-und-klassenfahrten-mageschneiderte-reisen-nach-prag-mit-komplettem-programm-unterkunft-und-verpflegung-container07 {
  gap: var(--dl-space-space-unit);
  display: none;
  align-items: flex-start;
  flex-direction: row;
}
.prag-mit-zuza-fr-schulen-und-klassenfahrten-mageschneiderte-reisen-nach-prag-mit-komplettem-programm-unterkunft-und-verpflegung-button1 {
  font-style: italic;
  border-color: var(--dl-color-theme-secondary1);
  border-width: 0px;
  padding-left: var(--dl-space-space-unit);
  padding-right: var(--dl-space-space-unit);
  background-color: var(--dl-color-primary-100);
}
.prag-mit-zuza-fr-schulen-und-klassenfahrten-mageschneiderte-reisen-nach-prag-mit-komplettem-programm-unterkunft-und-verpflegung-button2 {
  fill: var(--dl-color-primary-100);
  color: var(--dl-color-primary-100);
  border-color: var(--dl-color-primary-100);
  padding-left: var(--dl-space-space-unit);
  padding-right: var(--dl-space-space-unit);
}
.prag-mit-zuza-fr-schulen-und-klassenfahrten-mageschneiderte-reisen-nach-prag-mit-komplettem-programm-unterkunft-und-verpflegung-container08 {
  flex: 1;
  left: 0;
  right: 0;
  width: auto;
  height: 100%;
  display: flex;
  position: absolute;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.prag-mit-zuza-fr-schulen-und-klassenfahrten-mageschneiderte-reisen-nach-prag-mit-komplettem-programm-unterkunft-und-verpflegung-container09 {
  width: 12px;
  height: 12px;
  margin: var(--dl-space-space-halfunit);
  border-radius: var(--dl-radius-radius-round);
  background-color: var(--dl-color-primary-100);
}
.prag-mit-zuza-fr-schulen-und-klassenfahrten-mageschneiderte-reisen-nach-prag-mit-komplettem-programm-unterkunft-und-verpflegung-progress0 {
  height: 100%;
  display: flex;
  align-items: flex-start;
  border-color: var(--dl-color-theme-accent2);
  border-style: solid;
  border-width: 2px;
  flex-direction: column;
  background-color: var(--dl-color-theme-secondary2);
}
.prag-mit-zuza-fr-schulen-und-klassenfahrten-mageschneiderte-reisen-nach-prag-mit-komplettem-programm-unterkunft-und-verpflegung-step1 {
  display: flex;
  position: relative;
  align-items: flex-start;
  justify-content: flex-end;
}
.prag-mit-zuza-fr-schulen-und-klassenfahrten-mageschneiderte-reisen-nach-prag-mit-komplettem-programm-unterkunft-und-verpflegung-container10 {
  flex: 1;
  left: 0;
  right: 0;
  width: auto;
  height: 100%;
  display: flex;
  position: absolute;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.prag-mit-zuza-fr-schulen-und-klassenfahrten-mageschneiderte-reisen-nach-prag-mit-komplettem-programm-unterkunft-und-verpflegung-container11 {
  width: 12px;
  height: 12px;
  margin: var(--dl-space-space-halfunit);
  border-radius: var(--dl-radius-radius-round);
  background-color: var(--dl-color-primary-mud);
}
.prag-mit-zuza-fr-schulen-und-klassenfahrten-mageschneiderte-reisen-nach-prag-mit-komplettem-programm-unterkunft-und-verpflegung-progress01 {
  height: 100%;
  display: flex;
  align-items: flex-start;
  border-color: var(--dl-color-theme-accent2);
  border-style: solid;
  border-width: 2px;
  flex-direction: column;
  background-color: var(--dl-color-theme-accent2);
}
.prag-mit-zuza-fr-schulen-und-klassenfahrten-mageschneiderte-reisen-nach-prag-mit-komplettem-programm-unterkunft-und-verpflegung-container12 {
  gap: var(--dl-space-space-unit);
  width: 50%;
  display: flex;
  align-items: flex-end;
  padding-top: 0px;
  padding-left: var(--dl-space-space-unit);
  padding-right: var(--dl-space-space-unit);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-unit);
  justify-content: flex-start;
}
.prag-mit-zuza-fr-schulen-und-klassenfahrten-mageschneiderte-reisen-nach-prag-mit-komplettem-programm-unterkunft-und-verpflegung-text50 {
  fill: var(--dl-color-primary-mud);
  color: var(--dl-color-primary-mud);
  font-family: "Roboto Mono";
}
.prag-mit-zuza-fr-schulen-und-klassenfahrten-mageschneiderte-reisen-nach-prag-mit-komplettem-programm-unterkunft-und-verpflegung-text51 {
  color: var(--dl-color-theme-secondary1);
  align-self: flex-start;
  text-align: right;
  font-family: "Roboto Mono";
}
.prag-mit-zuza-fr-schulen-und-klassenfahrten-mageschneiderte-reisen-nach-prag-mit-komplettem-programm-unterkunft-und-verpflegung-text52 {
  align-self: flex-start;
  text-align: left;
  font-family: "Montserrat";
}
.prag-mit-zuza-fr-schulen-und-klassenfahrten-mageschneiderte-reisen-nach-prag-mit-komplettem-programm-unterkunft-und-verpflegung-text53 {
  font-weight: 700;
}
.prag-mit-zuza-fr-schulen-und-klassenfahrten-mageschneiderte-reisen-nach-prag-mit-komplettem-programm-unterkunft-und-verpflegung-text54 {
  color: var(--dl-color-theme-secondary1);
  font-style: italic;
}
.prag-mit-zuza-fr-schulen-und-klassenfahrten-mageschneiderte-reisen-nach-prag-mit-komplettem-programm-unterkunft-und-verpflegung-text55 {
  font-family: "Montserrat";
}
.prag-mit-zuza-fr-schulen-und-klassenfahrten-mageschneiderte-reisen-nach-prag-mit-komplettem-programm-unterkunft-und-verpflegung-text57 {
  font-weight: 700;
}
.prag-mit-zuza-fr-schulen-und-klassenfahrten-mageschneiderte-reisen-nach-prag-mit-komplettem-programm-unterkunft-und-verpflegung-text58 {
  color: var(--dl-color-theme-secondary2);
  font-style: italic;
}
.prag-mit-zuza-fr-schulen-und-klassenfahrten-mageschneiderte-reisen-nach-prag-mit-komplettem-programm-unterkunft-und-verpflegung-container13 {
  flex: 1;
  left: 0;
  right: 0;
  width: auto;
  height: 100%;
  display: flex;
  position: absolute;
  align-items: center;
  flex-direction: column;
  padding-bottom: 78px;
  justify-content: center;
}
.prag-mit-zuza-fr-schulen-und-klassenfahrten-mageschneiderte-reisen-nach-prag-mit-komplettem-programm-unterkunft-und-verpflegung-container14 {
  width: 12px;
  height: 12px;
  margin: var(--dl-space-space-halfunit);
  border-radius: var(--dl-radius-radius-round);
  background-color: var(--dl-color-primary-100);
}
.prag-mit-zuza-fr-schulen-und-klassenfahrten-mageschneiderte-reisen-nach-prag-mit-komplettem-programm-unterkunft-und-verpflegung-progress02 {
  height: 100%;
  display: flex;
  align-items: flex-start;
  border-color: var(--dl-color-theme-accent2);
  border-style: solid;
  border-width: 2px;
  flex-direction: column;
}
.prag-mit-zuza-fr-schulen-und-klassenfahrten-mageschneiderte-reisen-nach-prag-mit-komplettem-programm-unterkunft-und-verpflegung-step2 {
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-direction: row;
  justify-content: flex-start;
}
.prag-mit-zuza-fr-schulen-und-klassenfahrten-mageschneiderte-reisen-nach-prag-mit-komplettem-programm-unterkunft-und-verpflegung-container15 {
  gap: var(--dl-space-space-unit);
  width: 50%;
  display: flex;
  align-items: flex-end;
  padding-top: 0px;
  padding-left: var(--dl-space-space-unit);
  padding-right: var(--dl-space-space-unit);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-unit);
  justify-content: flex-start;
}
.prag-mit-zuza-fr-schulen-und-klassenfahrten-mageschneiderte-reisen-nach-prag-mit-komplettem-programm-unterkunft-und-verpflegung-text59 {
  fill: var(--dl-color-primary-mud);
  color: var(--dl-color-primary-mud);
  font-family: "Roboto Mono";
}
.prag-mit-zuza-fr-schulen-und-klassenfahrten-mageschneiderte-reisen-nach-prag-mit-komplettem-programm-unterkunft-und-verpflegung-text60 {
  fill: var(--dl-color-primary-mud);
  color: var(--dl-color-theme-secondary1);
  font-family: "Roboto Mono";
}
.prag-mit-zuza-fr-schulen-und-klassenfahrten-mageschneiderte-reisen-nach-prag-mit-komplettem-programm-unterkunft-und-verpflegung-text61 {
  text-align: right;
  font-family: "Montserrat";
}
.prag-mit-zuza-fr-schulen-und-klassenfahrten-mageschneiderte-reisen-nach-prag-mit-komplettem-programm-unterkunft-und-verpflegung-text63 {
  font-weight: 700;
}
.prag-mit-zuza-fr-schulen-und-klassenfahrten-mageschneiderte-reisen-nach-prag-mit-komplettem-programm-unterkunft-und-verpflegung-text66 {
  font-weight: 700;
}
.prag-mit-zuza-fr-schulen-und-klassenfahrten-mageschneiderte-reisen-nach-prag-mit-komplettem-programm-unterkunft-und-verpflegung-text67 {
  color: var(--dl-color-theme-secondary1);
}
.prag-mit-zuza-fr-schulen-und-klassenfahrten-mageschneiderte-reisen-nach-prag-mit-komplettem-programm-unterkunft-und-verpflegung-text68 {
  color: var(--dl-color-theme-secondary1);
  font-style: italic;
}
.prag-mit-zuza-fr-schulen-und-klassenfahrten-mageschneiderte-reisen-nach-prag-mit-komplettem-programm-unterkunft-und-verpflegung-text69 {
  font-style: italic;
}
.prag-mit-zuza-fr-schulen-und-klassenfahrten-mageschneiderte-reisen-nach-prag-mit-komplettem-programm-unterkunft-und-verpflegung-text72 {
  color: var(--dl-color-theme-secondary2);
  font-style: italic;
}
.prag-mit-zuza-fr-schulen-und-klassenfahrten-mageschneiderte-reisen-nach-prag-mit-komplettem-programm-unterkunft-und-verpflegung-text73 {
  color: var(--dl-color-primary-mud);
  font-style: italic;
  font-weight: 700;
}
.prag-mit-zuza-fr-schulen-und-klassenfahrten-mageschneiderte-reisen-nach-prag-mit-komplettem-programm-unterkunft-und-verpflegung-text74 {
  color: var(--dl-color-theme-secondary2);
  font-style: italic;
}
.prag-mit-zuza-fr-schulen-und-klassenfahrten-mageschneiderte-reisen-nach-prag-mit-komplettem-programm-unterkunft-und-verpflegung-text75 {
  color: var(--dl-color-theme-secondary2);
  font-style: italic;
}
.prag-mit-zuza-fr-schulen-und-klassenfahrten-mageschneiderte-reisen-nach-prag-mit-komplettem-programm-unterkunft-und-verpflegung-text78 {
  color: var(--dl-color-theme-secondary1);
  font-style: italic;
  font-weight: 700;
}
.prag-mit-zuza-fr-schulen-und-klassenfahrten-mageschneiderte-reisen-nach-prag-mit-komplettem-programm-unterkunft-und-verpflegung-container16 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-items: flex-start;
  flex-direction: row;
}
.prag-mit-zuza-fr-schulen-und-klassenfahrten-mageschneiderte-reisen-nach-prag-mit-komplettem-programm-unterkunft-und-verpflegung-button3 {
  display: none;
  font-size: 14px;
  font-style: italic;
  border-color: var(--dl-color-theme-secondary1);
  border-width: 0px;
  padding-left: var(--dl-space-space-unit);
  padding-right: var(--dl-space-space-unit);
  background-color: var(--dl-color-primary-100);
}
.prag-mit-zuza-fr-schulen-und-klassenfahrten-mageschneiderte-reisen-nach-prag-mit-komplettem-programm-unterkunft-und-verpflegung-contacte {
  font-size: 14px;
  font-style: italic;
  border-color: var(--dl-color-theme-secondary1);
  border-width: 0px;
  padding-left: var(--dl-space-space-unit);
  padding-right: var(--dl-space-space-unit);
  text-decoration: none;
  background-color: var(--dl-color-primary-100);
}
.prag-mit-zuza-fr-schulen-und-klassenfahrten-mageschneiderte-reisen-nach-prag-mit-komplettem-programm-unterkunft-und-verpflegung-button4 {
  fill: var(--dl-color-primary-100);
  color: var(--dl-color-theme-secondary1);
  display: none;
  font-size: 17px;
  border-color: var(--dl-color-primary-100);
  padding-left: var(--dl-space-space-unit);
  padding-right: var(--dl-space-space-unit);
}
.prag-mit-zuza-fr-schulen-und-klassenfahrten-mageschneiderte-reisen-nach-prag-mit-komplettem-programm-unterkunft-und-verpflegung-button5 {
  color: var(--dl-color-gray-white);
  border: none;
  display: none;
  font-size: 10px;
  align-self: center;
  transition: 0.3s;
  font-family: Poppins;
  font-weight: 600;
  padding-top: 11px;
  padding-left: var(--dl-space-space-doubleunit);
  border-radius: 50px;
  padding-right: var(--dl-space-space-doubleunit);
  padding-bottom: 11px;
  text-transform: none;
  text-decoration: none;
  background-color: #108a14;
}
.prag-mit-zuza-fr-schulen-und-klassenfahrten-mageschneiderte-reisen-nach-prag-mit-komplettem-programm-unterkunft-und-verpflegung-container17 {
  flex: 1;
  left: 0;
  right: 0;
  width: auto;
  height: 100%;
  display: none;
  position: absolute;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.prag-mit-zuza-fr-schulen-und-klassenfahrten-mageschneiderte-reisen-nach-prag-mit-komplettem-programm-unterkunft-und-verpflegung-container18 {
  flex: 1;
  left: 0;
  right: 0;
  width: auto;
  height: 100%;
  display: flex;
  position: absolute;
  align-items: center;
  flex-direction: column;
  padding-bottom: 78px;
  justify-content: center;
}
.prag-mit-zuza-fr-schulen-und-klassenfahrten-mageschneiderte-reisen-nach-prag-mit-komplettem-programm-unterkunft-und-verpflegung-container19 {
  width: 12px;
  height: 12px;
  margin: var(--dl-space-space-halfunit);
  border-radius: var(--dl-radius-radius-round);
  background-color: var(--dl-color-primary-100);
}
.prag-mit-zuza-fr-schulen-und-klassenfahrten-mageschneiderte-reisen-nach-prag-mit-komplettem-programm-unterkunft-und-verpflegung-progress03 {
  height: 100%;
  display: flex;
  align-items: flex-start;
  border-color: var(--dl-color-theme-accent2);
  border-style: solid;
  border-width: 2px;
  flex-direction: column;
}
.prag-mit-zuza-fr-schulen-und-klassenfahrten-mageschneiderte-reisen-nach-prag-mit-komplettem-programm-unterkunft-und-verpflegung-faq1-question {
  fill: var(--dl-color-theme-secondary1);
  color: var(--dl-color-theme-secondary1);
  font-size: 24px;
  align-self: center;
  font-style: italic;
  text-align: left;
  font-family: "Merriweather";
  font-weight: bold;
  padding-top: 59px;
  padding-bottom: 10px;
}
.prag-mit-zuza-fr-schulen-und-klassenfahrten-mageschneiderte-reisen-nach-prag-mit-komplettem-programm-unterkunft-und-verpflegung-faq8 {
  width: 2715px;
  height: 1082px;
  display: flex;
  z-index: 10;
  overflow: hidden;
  position: relative;
  align-self: center;
  box-shadow: 0px 0px 200px 30px #000000;
  align-items: center;
  flex-shrink: 0;
  padding-top: 63px;
  padding-left: 589px;
  border-radius: var(--dl-radius-radius-radius4);
  padding-right: 589px;
  flex-direction: column;
  padding-bottom: 49px;
  background-size: cover;
  justify-content: center;
  background-image: url("/zuzana-manova-hero-2000h.webp");
  background-position: right;
}
.prag-mit-zuza-fr-schulen-und-klassenfahrten-mageschneiderte-reisen-nach-prag-mit-komplettem-programm-unterkunft-und-verpflegung-faq1-question1 {
  fill: var(--dl-color-primary-main1);
  color: var(--dl-color-primary-main1);
  display: none;
  align-self: flex-start;
  font-style: normal;
  text-align: left;
  font-family: "Merriweather";
  font-weight: bold;
  padding-bottom: 28px;
}
.prag-mit-zuza-fr-schulen-und-klassenfahrten-mageschneiderte-reisen-nach-prag-mit-komplettem-programm-unterkunft-und-verpflegung-link4 {
  display: contents;
}
.prag-mit-zuza-fr-schulen-und-klassenfahrten-mageschneiderte-reisen-nach-prag-mit-komplettem-programm-unterkunft-und-verpflegung-image1 {
  width: 200px;
  display: none;
  box-shadow: 5px 5px 10px 0px #d4d4d4;
  object-fit: cover;
  transition: 0.3s;
  border-radius: var(--dl-radius-radius-buttonradius);
  text-decoration: none;
}
.prag-mit-zuza-fr-schulen-und-klassenfahrten-mageschneiderte-reisen-nach-prag-mit-komplettem-programm-unterkunft-und-verpflegung-image1:hover {
  display: flex;
  padding-bottom: 12px;
}
.prag-mit-zuza-fr-schulen-und-klassenfahrten-mageschneiderte-reisen-nach-prag-mit-komplettem-programm-unterkunft-und-verpflegung-max-width2 {
  gap: 48px;
  width: 1033px;
  align-self: center;
  align-items: flex-start;
  padding-bottom: var(--dl-space-space-doubleunit);
}
.prag-mit-zuza-fr-schulen-und-klassenfahrten-mageschneiderte-reisen-nach-prag-mit-komplettem-programm-unterkunft-und-verpflegung-list1 {
  flex: 1;
  width: auto;
  background-size: cover;
  background-image: linear-gradient(90deg, rgb(38, 0, 0) 0.00%,rgb(8, 2, 74) 100.00%),url('https://images.unsplash.com/photo-1573655349936-de6bed86f839?ixid=M3w5MTMyMXwwfDF8c2VhcmNofDE0fHx0ZXh0dXJlfGVufDB8fHx8MTcyMDQwMTkyNnww&ixlib=rb-4.0.3&w=2000');
  background-position: right;
}
.prag-mit-zuza-fr-schulen-und-klassenfahrten-mageschneiderte-reisen-nach-prag-mit-komplettem-programm-unterkunft-und-verpflegung-faq1 {
  width: 100%;
  display: flex;
  align-items: flex-start;
  border-color: #ffffff;
  border-width: 1px;
  flex-direction: column;
}
.prag-mit-zuza-fr-schulen-und-klassenfahrten-mageschneiderte-reisen-nach-prag-mit-komplettem-programm-unterkunft-und-verpflegung-trigger {
  display: flex;
  padding: var(--dl-space-space-unit);
  align-self: flex-start;
  align-items: center;
  flex-direction: row;
  justify-content: flex-start;
}
.prag-mit-zuza-fr-schulen-und-klassenfahrten-mageschneiderte-reisen-nach-prag-mit-komplettem-programm-unterkunft-und-verpflegung-faq1-question2 {
  fill: var(--dl-color-theme-secondary1);
  color: var(--dl-color-theme-secondary1);
  font-style: normal;
  text-align: left;
  font-family: "Merriweather";
  font-weight: 600;
}
.prag-mit-zuza-fr-schulen-und-klassenfahrten-mageschneiderte-reisen-nach-prag-mit-komplettem-programm-unterkunft-und-verpflegung-icons-container1 {
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  justify-content: space-between;
}
.prag-mit-zuza-fr-schulen-und-klassenfahrten-mageschneiderte-reisen-nach-prag-mit-komplettem-programm-unterkunft-und-verpflegung-icon25 {
  color: var(--dl-color-primary-100);
  width: 24px;
  height: 24px;
}
.prag-mit-zuza-fr-schulen-und-klassenfahrten-mageschneiderte-reisen-nach-prag-mit-komplettem-programm-unterkunft-und-verpflegung-icon27 {
  width: 24px;
  height: 24px;
}
.prag-mit-zuza-fr-schulen-und-klassenfahrten-mageschneiderte-reisen-nach-prag-mit-komplettem-programm-unterkunft-und-verpflegung-container22 {
  display: flex;
  padding: var(--dl-space-space-unit);
  align-self: stretch;
  align-items: flex-start;
  animation-name: fadeIn;
  flex-direction: row;
  animation-delay: 0s;
  justify-content: space-between;
  animation-duration: 300ms;
  animation-direction: normal;
  animation-iteration-count: 1;
  animation-timing-function: ease;
}
.prag-mit-zuza-fr-schulen-und-klassenfahrten-mageschneiderte-reisen-nach-prag-mit-komplettem-programm-unterkunft-und-verpflegung-text82 {
  fill: var(--dl-color-primary-mud);
  color: var(--dl-color-primary-mud);
  font-style: italic;
  font-family: "Fira Sans";
}
.prag-mit-zuza-fr-schulen-und-klassenfahrten-mageschneiderte-reisen-nach-prag-mit-komplettem-programm-unterkunft-und-verpflegung-faq11 {
  width: 100%;
  display: flex;
  align-items: flex-start;
  border-color: #ffffff;
  border-width: 1px;
  flex-direction: column;
}
.prag-mit-zuza-fr-schulen-und-klassenfahrten-mageschneiderte-reisen-nach-prag-mit-komplettem-programm-unterkunft-und-verpflegung-trigger1 {
  display: flex;
  padding: var(--dl-space-space-unit);
  align-self: flex-start;
  align-items: center;
  flex-direction: row;
  justify-content: flex-start;
}
.prag-mit-zuza-fr-schulen-und-klassenfahrten-mageschneiderte-reisen-nach-prag-mit-komplettem-programm-unterkunft-und-verpflegung-faq1-question3 {
  fill: var(--dl-color-theme-secondary1);
  color: var(--dl-color-theme-secondary1);
  font-style: normal;
  text-align: left;
  font-family: "Merriweather";
  font-weight: 600;
}
.prag-mit-zuza-fr-schulen-und-klassenfahrten-mageschneiderte-reisen-nach-prag-mit-komplettem-programm-unterkunft-und-verpflegung-icons-container2 {
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  justify-content: space-between;
}
.prag-mit-zuza-fr-schulen-und-klassenfahrten-mageschneiderte-reisen-nach-prag-mit-komplettem-programm-unterkunft-und-verpflegung-icon29 {
  color: var(--dl-color-primary-100);
  width: 24px;
  height: 24px;
}
.prag-mit-zuza-fr-schulen-und-klassenfahrten-mageschneiderte-reisen-nach-prag-mit-komplettem-programm-unterkunft-und-verpflegung-icon31 {
  width: 24px;
  height: 24px;
}
.prag-mit-zuza-fr-schulen-und-klassenfahrten-mageschneiderte-reisen-nach-prag-mit-komplettem-programm-unterkunft-und-verpflegung-container25 {
  display: flex;
  padding: var(--dl-space-space-unit);
  align-self: stretch;
  align-items: flex-start;
  animation-name: fadeIn;
  flex-direction: row;
  animation-delay: 0s;
  justify-content: space-between;
  animation-duration: 300ms;
  animation-direction: normal;
  animation-iteration-count: 1;
  animation-timing-function: ease;
}
.prag-mit-zuza-fr-schulen-und-klassenfahrten-mageschneiderte-reisen-nach-prag-mit-komplettem-programm-unterkunft-und-verpflegung-text85 {
  fill: var(--dl-color-primary-mud);
  color: var(--dl-color-primary-mud);
  font-style: italic;
  font-family: "Fira Sans";
}
.prag-mit-zuza-fr-schulen-und-klassenfahrten-mageschneiderte-reisen-nach-prag-mit-komplettem-programm-unterkunft-und-verpflegung-faq12 {
  width: 100%;
  display: flex;
  align-items: flex-start;
  border-color: #ffffff;
  border-width: 1px;
  flex-direction: column;
}
.prag-mit-zuza-fr-schulen-und-klassenfahrten-mageschneiderte-reisen-nach-prag-mit-komplettem-programm-unterkunft-und-verpflegung-trigger2 {
  display: flex;
  padding: var(--dl-space-space-unit);
  align-self: stretch;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.prag-mit-zuza-fr-schulen-und-klassenfahrten-mageschneiderte-reisen-nach-prag-mit-komplettem-programm-unterkunft-und-verpflegung-faq1-question4 {
  fill: var(--dl-color-theme-secondary1);
  color: var(--dl-color-theme-secondary1);
  font-style: normal;
  text-align: center;
  font-family: "Merriweather";
  font-weight: 600;
}
.prag-mit-zuza-fr-schulen-und-klassenfahrten-mageschneiderte-reisen-nach-prag-mit-komplettem-programm-unterkunft-und-verpflegung-icons-container3 {
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  justify-content: space-between;
}
.prag-mit-zuza-fr-schulen-und-klassenfahrten-mageschneiderte-reisen-nach-prag-mit-komplettem-programm-unterkunft-und-verpflegung-icon33 {
  color: var(--dl-color-primary-100);
  width: 24px;
  height: 24px;
}
.prag-mit-zuza-fr-schulen-und-klassenfahrten-mageschneiderte-reisen-nach-prag-mit-komplettem-programm-unterkunft-und-verpflegung-icon35 {
  width: 24px;
  height: 24px;
}
.prag-mit-zuza-fr-schulen-und-klassenfahrten-mageschneiderte-reisen-nach-prag-mit-komplettem-programm-unterkunft-und-verpflegung-container28 {
  display: flex;
  padding: var(--dl-space-space-unit);
  align-self: stretch;
  align-items: flex-start;
  margin-bottom: 13px;
  animation-name: fadeIn;
  flex-direction: row;
  animation-delay: 0s;
  justify-content: space-between;
  animation-duration: 300ms;
  animation-direction: normal;
  animation-iteration-count: 1;
  animation-timing-function: ease;
}
.prag-mit-zuza-fr-schulen-und-klassenfahrten-mageschneiderte-reisen-nach-prag-mit-komplettem-programm-unterkunft-und-verpflegung-text86 {
  fill: var(--dl-color-primary-mud);
  color: var(--dl-color-primary-mud);
  font-style: italic;
  font-family: "Fira Sans";
}
.prag-mit-zuza-fr-schulen-und-klassenfahrten-mageschneiderte-reisen-nach-prag-mit-komplettem-programm-unterkunft-und-verpflegung-faq13 {
  width: 100%;
  display: flex;
  align-items: flex-start;
  border-color: #ffffff;
  border-width: 1px;
  flex-direction: column;
}
.prag-mit-zuza-fr-schulen-und-klassenfahrten-mageschneiderte-reisen-nach-prag-mit-komplettem-programm-unterkunft-und-verpflegung-trigger3 {
  display: flex;
  padding: var(--dl-space-space-unit);
  align-self: stretch;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.prag-mit-zuza-fr-schulen-und-klassenfahrten-mageschneiderte-reisen-nach-prag-mit-komplettem-programm-unterkunft-und-verpflegung-faq1-question5 {
  fill: var(--dl-color-theme-secondary1);
  color: var(--dl-color-theme-secondary1);
  font-style: normal;
  text-align: center;
  font-family: "Merriweather";
  font-weight: 600;
}
.prag-mit-zuza-fr-schulen-und-klassenfahrten-mageschneiderte-reisen-nach-prag-mit-komplettem-programm-unterkunft-und-verpflegung-icons-container4 {
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  justify-content: space-between;
}
.prag-mit-zuza-fr-schulen-und-klassenfahrten-mageschneiderte-reisen-nach-prag-mit-komplettem-programm-unterkunft-und-verpflegung-icon37 {
  color: var(--dl-color-primary-100);
  width: 24px;
  height: 24px;
}
.prag-mit-zuza-fr-schulen-und-klassenfahrten-mageschneiderte-reisen-nach-prag-mit-komplettem-programm-unterkunft-und-verpflegung-icon39 {
  width: 24px;
  height: 24px;
}
.prag-mit-zuza-fr-schulen-und-klassenfahrten-mageschneiderte-reisen-nach-prag-mit-komplettem-programm-unterkunft-und-verpflegung-container31 {
  display: flex;
  padding: var(--dl-space-space-unit);
  align-self: stretch;
  align-items: flex-start;
  margin-bottom: 13px;
  animation-name: fadeIn;
  flex-direction: row;
  animation-delay: 0s;
  justify-content: space-between;
  animation-duration: 300ms;
  animation-direction: normal;
  animation-iteration-count: 1;
  animation-timing-function: ease;
}
.prag-mit-zuza-fr-schulen-und-klassenfahrten-mageschneiderte-reisen-nach-prag-mit-komplettem-programm-unterkunft-und-verpflegung-text91 {
  fill: var(--dl-color-primary-mud);
  color: var(--dl-color-primary-mud);
  font-style: italic;
  font-family: "Fira Sans";
}
.prag-mit-zuza-fr-schulen-und-klassenfahrten-mageschneiderte-reisen-nach-prag-mit-komplettem-programm-unterkunft-und-verpflegung-link5 {
  display: contents;
}
.prag-mit-zuza-fr-schulen-und-klassenfahrten-mageschneiderte-reisen-nach-prag-mit-komplettem-programm-unterkunft-und-verpflegung-image2 {
  width: 200px;
  box-shadow: 5px 5px 10px 0px #d4d4d4;
  object-fit: cover;
  transition: 0.3s;
  border-radius: var(--dl-radius-radius-buttonradius);
  text-decoration: none;
}
.prag-mit-zuza-fr-schulen-und-klassenfahrten-mageschneiderte-reisen-nach-prag-mit-komplettem-programm-unterkunft-und-verpflegung-image2:hover {
  width: 220;
  box-shadow: 5px 5px 30px 0px #22C427;
}
.prag-mit-zuza-fr-schulen-und-klassenfahrten-mageschneiderte-reisen-nach-prag-mit-komplettem-programm-unterkunft-und-verpflegung-button6 {
  color: var(--dl-color-gray-white);
  border: none;
  display: none;
  font-size: 10px;
  align-self: center;
  transition: 0.3s;
  font-family: Poppins;
  font-weight: 600;
  padding-top: 11px;
  padding-left: var(--dl-space-space-doubleunit);
  border-radius: 50px;
  padding-right: var(--dl-space-space-doubleunit);
  padding-bottom: 11px;
  text-transform: none;
  text-decoration: none;
  background-color: #108a14;
}
@media(max-width: 991px) {
  .prag-mit-zuza-fr-schulen-und-klassenfahrten-mageschneiderte-reisen-nach-prag-mit-komplettem-programm-unterkunft-und-verpflegung-text12 {
    color: var(--dl-color-theme-secondary2);
  }
  .prag-mit-zuza-fr-schulen-und-klassenfahrten-mageschneiderte-reisen-nach-prag-mit-komplettem-programm-unterkunft-und-verpflegung-text14 {
    font-style: inherit;
  }
  .prag-mit-zuza-fr-schulen-und-klassenfahrten-mageschneiderte-reisen-nach-prag-mit-komplettem-programm-unterkunft-und-verpflegung-container05 {
    width: 785px;
    flex-direction: column;
  }
  .prag-mit-zuza-fr-schulen-und-klassenfahrten-mageschneiderte-reisen-nach-prag-mit-komplettem-programm-unterkunft-und-verpflegung-column {
    width: 100%;
  }
  .prag-mit-zuza-fr-schulen-und-klassenfahrten-mageschneiderte-reisen-nach-prag-mit-komplettem-programm-unterkunft-und-verpflegung-text26 {
    font-weight: 700;
  }
  .prag-mit-zuza-fr-schulen-und-klassenfahrten-mageschneiderte-reisen-nach-prag-mit-komplettem-programm-unterkunft-und-verpflegung-button {
    fill: var(--dl-color-theme-secondary1);
    color: var(--dl-color-theme-secondary1);
    border-radius: var(--dl-radius-radius-buttonradius);
    background-color: var(--dl-color-primary-main1);
  }
  .prag-mit-zuza-fr-schulen-und-klassenfahrten-mageschneiderte-reisen-nach-prag-mit-komplettem-programm-unterkunft-und-verpflegung-text32 {
    font-family: Montserrat;
  }
  .prag-mit-zuza-fr-schulen-und-klassenfahrten-mageschneiderte-reisen-nach-prag-mit-komplettem-programm-unterkunft-und-verpflegung-panel-3 {
    padding-left: 231px;
    padding-right: 0px;
  }
  .prag-mit-zuza-fr-schulen-und-klassenfahrten-mageschneiderte-reisen-nach-prag-mit-komplettem-programm-unterkunft-und-verpflegung-step1 {
    height: 641px;
  }
  .prag-mit-zuza-fr-schulen-und-klassenfahrten-mageschneiderte-reisen-nach-prag-mit-komplettem-programm-unterkunft-und-verpflegung-container10 {
    height: 957px;
  }
  .prag-mit-zuza-fr-schulen-und-klassenfahrten-mageschneiderte-reisen-nach-prag-mit-komplettem-programm-unterkunft-und-verpflegung-container12 {
    padding-right: 319px;
  }
  .prag-mit-zuza-fr-schulen-und-klassenfahrten-mageschneiderte-reisen-nach-prag-mit-komplettem-programm-unterkunft-und-verpflegung-container13 {
    padding-bottom: 119px;
  }
  .prag-mit-zuza-fr-schulen-und-klassenfahrten-mageschneiderte-reisen-nach-prag-mit-komplettem-programm-unterkunft-und-verpflegung-step2 {
    padding-top: 109px;
  }
  .prag-mit-zuza-fr-schulen-und-klassenfahrten-mageschneiderte-reisen-nach-prag-mit-komplettem-programm-unterkunft-und-verpflegung-text60 {
    text-align: right;
  }
  .prag-mit-zuza-fr-schulen-und-klassenfahrten-mageschneiderte-reisen-nach-prag-mit-komplettem-programm-unterkunft-und-verpflegung-text61 {
    font-family: Montserrat;
    padding-bottom: var(--dl-space-space-tripleunit);
  }
  .prag-mit-zuza-fr-schulen-und-klassenfahrten-mageschneiderte-reisen-nach-prag-mit-komplettem-programm-unterkunft-und-verpflegung-text63 {
    font-weight: 700;
  }
  .prag-mit-zuza-fr-schulen-und-klassenfahrten-mageschneiderte-reisen-nach-prag-mit-komplettem-programm-unterkunft-und-verpflegung-text66 {
    font-size: 13px;
    font-weight: 700;
  }
  .prag-mit-zuza-fr-schulen-und-klassenfahrten-mageschneiderte-reisen-nach-prag-mit-komplettem-programm-unterkunft-und-verpflegung-text67 {
    color: var(--dl-color-theme-secondary1);
    font-size: 13px;
  }
  .prag-mit-zuza-fr-schulen-und-klassenfahrten-mageschneiderte-reisen-nach-prag-mit-komplettem-programm-unterkunft-und-verpflegung-text68 {
    color: var(--dl-color-theme-secondary1);
    font-size: 13px;
    font-style: italic;
  }
  .prag-mit-zuza-fr-schulen-und-klassenfahrten-mageschneiderte-reisen-nach-prag-mit-komplettem-programm-unterkunft-und-verpflegung-text69 {
    font-size: 13px;
    font-style: italic;
  }
  .prag-mit-zuza-fr-schulen-und-klassenfahrten-mageschneiderte-reisen-nach-prag-mit-komplettem-programm-unterkunft-und-verpflegung-text70 {
    font-size: 13px;
  }
  .prag-mit-zuza-fr-schulen-und-klassenfahrten-mageschneiderte-reisen-nach-prag-mit-komplettem-programm-unterkunft-und-verpflegung-text72 {
    color: var(--dl-color-theme-secondary2);
    font-size: 13px;
    font-style: italic;
  }
  .prag-mit-zuza-fr-schulen-und-klassenfahrten-mageschneiderte-reisen-nach-prag-mit-komplettem-programm-unterkunft-und-verpflegung-text73 {
    color: var(--dl-color-primary-mud);
    font-size: 13px;
    font-style: italic;
    font-weight: 700;
  }
  .prag-mit-zuza-fr-schulen-und-klassenfahrten-mageschneiderte-reisen-nach-prag-mit-komplettem-programm-unterkunft-und-verpflegung-text74 {
    color: var(--dl-color-theme-secondary2);
    font-size: 13px;
    font-style: italic;
  }
  .prag-mit-zuza-fr-schulen-und-klassenfahrten-mageschneiderte-reisen-nach-prag-mit-komplettem-programm-unterkunft-und-verpflegung-text75 {
    color: var(--dl-color-theme-secondary2);
    font-size: 13px;
    font-style: italic;
  }
  .prag-mit-zuza-fr-schulen-und-klassenfahrten-mageschneiderte-reisen-nach-prag-mit-komplettem-programm-unterkunft-und-verpflegung-text76 {
    font-size: 13px;
  }
  .prag-mit-zuza-fr-schulen-und-klassenfahrten-mageschneiderte-reisen-nach-prag-mit-komplettem-programm-unterkunft-und-verpflegung-text78 {
    color: var(--dl-color-theme-secondary1);
    font-style: italic;
    font-weight: 700;
  }
  .prag-mit-zuza-fr-schulen-und-klassenfahrten-mageschneiderte-reisen-nach-prag-mit-komplettem-programm-unterkunft-und-verpflegung-button3 {
    display: none;
    font-size: 20px;
  }
  .prag-mit-zuza-fr-schulen-und-klassenfahrten-mageschneiderte-reisen-nach-prag-mit-komplettem-programm-unterkunft-und-verpflegung-contacte {
    display: none;
    font-size: 20px;
  }
  .prag-mit-zuza-fr-schulen-und-klassenfahrten-mageschneiderte-reisen-nach-prag-mit-komplettem-programm-unterkunft-und-verpflegung-button4 {
    font-size: 13px;
  }
  .prag-mit-zuza-fr-schulen-und-klassenfahrten-mageschneiderte-reisen-nach-prag-mit-komplettem-programm-unterkunft-und-verpflegung-container17 {
    display: flex;
  }
  .prag-mit-zuza-fr-schulen-und-klassenfahrten-mageschneiderte-reisen-nach-prag-mit-komplettem-programm-unterkunft-und-verpflegung-container18 {
    padding-bottom: 119px;
  }
  .prag-mit-zuza-fr-schulen-und-klassenfahrten-mageschneiderte-reisen-nach-prag-mit-komplettem-programm-unterkunft-und-verpflegung-faq1-question {
    align-self: center;
    padding-right: 873px;
  }
  .prag-mit-zuza-fr-schulen-und-klassenfahrten-mageschneiderte-reisen-nach-prag-mit-komplettem-programm-unterkunft-und-verpflegung-image1 {
    display: none;
  }
  .prag-mit-zuza-fr-schulen-und-klassenfahrten-mageschneiderte-reisen-nach-prag-mit-komplettem-programm-unterkunft-und-verpflegung-max-width2 {
    width: 920px;
    flex-direction: column;
  }
  .prag-mit-zuza-fr-schulen-und-klassenfahrten-mageschneiderte-reisen-nach-prag-mit-komplettem-programm-unterkunft-und-verpflegung-list1 {
    width: 100%;
  }
}
@media(max-width: 767px) {
  .prag-mit-zuza-fr-schulen-und-klassenfahrten-mageschneiderte-reisen-nach-prag-mit-komplettem-programm-unterkunft-und-verpflegung-right-side {
    display: none;
  }
  .prag-mit-zuza-fr-schulen-und-klassenfahrten-mageschneiderte-reisen-nach-prag-mit-komplettem-programm-unterkunft-und-verpflegung-burger-menu {
    display: flex;
  }
  .prag-mit-zuza-fr-schulen-und-klassenfahrten-mageschneiderte-reisen-nach-prag-mit-komplettem-programm-unterkunft-und-verpflegung-panel-3 {
    align-self: center;
    align-items: center;
    padding-left: 0px;
    justify-content: center;
  }
  .prag-mit-zuza-fr-schulen-und-klassenfahrten-mageschneiderte-reisen-nach-prag-mit-komplettem-programm-unterkunft-und-verpflegung-max-width1 {
    width: auto;
    align-items: center;
    justify-content: center;
  }
  .prag-mit-zuza-fr-schulen-und-klassenfahrten-mageschneiderte-reisen-nach-prag-mit-komplettem-programm-unterkunft-und-verpflegung-timeline {
    width: 766px;
  }
  .prag-mit-zuza-fr-schulen-und-klassenfahrten-mageschneiderte-reisen-nach-prag-mit-komplettem-programm-unterkunft-und-verpflegung-container06 {
    width: 100%;
    align-items: flex-start;
    padding-left: 48px;
  }
  .prag-mit-zuza-fr-schulen-und-klassenfahrten-mageschneiderte-reisen-nach-prag-mit-komplettem-programm-unterkunft-und-verpflegung-asd {
    text-align: left;
  }
  .prag-mit-zuza-fr-schulen-und-klassenfahrten-mageschneiderte-reisen-nach-prag-mit-komplettem-programm-unterkunft-und-verpflegung-text37 {
    text-align: left;
  }
  .prag-mit-zuza-fr-schulen-und-klassenfahrten-mageschneiderte-reisen-nach-prag-mit-komplettem-programm-unterkunft-und-verpflegung-container08 {
    right: auto;
  }
  .prag-mit-zuza-fr-schulen-und-klassenfahrten-mageschneiderte-reisen-nach-prag-mit-komplettem-programm-unterkunft-und-verpflegung-step1 {
    height: 355px;
    padding-bottom: 0px;
  }
  .prag-mit-zuza-fr-schulen-und-klassenfahrten-mageschneiderte-reisen-nach-prag-mit-komplettem-programm-unterkunft-und-verpflegung-container10 {
    right: auto;
  }
  .prag-mit-zuza-fr-schulen-und-klassenfahrten-mageschneiderte-reisen-nach-prag-mit-komplettem-programm-unterkunft-und-verpflegung-container12 {
    width: 100%;
    align-items: flex-start;
    padding-left: 48px;
  }
  .prag-mit-zuza-fr-schulen-und-klassenfahrten-mageschneiderte-reisen-nach-prag-mit-komplettem-programm-unterkunft-und-verpflegung-text51 {
    text-align: left;
  }
  .prag-mit-zuza-fr-schulen-und-klassenfahrten-mageschneiderte-reisen-nach-prag-mit-komplettem-programm-unterkunft-und-verpflegung-text52 {
    text-align: left;
  }
  .prag-mit-zuza-fr-schulen-und-klassenfahrten-mageschneiderte-reisen-nach-prag-mit-komplettem-programm-unterkunft-und-verpflegung-text53 {
    text-align: left;
  }
  .prag-mit-zuza-fr-schulen-und-klassenfahrten-mageschneiderte-reisen-nach-prag-mit-komplettem-programm-unterkunft-und-verpflegung-text54 {
    text-align: left;
  }
  .prag-mit-zuza-fr-schulen-und-klassenfahrten-mageschneiderte-reisen-nach-prag-mit-komplettem-programm-unterkunft-und-verpflegung-text56 {
    text-align: left;
  }
  .prag-mit-zuza-fr-schulen-und-klassenfahrten-mageschneiderte-reisen-nach-prag-mit-komplettem-programm-unterkunft-und-verpflegung-text57 {
    text-align: left;
  }
  .prag-mit-zuza-fr-schulen-und-klassenfahrten-mageschneiderte-reisen-nach-prag-mit-komplettem-programm-unterkunft-und-verpflegung-text58 {
    text-align: left;
  }
  .prag-mit-zuza-fr-schulen-und-klassenfahrten-mageschneiderte-reisen-nach-prag-mit-komplettem-programm-unterkunft-und-verpflegung-container13 {
    right: auto;
  }
  .prag-mit-zuza-fr-schulen-und-klassenfahrten-mageschneiderte-reisen-nach-prag-mit-komplettem-programm-unterkunft-und-verpflegung-container15 {
    width: 100%;
    align-items: flex-start;
    padding-left: 48px;
  }
  .prag-mit-zuza-fr-schulen-und-klassenfahrten-mageschneiderte-reisen-nach-prag-mit-komplettem-programm-unterkunft-und-verpflegung-text61 {
    text-align: left;
  }
  .prag-mit-zuza-fr-schulen-und-klassenfahrten-mageschneiderte-reisen-nach-prag-mit-komplettem-programm-unterkunft-und-verpflegung-text62 {
    text-align: left;
  }
  .prag-mit-zuza-fr-schulen-und-klassenfahrten-mageschneiderte-reisen-nach-prag-mit-komplettem-programm-unterkunft-und-verpflegung-text63 {
    text-align: left;
  }
  .prag-mit-zuza-fr-schulen-und-klassenfahrten-mageschneiderte-reisen-nach-prag-mit-komplettem-programm-unterkunft-und-verpflegung-text65 {
    text-align: left;
  }
  .prag-mit-zuza-fr-schulen-und-klassenfahrten-mageschneiderte-reisen-nach-prag-mit-komplettem-programm-unterkunft-und-verpflegung-text66 {
    text-align: left;
  }
  .prag-mit-zuza-fr-schulen-und-klassenfahrten-mageschneiderte-reisen-nach-prag-mit-komplettem-programm-unterkunft-und-verpflegung-text67 {
    text-align: left;
  }
  .prag-mit-zuza-fr-schulen-und-klassenfahrten-mageschneiderte-reisen-nach-prag-mit-komplettem-programm-unterkunft-und-verpflegung-text68 {
    text-align: left;
  }
  .prag-mit-zuza-fr-schulen-und-klassenfahrten-mageschneiderte-reisen-nach-prag-mit-komplettem-programm-unterkunft-und-verpflegung-text69 {
    text-align: left;
  }
  .prag-mit-zuza-fr-schulen-und-klassenfahrten-mageschneiderte-reisen-nach-prag-mit-komplettem-programm-unterkunft-und-verpflegung-text71 {
    text-align: left;
  }
  .prag-mit-zuza-fr-schulen-und-klassenfahrten-mageschneiderte-reisen-nach-prag-mit-komplettem-programm-unterkunft-und-verpflegung-text72 {
    text-align: left;
  }
  .prag-mit-zuza-fr-schulen-und-klassenfahrten-mageschneiderte-reisen-nach-prag-mit-komplettem-programm-unterkunft-und-verpflegung-text73 {
    text-align: left;
  }
  .prag-mit-zuza-fr-schulen-und-klassenfahrten-mageschneiderte-reisen-nach-prag-mit-komplettem-programm-unterkunft-und-verpflegung-text74 {
    text-align: left;
  }
  .prag-mit-zuza-fr-schulen-und-klassenfahrten-mageschneiderte-reisen-nach-prag-mit-komplettem-programm-unterkunft-und-verpflegung-text75 {
    text-align: left;
  }
  .prag-mit-zuza-fr-schulen-und-klassenfahrten-mageschneiderte-reisen-nach-prag-mit-komplettem-programm-unterkunft-und-verpflegung-text77 {
    text-align: left;
  }
  .prag-mit-zuza-fr-schulen-und-klassenfahrten-mageschneiderte-reisen-nach-prag-mit-komplettem-programm-unterkunft-und-verpflegung-container17 {
    right: auto;
  }
  .prag-mit-zuza-fr-schulen-und-klassenfahrten-mageschneiderte-reisen-nach-prag-mit-komplettem-programm-unterkunft-und-verpflegung-container18 {
    right: auto;
  }
  .prag-mit-zuza-fr-schulen-und-klassenfahrten-mageschneiderte-reisen-nach-prag-mit-komplettem-programm-unterkunft-und-verpflegung-faq1-question {
    color: var(--dl-color-theme-secondary1);
    width: 766px;
    font-size: 24px;
    align-self: center;
    font-style: italic;
    text-align: center;
    font-family: Merriweather;
    font-weight: bold;
    padding-top: 9px;
    padding-right: 12px;
  }
  .prag-mit-zuza-fr-schulen-und-klassenfahrten-mageschneiderte-reisen-nach-prag-mit-komplettem-programm-unterkunft-und-verpflegung-text80 {
    width: 240px;
    align-self: center;
  }
  .prag-mit-zuza-fr-schulen-und-klassenfahrten-mageschneiderte-reisen-nach-prag-mit-komplettem-programm-unterkunft-und-verpflegung-faq8 {
    width: 551px;
    height: 1356px;
    margin-right: 0px;
  }
  .prag-mit-zuza-fr-schulen-und-klassenfahrten-mageschneiderte-reisen-nach-prag-mit-komplettem-programm-unterkunft-und-verpflegung-faq1-question1 {
    width: 240px;
    align-self: center;
  }
  .prag-mit-zuza-fr-schulen-und-klassenfahrten-mageschneiderte-reisen-nach-prag-mit-komplettem-programm-unterkunft-und-verpflegung-max-width2 {
    width: 676px;
  }
  .prag-mit-zuza-fr-schulen-und-klassenfahrten-mageschneiderte-reisen-nach-prag-mit-komplettem-programm-unterkunft-und-verpflegung-image2 {
    display: flex;
  }
  .prag-mit-zuza-fr-schulen-und-klassenfahrten-mageschneiderte-reisen-nach-prag-mit-komplettem-programm-unterkunft-und-verpflegung-button6 {
    display: none;
  }
}
@media(max-width: 479px) {
  .prag-mit-zuza-fr-schulen-und-klassenfahrten-mageschneiderte-reisen-nach-prag-mit-komplettem-programm-unterkunft-und-verpflegung-navbar {
    width: 100%;
  }
  .prag-mit-zuza-fr-schulen-und-klassenfahrten-mageschneiderte-reisen-nach-prag-mit-komplettem-programm-unterkunft-und-verpflegung-max-width {
    gap: 24px;
  }
  .prag-mit-zuza-fr-schulen-und-klassenfahrten-mageschneiderte-reisen-nach-prag-mit-komplettem-programm-unterkunft-und-verpflegung-image {
    width: 454px;
    height: 506px;
    opacity: 0.6;
    border-radius: var(--dl-radius-radius-radius8);
  }
  .prag-mit-zuza-fr-schulen-und-klassenfahrten-mageschneiderte-reisen-nach-prag-mit-komplettem-programm-unterkunft-und-verpflegung-container05 {
    width: 478px;
  }
  .prag-mit-zuza-fr-schulen-und-klassenfahrten-mageschneiderte-reisen-nach-prag-mit-komplettem-programm-unterkunft-und-verpflegung-column {
    width: 455px;
    align-self: center;
    margin-right: 0px;
  }
  .prag-mit-zuza-fr-schulen-und-klassenfahrten-mageschneiderte-reisen-nach-prag-mit-komplettem-programm-unterkunft-und-verpflegung-text26 {
    font-weight: 700;
  }
  .prag-mit-zuza-fr-schulen-und-klassenfahrten-mageschneiderte-reisen-nach-prag-mit-komplettem-programm-unterkunft-und-verpflegung-text28 {
    font-weight: 700;
  }
  .prag-mit-zuza-fr-schulen-und-klassenfahrten-mageschneiderte-reisen-nach-prag-mit-komplettem-programm-unterkunft-und-verpflegung-button {
    width: 100%;
    background-color: var(--dl-color-primary-main1);
  }
  .prag-mit-zuza-fr-schulen-und-klassenfahrten-mageschneiderte-reisen-nach-prag-mit-komplettem-programm-unterkunft-und-verpflegung-text32 {
    text-align: left;
  }
  .prag-mit-zuza-fr-schulen-und-klassenfahrten-mageschneiderte-reisen-nach-prag-mit-komplettem-programm-unterkunft-und-verpflegung-panel-3 {
    align-items: center;
    padding-left: 0px;
    justify-content: center;
  }
  .prag-mit-zuza-fr-schulen-und-klassenfahrten-mageschneiderte-reisen-nach-prag-mit-komplettem-programm-unterkunft-und-verpflegung-max-width1 {
    width: auto;
    align-items: center;
    padding-top: 13px;
    justify-content: center;
  }
  .prag-mit-zuza-fr-schulen-und-klassenfahrten-mageschneiderte-reisen-nach-prag-mit-komplettem-programm-unterkunft-und-verpflegung-timeline {
    width: auto;
    height: auto;
  }
  .prag-mit-zuza-fr-schulen-und-klassenfahrten-mageschneiderte-reisen-nach-prag-mit-komplettem-programm-unterkunft-und-verpflegung-container06 {
    padding-top: 15px;
    padding-bottom: 90px;
  }
  .prag-mit-zuza-fr-schulen-und-klassenfahrten-mageschneiderte-reisen-nach-prag-mit-komplettem-programm-unterkunft-und-verpflegung-text34 {
    color: var(--dl-color-primary-mud);
    font-family: Roboto Mono;
  }
  .prag-mit-zuza-fr-schulen-und-klassenfahrten-mageschneiderte-reisen-nach-prag-mit-komplettem-programm-unterkunft-und-verpflegung-asd {
    color: var(--dl-color-theme-secondary2);
    font-family: Roboto Mono;
    padding-bottom: 14px;
  }
  .prag-mit-zuza-fr-schulen-und-klassenfahrten-mageschneiderte-reisen-nach-prag-mit-komplettem-programm-unterkunft-und-verpflegung-container07 {
    width: 100%;
    align-items: stretch;
    flex-direction: column;
  }
  .prag-mit-zuza-fr-schulen-und-klassenfahrten-mageschneiderte-reisen-nach-prag-mit-komplettem-programm-unterkunft-und-verpflegung-container08 {
    width: 29px;
    height: 723px;
  }
  .prag-mit-zuza-fr-schulen-und-klassenfahrten-mageschneiderte-reisen-nach-prag-mit-komplettem-programm-unterkunft-und-verpflegung-progress0 {
    height: 92%;
  }
  .prag-mit-zuza-fr-schulen-und-klassenfahrten-mageschneiderte-reisen-nach-prag-mit-komplettem-programm-unterkunft-und-verpflegung-step1 {
    height: auto;
  }
  .prag-mit-zuza-fr-schulen-und-klassenfahrten-mageschneiderte-reisen-nach-prag-mit-komplettem-programm-unterkunft-und-verpflegung-container12 {
    padding-right: 0px;
    padding-bottom: 57px;
  }
  .prag-mit-zuza-fr-schulen-und-klassenfahrten-mageschneiderte-reisen-nach-prag-mit-komplettem-programm-unterkunft-und-verpflegung-text50 {
    padding-top: 29px;
  }
  .prag-mit-zuza-fr-schulen-und-klassenfahrten-mageschneiderte-reisen-nach-prag-mit-komplettem-programm-unterkunft-und-verpflegung-text51 {
    color: var(--dl-color-theme-secondary1);
  }
  .prag-mit-zuza-fr-schulen-und-klassenfahrten-mageschneiderte-reisen-nach-prag-mit-komplettem-programm-unterkunft-und-verpflegung-container13 {
    height: 445px;
  }
  .prag-mit-zuza-fr-schulen-und-klassenfahrten-mageschneiderte-reisen-nach-prag-mit-komplettem-programm-unterkunft-und-verpflegung-step2 {
    padding-top: 0px;
  }
  .prag-mit-zuza-fr-schulen-und-klassenfahrten-mageschneiderte-reisen-nach-prag-mit-komplettem-programm-unterkunft-und-verpflegung-text60 {
    color: var(--dl-color-theme-secondary1);
    text-align: left;
  }
  .prag-mit-zuza-fr-schulen-und-klassenfahrten-mageschneiderte-reisen-nach-prag-mit-komplettem-programm-unterkunft-und-verpflegung-text61 {
    font-family: Montserrat;
  }
  .prag-mit-zuza-fr-schulen-und-klassenfahrten-mageschneiderte-reisen-nach-prag-mit-komplettem-programm-unterkunft-und-verpflegung-text63 {
    font-weight: 700;
  }
  .prag-mit-zuza-fr-schulen-und-klassenfahrten-mageschneiderte-reisen-nach-prag-mit-komplettem-programm-unterkunft-und-verpflegung-text64 {
    font-family: Montserrat;
  }
  .prag-mit-zuza-fr-schulen-und-klassenfahrten-mageschneiderte-reisen-nach-prag-mit-komplettem-programm-unterkunft-und-verpflegung-text66 {
    font-weight: 700;
  }
  .prag-mit-zuza-fr-schulen-und-klassenfahrten-mageschneiderte-reisen-nach-prag-mit-komplettem-programm-unterkunft-und-verpflegung-text67 {
    color: var(--dl-color-theme-secondary1);
  }
  .prag-mit-zuza-fr-schulen-und-klassenfahrten-mageschneiderte-reisen-nach-prag-mit-komplettem-programm-unterkunft-und-verpflegung-text68 {
    color: var(--dl-color-theme-secondary1);
    font-style: italic;
  }
  .prag-mit-zuza-fr-schulen-und-klassenfahrten-mageschneiderte-reisen-nach-prag-mit-komplettem-programm-unterkunft-und-verpflegung-text69 {
    font-style: italic;
  }
  .prag-mit-zuza-fr-schulen-und-klassenfahrten-mageschneiderte-reisen-nach-prag-mit-komplettem-programm-unterkunft-und-verpflegung-text70 {
    font-family: Montserrat;
  }
  .prag-mit-zuza-fr-schulen-und-klassenfahrten-mageschneiderte-reisen-nach-prag-mit-komplettem-programm-unterkunft-und-verpflegung-text72 {
    color: var(--dl-color-theme-secondary2);
    font-style: italic;
  }
  .prag-mit-zuza-fr-schulen-und-klassenfahrten-mageschneiderte-reisen-nach-prag-mit-komplettem-programm-unterkunft-und-verpflegung-text73 {
    color: var(--dl-color-primary-mud);
    font-style: italic;
    font-weight: 700;
  }
  .prag-mit-zuza-fr-schulen-und-klassenfahrten-mageschneiderte-reisen-nach-prag-mit-komplettem-programm-unterkunft-und-verpflegung-text74 {
    color: var(--dl-color-theme-secondary2);
    font-style: italic;
  }
  .prag-mit-zuza-fr-schulen-und-klassenfahrten-mageschneiderte-reisen-nach-prag-mit-komplettem-programm-unterkunft-und-verpflegung-text75 {
    font-weight: 700;
  }
  .prag-mit-zuza-fr-schulen-und-klassenfahrten-mageschneiderte-reisen-nach-prag-mit-komplettem-programm-unterkunft-und-verpflegung-text76 {
    color: var(--dl-color-theme-secondary2);
    font-style: italic;
    font-family: Montserrat;
  }
  .prag-mit-zuza-fr-schulen-und-klassenfahrten-mageschneiderte-reisen-nach-prag-mit-komplettem-programm-unterkunft-und-verpflegung-text77 {
    color: var(--dl-color-theme-secondary2);
    font-style: italic;
  }
  .prag-mit-zuza-fr-schulen-und-klassenfahrten-mageschneiderte-reisen-nach-prag-mit-komplettem-programm-unterkunft-und-verpflegung-text78 {
    color: var(--dl-color-theme-secondary1);
    font-style: italic;
  }
  .prag-mit-zuza-fr-schulen-und-klassenfahrten-mageschneiderte-reisen-nach-prag-mit-komplettem-programm-unterkunft-und-verpflegung-container16 {
    width: 100%;
    align-items: stretch;
    flex-direction: column;
    padding-bottom: var(--dl-space-space-doubleunit);
  }
  .prag-mit-zuza-fr-schulen-und-klassenfahrten-mageschneiderte-reisen-nach-prag-mit-komplettem-programm-unterkunft-und-verpflegung-button3 {
    display: none;
  }
  .prag-mit-zuza-fr-schulen-und-klassenfahrten-mageschneiderte-reisen-nach-prag-mit-komplettem-programm-unterkunft-und-verpflegung-contacte {
    display: none;
  }
  .prag-mit-zuza-fr-schulen-und-klassenfahrten-mageschneiderte-reisen-nach-prag-mit-komplettem-programm-unterkunft-und-verpflegung-button4 {
    display: none;
    padding-top: var(--dl-space-space-doubleunit);
    padding-bottom: var(--dl-space-space-doubleunit);
  }
  .prag-mit-zuza-fr-schulen-und-klassenfahrten-mageschneiderte-reisen-nach-prag-mit-komplettem-programm-unterkunft-und-verpflegung-button5 {
    display: none;
  }
  .prag-mit-zuza-fr-schulen-und-klassenfahrten-mageschneiderte-reisen-nach-prag-mit-komplettem-programm-unterkunft-und-verpflegung-container17 {
    display: flex;
  }
  .prag-mit-zuza-fr-schulen-und-klassenfahrten-mageschneiderte-reisen-nach-prag-mit-komplettem-programm-unterkunft-und-verpflegung-container18 {
    padding-bottom: 160px;
  }
  .prag-mit-zuza-fr-schulen-und-klassenfahrten-mageschneiderte-reisen-nach-prag-mit-komplettem-programm-unterkunft-und-verpflegung-faq1-question {
    color: var(--dl-color-primary-main1);
    width: 212px;
    display: none;
    align-self: center;
    font-style: normal;
    text-align: center;
    font-family: Merriweather;
    font-weight: bold;
    padding-bottom: 114px;
  }
  .prag-mit-zuza-fr-schulen-und-klassenfahrten-mageschneiderte-reisen-nach-prag-mit-komplettem-programm-unterkunft-und-verpflegung-text80 {
    color: var(--dl-color-primary-main1);
    width: 212px;
    display: none;
    align-self: center;
    font-style: normal;
    text-align: center;
    font-family: Merriweather;
    font-weight: bold;
    padding-bottom: 114px;
  }
  .prag-mit-zuza-fr-schulen-und-klassenfahrten-mageschneiderte-reisen-nach-prag-mit-komplettem-programm-unterkunft-und-verpflegung-faq8 {
    height: 1907px;
    padding-top: 0px;
  }
  .prag-mit-zuza-fr-schulen-und-klassenfahrten-mageschneiderte-reisen-nach-prag-mit-komplettem-programm-unterkunft-und-verpflegung-faq1-question1 {
    color: var(--dl-color-primary-main1);
    width: 212px;
    align-self: center;
    font-style: normal;
    text-align: center;
    font-family: Merriweather;
    font-weight: bold;
  }
  .prag-mit-zuza-fr-schulen-und-klassenfahrten-mageschneiderte-reisen-nach-prag-mit-komplettem-programm-unterkunft-und-verpflegung-image1 {
    width: 399px;
    height: 71px;
    display: flex;
    align-self: center;
    box-shadow: 2px 5px 2px 0px rgba(0, 29, 3, 0.32);
    margin-top: var(--dl-space-space-doubleunit);
    align-items: center;
    margin-bottom: var(--dl-space-space-doubleunit);
    justify-content: center;
  }
  .prag-mit-zuza-fr-schulen-und-klassenfahrten-mageschneiderte-reisen-nach-prag-mit-komplettem-programm-unterkunft-und-verpflegung-max-width2 {
    gap: 24px;
    width: 433px;
  }
  .prag-mit-zuza-fr-schulen-und-klassenfahrten-mageschneiderte-reisen-nach-prag-mit-komplettem-programm-unterkunft-und-verpflegung-image2 {
    display: none;
  }
  .prag-mit-zuza-fr-schulen-und-klassenfahrten-mageschneiderte-reisen-nach-prag-mit-komplettem-programm-unterkunft-und-verpflegung-button6 {
    color: var(--dl-color-gray-white);
    width: 276px;
    font-size: 10px;
    font-family: Poppins;
    font-weight: 600;
    text-transform: none;
    text-decoration: none;
    background-color: rgb(16, 138, 20);
  }
}

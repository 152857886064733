.prag-mit-zuza-legal-container {
  width: 100%;
  display: flex;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
}
.prag-mit-zuza-legal-navbar {
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-doubleunit);
  max-width: 1000px;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.prag-mit-zuza-legal-navlink {
  display: contents;
}
.prag-mit-zuza-legal-logo {
  fill: var(--dl-color-primary-main1);
  color: var(--dl-color-primary-main1);
  font-family: "Pano-Regular";
  text-decoration: none;
}
.prag-mit-zuza-legal-right-side {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.prag-mit-zuza-legal-links-container {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.prag-mit-zuza-legal-navlink1 {
  margin-right: var(--dl-space-space-doubleunit);
  text-decoration: none;
}
.prag-mit-zuza-legal-container1 {
  display: flex;
  position: relative;
}
.prag-mit-zuza-legal-navlink2 {
  color: var(--dl-color-gray-white);
  border: none;
  transition: 0.3s;
  padding-top: var(--dl-space-space-halfunit);
  padding-left: var(--dl-space-space-doubleunit);
  border-radius: 50px;
  padding-right: var(--dl-space-space-doubleunit);
  padding-bottom: var(--dl-space-space-halfunit);
  text-decoration: none;
  background-color: #108a14;
}
.prag-mit-zuza-legal-navlink2:hover {
  color: var(--dl-color-primary-100);
  border: 2px;
  border-color: var(--dl-color-primary-108a14);
  background-color: var(--dl-color-theme-secondary2);
}
.prag-mit-zuza-legal-burger-menu {
  display: none;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.prag-mit-zuza-legal-burger-menu1 {
  width: 24px;
  height: 24px;
}
.prag-mit-zuza-legal-mobile-menu {
  top: 0px;
  flex: 0 0 auto;
  left: 0px;
  width: 100%;
  height: 100%;
  display: none;
  padding: var(--dl-space-space-doubleunit);
  z-index: 100;
  position: absolute;
  flex-direction: column;
  justify-content: space-between;
  background-color: #fff;
}
.prag-mit-zuza-legal-nav {
  flex: 0 0 auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.prag-mit-zuza-legal-top {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-doubleunit);
  flex-direction: row;
  justify-content: space-between;
}
.prag-mit-zuza-legal-close-menu {
  flex: 0 0 auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.prag-mit-zuza-legal-icon03 {
  width: 24px;
  height: 24px;
}
.prag-mit-zuza-legal-right-side1 {
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: space-between;
}
.prag-mit-zuza-legal-links-container1 {
  display: flex;
  align-items: flex-start;
  margin-bottom: 16px;
  flex-direction: column;
  justify-content: space-between;
}
.prag-mit-zuza-legal-text02 {
  margin-bottom: 8px;
}
.prag-mit-zuza-legal-text03 {
  margin-bottom: 8px;
}
.prag-mit-zuza-legal-text04 {
  margin-bottom: 8px;
}
.prag-mit-zuza-legal-follow-container {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: space-between;
}
.prag-mit-zuza-legal-text06 {
  padding-bottom: var(--dl-space-space-halfunit);
}
.prag-mit-zuza-legal-icons-container {
  width: 100px;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  justify-content: space-between;
}
.prag-mit-zuza-legal-link {
  display: contents;
}
.prag-mit-zuza-legal-icon05 {
  width: 24px;
  height: 24px;
}
.prag-mit-zuza-legal-link1 {
  display: contents;
}
.prag-mit-zuza-legal-icon07 {
  width: 24px;
  height: 24px;
}
.prag-mit-zuza-legal-link2 {
  display: contents;
}
.prag-mit-zuza-legal-icon09 {
  width: 24px;
  height: 24px;
}
.prag-mit-zuza-legal-container2 {
  height: 1717px;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.prag-mit-zuza-legal-container3 {
  gap: 48px;
  display: flex;
  overflow: hidden;
  position: relative;
  flex-direction: column;
}
.prag-mit-zuza-legal-max-width {
  width: 100%;
  display: flex;
  max-width: 1400px;
  align-items: center;
  flex-direction: column;
}
.prag-mit-zuza-legal-content {
  gap: 24px;
  display: flex;
  align-self: stretch;
  align-items: center;
  flex-direction: column;
}
.prag-mit-zuza-legal-heading1 {
  font-size: 60px;
  text-align: center;
  font-family: "Noto Sans";
}
.prag-mit-zuza-legal-content1 {
  width: 1001px;
  text-align: center;
  font-family: "Noto Sans";
  padding-bottom: 57px;
}
.prag-mit-zuza-legal-container4 {
  width: 1000px;
  height: 551px;
  display: flex;
  position: relative;
  align-items: center;
  flex-direction: column;
}
.prag-mit-zuza-legal-max-width1 {
  width: 100%;
  display: flex;
  max-width: 1400px;
  align-items: center;
  flex-direction: column;
}
.prag-mit-zuza-legal-content1 {
  align-self: stretch;
  align-items: flex-start;
}
.prag-mit-zuza-legal-ul {
  align-items: flex-start;
}
.prag-mit-zuza-legal-li {
  font-family: "Noto Sans";
}
.prag-mit-zuza-legal-heading2 {
  font-family: "Noto Sans";
}
.prag-mit-zuza-legal-text09 {
  font-weight: 700;
}
.prag-mit-zuza-legal-text12 {
  font-weight: 700;
}
.prag-mit-zuza-legal-text15 {
  font-weight: 700;
}
.prag-mit-zuza-legal-max-width2 {
  width: 100%;
  display: flex;
  max-width: 1400px;
  align-items: center;
  flex-direction: column;
}
.prag-mit-zuza-legal-content2 {
  align-self: stretch;
  align-items: flex-start;
}
.prag-mit-zuza-legal-ul1 {
  align-items: flex-start;
}
.prag-mit-zuza-legal-li1 {
  font-family: "Noto Sans";
}
.prag-mit-zuza-legal-heading21 {
  font-family: "Noto Sans";
}
.prag-mit-zuza-legal-max-width3 {
  width: 100%;
  display: flex;
  max-width: 1400px;
  align-items: center;
  flex-direction: column;
}
.prag-mit-zuza-legal-content3 {
  align-self: stretch;
  align-items: flex-start;
}
.prag-mit-zuza-legal-ul2 {
  align-items: flex-start;
}
.prag-mit-zuza-legal-li2 {
  font-family: "Noto Sans";
}
.prag-mit-zuza-legal-heading22 {
  font-family: "Noto Sans";
}
.prag-mit-zuza-legal-max-width4 {
  width: 100%;
  display: flex;
  max-width: 1400px;
  align-items: center;
  flex-direction: column;
}
.prag-mit-zuza-legal-content4 {
  align-self: stretch;
  align-items: flex-start;
}
.prag-mit-zuza-legal-ul3 {
  align-items: flex-start;
}
.prag-mit-zuza-legal-li3 {
  font-family: "Noto Sans";
}
.prag-mit-zuza-legal-heading23 {
  font-family: "Noto Sans";
}
.prag-mit-zuza-legal-max-width5 {
  width: 100%;
  display: flex;
  max-width: 1400px;
  align-items: center;
  flex-direction: column;
}
.prag-mit-zuza-legal-content5 {
  align-self: stretch;
  align-items: flex-start;
}
.prag-mit-zuza-legal-ul4 {
  align-items: flex-start;
}
.prag-mit-zuza-legal-li4 {
  font-family: "Noto Sans";
}
.prag-mit-zuza-legal-heading24 {
  font-family: "Noto Sans";
}
.prag-mit-zuza-legal-text31 {
  font-size: 14px;
  font-weight: 700;
}
.prag-mit-zuza-legal-text32 {
  font-size: 14px;
  font-weight: 700;
}
.prag-mit-zuza-legal-text33 {
  font-weight: 700;
}
.prag-mit-zuza-legal-text36 {
  font-weight: 700;
}
.prag-mit-zuza-legal-text39 {
  font-weight: 700;
}
.prag-mit-zuza-legal-text42 {
  font-weight: 700;
}
.prag-mit-zuza-legal-text45 {
  font-weight: 700;
}
.prag-mit-zuza-legal-text48 {
  font-weight: 700;
}
.prag-mit-zuza-legal-heading25 {
  font-family: "Noto Sans";
}
.prag-mit-zuza-legal-heading26 {
  font-family: "Noto Sans";
}
.prag-mit-zuza-legal-heading27 {
  font-family: "Noto Sans";
}
.prag-mit-zuza-legal-heading28 {
  font-family: "Noto Sans";
}
.prag-mit-zuza-legal-footer {
  color: var(--dl-color-gray-white);
  width: 3200px;
  display: flex;
  padding: var(--dl-space-space-tripleunit);
  z-index: 100;
  box-shadow: 5px 5px 10px 0px #000000;
  align-items: flex-end;
  flex-direction: row;
  justify-content: center;
  background-color: var(--dl-color-gray-black);
}
.prag-mit-zuza-legal-menu {
  width: 100%;
  display: flex;
  max-width: 1000px;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.prag-mit-zuza-legal-logo2 {
  fill: var(--dl-color-primary-main1);
  color: var(--dl-color-primary-main1);
  font-family: "Pano-Regular";
}
.prag-mit-zuza-legal-links-container2 {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.prag-mit-zuza-legal-container5 {
  display: flex;
  align-items: flex-start;
  margin-left: 100px;
  flex-direction: column;
}
.prag-mit-zuza-legal-navlink3 {
  align-self: center;
  text-align: center;
  padding-top: var(--dl-space-space-halfunit);
  padding-bottom: var(--dl-space-space-halfunit);
  text-decoration: none;
}
.prag-mit-zuza-legal-container6 {
  display: flex;
  margin-top: var(--dl-space-space-halfunit);
  align-items: flex-start;
  margin-left: 100px;
  margin-bottom: var(--dl-space-space-halfunit);
  flex-direction: column;
}
.prag-mit-zuza-legal-navlink4 {
  margin-top: var(--dl-space-space-halfunit);
  margin-bottom: var(--dl-space-space-halfunit);
  text-decoration: none;
}
@media(max-width: 991px) {
  .prag-mit-zuza-legal-container2 {
    width: 898px;
    height: 1939px;
  }
  .prag-mit-zuza-legal-content1 {
    width: 808px;
    font-family: Noto Sans;
  }
  .prag-mit-zuza-legal-max-width1 {
    width: 80%;
  }
  .prag-mit-zuza-legal-max-width2 {
    width: 80%;
  }
  .prag-mit-zuza-legal-max-width3 {
    width: 80%;
  }
  .prag-mit-zuza-legal-max-width4 {
    width: 80%;
  }
  .prag-mit-zuza-legal-max-width5 {
    width: 80%;
  }
  .prag-mit-zuza-legal-logo2 {
    padding-left: 174px;
  }
}
@media(max-width: 767px) {
  .prag-mit-zuza-legal-right-side {
    display: none;
  }
  .prag-mit-zuza-legal-burger-menu {
    display: flex;
  }
  .prag-mit-zuza-legal-container2 {
    height: 2198px;
  }
  .prag-mit-zuza-legal-content1 {
    width: 611px;
    text-align: justify;
    font-family: Roboto Mono;
  }
  .prag-mit-zuza-legal-container4 {
    width: 766px;
    height: 1633px;
  }
  .prag-mit-zuza-legal-text09 {
    font-weight: 700;
  }
  .prag-mit-zuza-legal-text12 {
    font-weight: 700;
  }
  .prag-mit-zuza-legal-text15 {
    font-weight: 700;
  }
  .prag-mit-zuza-legal-menu {
    width: 766px;
    flex-direction: column;
  }
  .prag-mit-zuza-legal-logo2 {
    padding-left: 0px;
  }
  .prag-mit-zuza-legal-links-container2 {
    margin-top: var(--dl-space-space-tripleunit);
    margin-bottom: var(--dl-space-space-tripleunit);
    justify-content: space-between;
  }
  .prag-mit-zuza-legal-container5 {
    margin-left: 0px;
  }
  .prag-mit-zuza-legal-navlink3 {
    text-decoration: none;
  }
}
@media(max-width: 479px) {
  .prag-mit-zuza-legal-container2 {
    width: 100%;
    height: 3279px;
  }
  .prag-mit-zuza-legal-container3 {
    height: 1238px;
    padding-bottom: 0px;
  }
  .prag-mit-zuza-legal-heading1 {
    width: 478px;
    height: 235px;
    font-size: 80px;
    font-family: Noto Sans;
    margin-bottom: 0px;
    padding-right: 0px;
  }
  .prag-mit-zuza-legal-content1 {
    width: 414px;
    font-family: Noto Sans;
    padding-bottom: 0px;
  }
  .prag-mit-zuza-legal-container4 {
    width: 100%;
    height: 2943px;
    padding-top: 0px;
    padding-bottom: 39px;
  }
  .prag-mit-zuza-legal-heading2 {
    font-family: Roboto Mono;
  }
  .prag-mit-zuza-legal-content2 {
    font-family: Roboto Mono;
    padding-bottom: 200px;
  }
  .prag-mit-zuza-legal-links-container2 {
    margin-top: var(--dl-space-space-doubleunit);
    align-items: flex-start;
    justify-content: flex-start;
  }
  .prag-mit-zuza-legal-navlink3 {
    margin-bottom: var(--dl-space-space-halfunit);
  }
  .prag-mit-zuza-legal-container6 {
    height: 100%;
    align-items: flex-start;
    margin-left: 50px;
  }
  .prag-mit-zuza-legal-navlink4 {
    margin-bottom: var(--dl-space-space-halfunit);
  }
}

.prag-mit-zuzablog-container {
  width: 100%;
  height: auto;
  display: flex;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
  background-image: linear-gradient(90deg, rgba(252, 0, 255, 0.23) 3.00%,rgba(252, 0, 255, 0) 52.00%,rgba(0, 219, 222, 0.3) 100.00%);
}
.prag-mit-zuzablog-top-container {
  width: 100%;
  height: 99px;
  display: flex;
  align-items: center;
  flex-direction: column;
  background-size: cover;
  background-image: linear-gradient(225deg, rgba(0, 0, 0, 0.2) 0.00%,rgba(0, 0, 0, 0.1) 100.00%),url("/zuzana-manova-hero-1500w.webp");
  background-position: center,top;
}
.prag-mit-zuzablog-navbar {
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-doubleunit);
  max-width: 1000px;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.prag-mit-zuzablog-logo {
  fill: var(--dl-color-primary-main1);
  color: var(--dl-color-primary-main1);
  font-family: "Pano-Regular";
}
.prag-mit-zuzablog-right-side {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.prag-mit-zuzablog-links-container {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.prag-mit-zuzablog-navlink {
  transition: 0.3s;
  margin-right: var(--dl-space-space-doubleunit);
  text-decoration: none;
}
.prag-mit-zuzablog-navlink:hover {
  color: #ffffff;
}
.prag-mit-zuzablog-link {
  transition: 0.3s;
  margin-right: var(--dl-space-space-doubleunit);
  text-decoration: none;
}
.prag-mit-zuzablog-link:hover {
  color: rgba(1, 31, 247, 0.64);
}
.prag-mit-zuzablog-link01 {
  transition: 0.3s;
  margin-right: var(--dl-space-space-doubleunit);
  text-decoration: none;
}
.prag-mit-zuzablog-link01:hover {
  color: rgba(69, 2, 115, 0.43);
}
.prag-mit-zuzablog-container01 {
  display: flex;
  position: relative;
}
.prag-mit-zuzablog-button {
  color: var(--dl-color-gray-white);
  border: none;
  transition: 0.3s;
  padding-top: var(--dl-space-space-halfunit);
  padding-left: var(--dl-space-space-doubleunit);
  border-radius: 50px;
  padding-right: var(--dl-space-space-doubleunit);
  padding-bottom: var(--dl-space-space-halfunit);
  text-decoration: none;
  background-color: #108a14;
}
.prag-mit-zuzablog-button:hover {
  color: var(--dl-color-primary-100);
  border: 2px;
  border-color: rgba(16, 138, 20, 0.39);
  background-color: var(--dl-color-primary-108a14);
}
.prag-mit-zuzablog-burger-menu {
  display: none;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.prag-mit-zuzablog-burger-menu1 {
  width: 24px;
  height: 24px;
}
.prag-mit-zuzablog-mobile-menu {
  top: 0px;
  flex: 0 0 auto;
  left: 0px;
  width: 100%;
  height: 100%;
  display: none;
  padding: var(--dl-space-space-doubleunit);
  z-index: 100;
  position: absolute;
  flex-direction: column;
  justify-content: space-between;
  background-color: #fff;
}
.prag-mit-zuzablog-nav {
  flex: 0 0 auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.prag-mit-zuzablog-top {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-doubleunit);
  flex-direction: row;
  justify-content: space-between;
}
.prag-mit-zuzablog-close-menu {
  flex: 0 0 auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.prag-mit-zuzablog-icon03 {
  width: 24px;
  height: 24px;
}
.prag-mit-zuzablog-right-side1 {
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: space-between;
}
.prag-mit-zuzablog-links-container1 {
  display: flex;
  align-items: flex-start;
  margin-bottom: 16px;
  flex-direction: column;
  justify-content: space-between;
}
.prag-mit-zuzablog-text02 {
  margin-bottom: 8px;
}
.prag-mit-zuzablog-text03 {
  margin-bottom: 8px;
}
.prag-mit-zuzablog-text04 {
  margin-bottom: 8px;
}
.prag-mit-zuzablog-follow-container {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: space-between;
}
.prag-mit-zuzablog-text06 {
  padding-bottom: var(--dl-space-space-halfunit);
}
.prag-mit-zuzablog-icons-container {
  width: 100px;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  justify-content: space-between;
}
.prag-mit-zuzablog-link02 {
  display: contents;
}
.prag-mit-zuzablog-icon05 {
  width: 24px;
  height: 24px;
}
.prag-mit-zuzablog-link03 {
  display: contents;
}
.prag-mit-zuzablog-icon07 {
  width: 24px;
  height: 24px;
}
.prag-mit-zuzablog-link04 {
  display: contents;
}
.prag-mit-zuzablog-icon09 {
  width: 24px;
  height: 24px;
}
.prag-mit-zuzablog-main {
  width: 100%;
  display: flex;
  align-items: center;
  padding-top: var(--dl-space-space-quadruple);
  padding-left: var(--dl-space-space-doubleunit);
  padding-right: var(--dl-space-space-doubleunit);
  flex-direction: column;
  justify-content: flex-start;
}
.prag-mit-zuzablog-text07 {
  fill: var(--dl-color-primary-main1);
  color: var(--dl-color-primary-main1);
  font-family: "Roboto Mono";
  line-height: 1;
}
.prag-mit-zuzablog-gallery3 {
  width: 100%;
  height: auto;
  display: flex;
  overflow: hidden;
  position: relative;
  align-items: center;
  flex-shrink: 0;
  flex-direction: column;
  padding-bottom: var(--dl-space-space-unit);
}
.prag-mit-zuzablog-max-width {
  gap: 48px;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.prag-mit-zuzablog-section-title {
  gap: 24px;
  width: auto;
  display: flex;
  max-width: 800px;
  align-items: center;
  flex-shrink: 0;
  flex-direction: column;
}
.prag-mit-zuzablog-text16 {
  display: none;
  text-align: center;
  font-family: "Pano-Regular";
}
.prag-mit-zuzablog-container02 {
  gap: 24px;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: row;
}
.prag-mit-zuzablog-content {
  gap: 24px;
  flex: 1;
  width: 100%;
  display: flex;
  align-self: stretch;
  align-items: center;
  flex-shrink: 0;
  justify-content: center;
}
.prag-mit-zuzablog-container03 {
  flex: 1;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.prag-mit-zuzablog-image1 {
  width: 100%;
  height: 902px;
  display: none;
  max-width: 100%;
  align-self: center;
}
.prag-mit-zuzablog-container04 {
  gap: 24px;
  flex: 1;
  width: auto;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.prag-mit-zuzablog-container05 {
  gap: 24px;
  flex: 1;
  width: auto;
  height: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.prag-mit-zuzablog-image2 {
  width: 100%;
  height: 440px;
  display: none;
  object-fit: cover;
}
.prag-mit-zuzablog-image3 {
  width: 100%;
  height: 440px;
  display: none;
  object-fit: cover;
}
.prag-mit-zuzablog-container06 {
  gap: 24px;
  flex: 1;
  width: auto;
  height: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.prag-mit-zuzablog-image5 {
  width: 100%;
  height: 440px;
  display: none;
  object-fit: cover;
}
.prag-mit-zuzablog-contact20 {
  gap: 48px;
  display: flex;
  overflow: hidden;
  position: relative;
  padding-top: var(--dl-space-space-doubleunit);
  flex-direction: column;
}
.prag-mit-zuzablog-max-width1 {
  gap: 48px;
  width: auto;
  display: none;
  align-items: center;
  flex-shrink: 0;
  padding-left: var(--dl-space-space-tripleunit);
  padding-right: var(--dl-space-space-tripleunit);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-quadruple);
  justify-content: flex-start;
}
.prag-mit-zuzablog-container07 {
  width: auto;
  display: flex;
  align-items: center;
  padding-top: var(--dl-space-space-doubleunit);
  padding-left: var(--dl-space-space-doubleunit);
  border-radius: var(--dl-radius-radius-cardradius);
  padding-right: var(--dl-space-space-doubleunit);
  padding-bottom: var(--dl-space-space-doubleunit);
  justify-content: center;
  background-color: #3a3a3a;
}
.prag-mit-zuzablog-container08 {
  display: flex;
  align-items: center;
  justify-content: center;
}
.prag-mit-zuzablog-container09 {
  flex: 0 0 auto;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.prag-mit-zuzablog-image {
  width: 200px;
  object-fit: cover;
}
.prag-mit-zuzablog-link05 {
  display: contents;
}
.prag-mit-zuzablog-text18 {
  text-decoration: none;
}
.prag-mit-zuzablog-container10 {
  flex: 0 0 auto;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.prag-mit-zuzablog-image1 {
  width: 200px;
  object-fit: cover;
}
.prag-mit-zuzablog-link06 {
  display: contents;
}
.prag-mit-zuzablog-text20 {
  text-decoration: none;
}
.prag-mit-zuzablog-container11 {
  flex: 0 0 auto;
  width: auto;
  display: flex;
  align-self: stretch;
  align-items: stretch;
  flex-direction: column;
  justify-content: flex-start;
}
.prag-mit-zuzablog-text21 {
  align-self: flex-end;
  text-align: justify;
}
.prag-mit-zuzablog-text22 {
  text-align: center;
  font-family: "Pano-Regular";
}
.prag-mit-zuzablog-container12 {
  display: flex;
  align-items: center;
  justify-content: center;
}
.prag-mit-zuzablog-link07 {
  display: contents;
}
.prag-mit-zuzablog-image2 {
  width: 200px;
  box-shadow: 5px 5px 10px 0px #d4d4d4;
  object-fit: cover;
  transition: 0.3s;
  border-radius: var(--dl-radius-radius-buttonradius);
  text-decoration: none;
}
.prag-mit-zuzablog-image2:hover {
  width: 220;
  box-shadow: 5px 5px 30px 0px #22C427;
}
.prag-mit-zuzablog-container13 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: center;
  border-radius: var(--dl-radius-radius-imageradius);
  padding-bottom: 61px;
  justify-content: center;
}
.prag-mit-zuzablog-container14 {
  flex: 0 0 auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.prag-mit-zuzablog-slider {
  width: 500px;
  height: 300px;
  display: inline-block;
}
.prag-mit-zuzablog-slider-slide {
  display: flex;
  align-items: flex-end;
  border-radius: var(--dl-radius-radius-radius4);
  background-size: cover;
  justify-content: center;
  background-image: url("https://images.unsplash.com/photo-1557745133-ee31592739cb?ixid=M3w5MTMyMXwwfDF8c2VhcmNofDJ8fFByYWd8ZW58MHx8fHwxNzIwMzc2NjU5fDA&ixlib=rb-4.0.3&w=500");
}
.prag-mit-zuzablog-slider-slide01 {
  display: flex;
  background-size: cover;
  background-image: url("https://images.unsplash.com/photo-1597220333813-7bf186e81e41?ixid=M3w5MTMyMXwwfDF8c2VhcmNofDIxfHxQcmFnfGVufDB8fHx8MTcyMDM3NjY1OXww&ixlib=rb-4.0.3&w=500");
}
.prag-mit-zuzablog-slider-slide02 {
  display: flex;
  border-radius: var(--dl-radius-radius-buttonradius);
  background-size: cover;
  background-image: url("https://images.unsplash.com/photo-1580231042674-9183f8cc1f4c?ixid=M3w5MTMyMXwwfDF8c2VhcmNofDMzfHxQcmFnfGVufDB8fHx8MTcyMDM3NjcyMnww&ixlib=rb-4.0.3&w=500");
}
.prag-mit-zuzablog-slider-slide03 {
  display: flex ;
  background-size: cover;
  background-image: url("https://images.unsplash.com/photo-1645536111601-af99b7e9cd70?ixid=M3w5MTMyMXwwfDF8c2VhcmNofDE5fHxQcmFnfGVufDB8fHx8MTcyMDM3NjY1OXww&ixlib=rb-4.0.3&w=500");
}
.prag-mit-zuzablog-slider-slide04 {
  display: flex ;
  background-size: contain;
  background-image: url("https://images.unsplash.com/photo-1629044564932-e07514fa4e7c?ixid=M3w5MTMyMXwwfDF8c2VhcmNofDczfHxQcmFnfGVufDB8fHx8MTcyMDM3Njg1NXww&ixlib=rb-4.0.3&w=500");
  background-repeat: no-repeat;
  background-position: center;
}
.prag-mit-zuzablog-slider-slide05 {
  display: flex ;
  background-size: contain;
  background-image: url("https://images.unsplash.com/photo-1532631287453-22064af5316b?ixid=M3w5MTMyMXwwfDF8c2VhcmNofDcwfHxQcmFnfGVufDB8fHx8MTcyMDM3NjgxN3ww&ixlib=rb-4.0.3&w=500");
  background-repeat: no-repeat;
  background-position: center;
}
.prag-mit-zuzablog-slider-slide06 {
  display: flex ;
  background-size: cover;
  background-image: url("https://images.unsplash.com/photo-1557745271-23ad4dd34d53?ixid=M3w5MTMyMXwwfDF8c2VhcmNofDZ8fFByYWd8ZW58MHx8fHwxNzIwMzc2NjU5fDA&ixlib=rb-4.0.3&w=500");
}
.prag-mit-zuzablog-slider-slide07 {
  display: flex ;
  background-size: cover;
  background-image: url("https://images.unsplash.com/photo-1709645648368-b657d45218b1?ixid=M3w5MTMyMXwwfDF8c2VhcmNofDEyOXx8UHJhZ3xlbnwwfHx8fDE3MjAzNzY5ODN8MA&ixlib=rb-4.0.3&w=500");
}
.prag-mit-zuzablog-slider-slide08 {
  display: flex ;
  background-size: auto;
  background-image: url("https://images.unsplash.com/photo-1716841544132-582d114be671?ixid=M3w5MTMyMXwwfDF8c2VhcmNofDE2MHx8UHJhZ3xlbnwwfHx8fDE3MjAzNzY5OTh8MA&ixlib=rb-4.0.3&w=500");
  background-position: bottom;
}
.prag-mit-zuzablog-slider-slide09 {
  display: flex ;
  background-size: cover;
  background-image: url("https://images.unsplash.com/photo-1668363676100-83025a1620fa?ixid=M3w5MTMyMXwwfDF8c2VhcmNofDIyMHx8UHJhZ3xlbnwwfHx8fDE3MjAzNzcwNDd8MA&ixlib=rb-4.0.3&w=500");
  background-position: left;
}
.prag-mit-zuzablog-slider-slide10 {
  display: flex ;
  background-size: contain;
  background-image: url("https://images.unsplash.com/photo-1702238698415-fed4d5b561d2?ixid=M3w5MTMyMXwwfDF8c2VhcmNofDIzMXx8UHJhZ3xlbnwwfHx8fDE3MjAzNzcwNDd8MA&ixlib=rb-4.0.3&w=500");
  background-repeat: no-repeat;
  background-position: center;
}
.prag-mit-zuzablog-slider-slide11 {
  display: flex ;
  background-size: contain;
  background-image: url("https://images.unsplash.com/photo-1661609304524-21dc06282f1f?ixid=M3w5MTMyMXwwfDF8c2VhcmNofDIyN3x8UHJhZ3xlbnwwfHx8fDE3MjAzNzcwNDd8MA&ixlib=rb-4.0.3&w=500");
  background-repeat: no-repeat;
  background-position: center;
}
.prag-mit-zuzablog-slider-slide12 {
  display: flex ;
  background-size: cover;
  background-image: url("https://images.unsplash.com/photo-1574326195723-f58f1b35d9ca?ixid=M3w5MTMyMXwwfDF8c2VhcmNofDF8fFByYWhhfGVufDB8fHx8MTcyMDM4OTkwOHww&ixlib=rb-4.0.3&w=500");
  background-position: center;
}
.prag-mit-zuzablog-container15 {
  flex: 0 0 auto;
  width: auto;
  height: auto;
  display: flex;
  flex-direction: column;
}
.prag-mit-zuzablog-container16 {
  flex: 0 0 auto;
  width: 100%;
  border: 2px dashed rgba(120, 120, 120, 0.4);
  height: 100px;
  display: flex;
  align-items: flex-start;
}
.prag-mit-zuzablog-slider-pagination {
  display: block;
}
.prag-mit-zuzablog-container17 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: flex-start;
}
.prag-mit-zuzablog-container18 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-self: center;
  align-items: flex-start;
  padding-top: 32px;
}
.prag-mit-zuzablog-container19 {
  flex: 0 0 auto;
  width: 308px;
  height: 201px;
  display: flex;
  align-items: center;
  padding-top: 21px;
  padding-left: 63px;
  padding-right: 48px;
  flex-direction: column;
  justify-content: center;
}
.prag-mit-zuzablog-container20 {
  display: none;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.prag-mit-zuzablog-text24 {
  font-style: italic;
}
.prag-mit-zuzablog-container21 {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.prag-mit-zuzablog-text25 {
  font-style: italic;
}
.prag-mit-zuzablog-text27 {
  font-weight: 700;
}
.prag-mit-zuzablog-container22 {
  flex: 0 0 auto;
  width: 308px;
  height: 201px;
  display: flex;
  align-items: center;
  padding-top: 21px;
  flex-direction: column;
  justify-content: center;
}
.prag-mit-zuzablog-text30 {
  font-style: italic;
}
.prag-mit-zuzablog-text32 {
  font-weight: 700;
}
.prag-mit-zuzablog-container23 {
  width: 100%;
  height: 548px;
  display: flex;
  align-items: center;
  padding-bottom: var(--dl-space-space-doubleunit);
  justify-content: center;
}
.prag-mit-zuzablog-container24 {
  width: 100%;
  height: 495px;
  display: flex;
  align-items: center;
  padding-top: 44px;
  flex-direction: column;
  justify-content: flex-start;
  background-image: linear-gradient(90deg, rgba(115, 128, 137, 0.17) 0.00%,rgba(44, 62, 80, 0.09) 100.00%);
}
.prag-mit-zuzablog-form {
  width: auto;
  height: auto;
  display: flex;
}
.prag-mit-zuzablog-container25 {
  display: flex;
  align-items: center;
  justify-content: center;
}
.prag-mit-zuzablog-container26 {
  flex: 0 0 auto;
  width: auto;
  height: auto;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.prag-mit-zuzablog-container27 {
  width: 575px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.prag-mit-zuzablog-textinput {
  width: 100%;
  margin-top: var(--dl-space-space-halfunit);
  padding-left: var(--dl-space-space-halfunit);
  margin-bottom: var(--dl-space-space-halfunit);
}
.prag-mit-zuzablog-textinput1 {
  width: 100%;
  margin-top: var(--dl-space-space-halfunit);
  padding-left: var(--dl-space-space-halfunit);
  margin-bottom: var(--dl-space-space-halfunit);
}
.prag-mit-zuzablog-textarea {
  width: 100%;
  height: 188px;
  margin-top: var(--dl-space-space-halfunit);
  margin-bottom: var(--dl-space-space-halfunit);
}
.prag-mit-zuzablog-container28 {
  flex: 0 0 auto;
  width: auto;
  height: auto;
  display: flex;
  align-self: flex-start;
  align-items: flex-start;
  padding-top: 14px;
  justify-content: flex-start;
}
.prag-mit-zuzablog-date-time {
  width: 120px;
  padding-right: var(--dl-space-space-halfunit);
}
.prag-mit-zuzablog-container29 {
  width: 453px;
  height: 40px;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.prag-mit-zuzablog-text34 {
  fill: var(--dl-color-primary-main1);
  color: var(--dl-color-primary-main1);
  font-family: "Poppins";
  line-height: 1;
  padding-top: 0px;
  padding-left: 0px;
  padding-right: 0px;
  padding-bottom: var(--dl-space-space-unit);
}
.prag-mit-zuzablog-text36 {
  font-weight: 700;
}
.prag-mit-zuzablog-container30 {
  flex: 0 0 auto;
  width: 200px;
  height: 100px;
  display: flex;
  align-items: flex-start;
  padding-left: var(--dl-space-space-unit);
  padding-right: var(--dl-space-space-unit);
  flex-direction: column;
  justify-content: flex-start;
}
.prag-mit-zuzablog-container31 {
  width: 100%;
  display: flex;
  align-items: flex-start;
  padding-left: var(--dl-space-space-quadruple);
  flex-direction: column;
  justify-content: flex-start;
}
.prag-mit-zuzablog-button1 {
  width: 165px;
  align-self: flex-start;
  border-color: var(--dl-color-primary-100);
  margin-right: 83px;
}
.prag-mit-zuzablog-container32 {
  flex: 0 0 auto;
  width: auto;
  height: auto;
  display: flex;
  align-items: flex-start;
  justify-content: center;
}
.prag-mit-zuzablog-container33 {
  flex: 0 0 auto;
  width: 333px;
  height: 36px;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.prag-mit-zuzablog-checkbox {
  align-self: center;
}
.prag-mit-zuzablog-text38 {
  font-size: 8px;
  align-self: center;
  font-style: italic;
  padding-left: var(--dl-space-space-unit);
}
.prag-mit-zuzablog-footer {
  color: var(--dl-color-gray-white);
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-tripleunit);
  z-index: 100;
  box-shadow: 5px 5px 10px 0px #000000;
  align-items: center;
  flex-direction: row;
  justify-content: center;
  background-color: var(--dl-color-gray-black);
}
.prag-mit-zuzablog-menu {
  width: 100%;
  display: flex;
  max-width: 1000px;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.prag-mit-zuzablog-logo2 {
  fill: var(--dl-color-primary-main1);
  color: var(--dl-color-primary-main1);
  font-family: "Pano-Regular";
}
.prag-mit-zuzablog-links-container2 {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.prag-mit-zuzablog-container34 {
  display: flex;
  align-items: flex-start;
  margin-left: 100px;
  flex-direction: column;
}
.prag-mit-zuzablog-navlink1 {
  align-self: center;
  text-align: left;
  padding-top: var(--dl-space-space-halfunit);
  padding-bottom: var(--dl-space-space-halfunit);
  text-decoration: none;
}
.prag-mit-zuzablog-navlink2 {
  align-self: stretch;
  padding-top: var(--dl-space-space-halfunit);
  padding-bottom: var(--dl-space-space-halfunit);
  text-decoration: none;
}
.prag-mit-zuzablog-container35 {
  display: flex;
  align-items: flex-start;
  margin-left: 100px;
  flex-direction: column;
}
.prag-mit-zuzablog-navlink3 {
  align-self: stretch;
  padding-top: var(--dl-space-space-halfunit);
  padding-bottom: var(--dl-space-space-halfunit);
  text-decoration: none;
}
.prag-mit-zuzablog-container36 {
  display: flex;
  margin-top: var(--dl-space-space-halfunit);
  align-items: flex-start;
  margin-left: 100px;
  margin-bottom: var(--dl-space-space-halfunit);
  flex-direction: column;
}
.prag-mit-zuzablog-navlink4 {
  margin-top: var(--dl-space-space-halfunit);
  margin-bottom: var(--dl-space-space-halfunit);
  text-decoration: none;
}
.prag-mit-zuzablog-navlink5 {
  margin-top: var(--dl-space-space-halfunit);
  margin-bottom: var(--dl-space-space-halfunit);
  text-decoration: none;
}
.prag-mit-zuzablog-follow-container1 {
  display: flex;
  align-items: center;
  margin-left: 50px;
  flex-direction: column;
  justify-content: space-between;
}
.prag-mit-zuzablog-text41 {
  text-align: center;
  padding-bottom: var(--dl-space-space-halfunit);
}
.prag-mit-zuzablog-icons-container1 {
  width: 100px;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  justify-content: space-between;
}
.prag-mit-zuzablog-link08 {
  display: contents;
}
.prag-mit-zuzablog-icon11 {
  fill: var(--dl-color-gray-white);
  width: 24px;
  height: 24px;
}
.prag-mit-zuzablog-link09 {
  display: contents;
}
.prag-mit-zuzablog-icon13 {
  fill: var(--dl-color-gray-white);
  width: 24px;
  height: 24px;
}
.prag-mit-zuzablog-container37 {
  width: 100%;
  height: auto;
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-direction: column;
  padding-bottom: var(--dl-space-space-quadruple);
  background-color: #000000;
}
.prag-mit-zuzablog-max-width2 {
  gap: 80px;
  width: 2300px;
  height: auto;
  display: flex;
  overflow: hidden;
  max-width: 1400px;
  align-self: center;
  align-items: flex-start;
  flex-shrink: 0;
  flex-direction: column;
}
.prag-mit-zuzablog-container38 {
  height: 263px;
  align-self: stretch;
  align-items: center;
  flex-shrink: 0;
  border-color: var(--dl-color-gray-black);
  border-width: 20px;
  background-image: linear-gradient(to right, #bdc3c7 0%, #2c3e50 100%);
}
.prag-mit-zuzablog-container39 {
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  background-color: var(--dl-color-theme-neutral-dark);
}
.prag-mit-zuzablog-container40 {
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.prag-mit-zuzablog-placeholder-image {
  width: 527px;
  height: 100%;
  opacity: 0.3;
  z-index: 0;
  max-height: 400px;
  border-radius: 0px;
}
.prag-mit-zuzablog-column {
  gap: 24px;
  display: flex;
  flex-grow: 1;
  align-items: flex-start;
  padding-top: 30px;
  padding-left: var(--dl-space-space-doubleunit);
  padding-right: var(--dl-space-space-doubleunit);
  flex-direction: column;
}
.prag-mit-zuzablog-text42 {
  font-family: "Roboto Mono";
}
.prag-mit-zuzablog-text43 {
  color: rgb(255, 255, 255);
  width: 714px;
  height: 35px;
  font-size: 14px;
  font-style: italic;
  font-family: "Times New Roman";
  font-weight: 700;
}
.prag-mit-zuzablog-container41 {
  width: 100%;
  height: 39px;
  display: flex;
  align-items: flex-start;
  padding-bottom: 50px;
}
.prag-mit-zuzablog-form1 {
  width: 200px;
  height: 100px;
  display: flex;
  align-items: flex-start;
}
.prag-mit-zuzablog-container42 {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.prag-mit-zuzablog-container43 {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.prag-mit-zuzablog-actions {
  gap: 24px;
  display: flex;
  align-items: flex-start;
  padding-top: 0px;
  padding-left: 16px;
}
.prag-mit-zuzablog-button2 {
  font-size: 12px;
  align-self: flex-start;
  font-style: italic;
  transition: 0.3s;
  align-items: flex-start;
  font-weight: 700;
  padding-top: var(--dl-space-space-halfunit);
  border-color: #ffffff;
  border-width: 0px;
  padding-left: var(--dl-space-space-tripleunit);
  border-radius: var(--dl-radius-radius-buttonradius);
  margin-bottom: var(--dl-space-space-unit);
  padding-right: var(--dl-space-space-tripleunit);
  padding-bottom: var(--dl-space-space-halfunit);
  justify-content: flex-start;
  background-color: rgb(9, 69, 123);
}
.prag-mit-zuzablog-button2:hover {
  background-color: #065ead;
}
.prag-mit-zuzablog-thq-dropdown {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: 2px;
}
.prag-mit-zuzablog-dropdown-list {
  left: 0%;
  width: max-content;
  display: none;
  z-index: 100;
  position: absolute;
  min-width: 100%;
  transition: 0.3s;
  align-items: stretch;
  border-color: #D9D9D9;
  border-width: 1px;
  border-radius: 4px;
  flex-direction: column;
  list-style-type: none;
  background-color: var(--dl-color-gray-white);
  list-style-position: inside;
}
.prag-mit-zuzablog-dropdown {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: 2px;
}
.prag-mit-zuzablog-dropdown-toggle {
  fill: #595959;
  color: #595959;
  width: 100%;
  display: inline-flex;
  transition: 0.3s;
  align-items: center;
  padding-top: var(--dl-space-space-halfunit);
  padding-left: var(--dl-space-space-unit);
  border-radius: 4px;
  padding-right: var(--dl-space-space-unit);
  padding-bottom: var(--dl-space-space-halfunit);
}
.prag-mit-zuzablog-dropdown-toggle:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}
.prag-mit-zuzablog-text44 {
  width: 100%;
  cursor: pointer;
  display: flex;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
}
.prag-mit-zuzablog-dropdown1 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: 2px;
}
.prag-mit-zuzablog-dropdown-toggle1 {
  fill: #595959;
  color: #595959;
  width: 100%;
  display: inline-flex;
  transition: 0.3s;
  align-items: center;
  padding-top: var(--dl-space-space-halfunit);
  padding-left: var(--dl-space-space-unit);
  border-radius: 4px;
  padding-right: var(--dl-space-space-unit);
  padding-bottom: var(--dl-space-space-halfunit);
}
.prag-mit-zuzablog-dropdown-toggle1:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}
.prag-mit-zuzablog-text45 {
  width: 100%;
  cursor: pointer;
  display: flex;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
}
.prag-mit-zuzablog-dropdown2 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: 2px;
}
.prag-mit-zuzablog-dropdown-toggle2 {
  fill: #595959;
  color: #595959;
  width: 100%;
  display: inline-flex;
  transition: 0.3s;
  align-items: center;
  padding-top: var(--dl-space-space-halfunit);
  padding-left: var(--dl-space-space-unit);
  border-radius: 4px;
  padding-right: var(--dl-space-space-unit);
  padding-bottom: var(--dl-space-space-halfunit);
}
.prag-mit-zuzablog-dropdown-toggle2:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}
.prag-mit-zuzablog-text46 {
  width: 100%;
  cursor: pointer;
  display: flex;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
}
@media(max-width: 991px) {
  .prag-mit-zuzablog-link01 {
    display: none;
  }
  .prag-mit-zuzablog-button {
    display: none;
  }
  .prag-mit-zuzablog-gallery3 {
    display: none;
  }
  .prag-mit-zuzablog-text16 {
    font-family: Pano-Regular;
  }
  .prag-mit-zuzablog-content {
    align-items: center;
    flex-direction: column;
  }
  .prag-mit-zuzablog-image1 {
    width: 200px;
    height: 200px;
    box-shadow: 5px 5px 10px 0px #d4d4d4;
    border-color: var(--dl-color-gray-black);
    border-width: 3px;
    border-radius: var(--dl-radius-radius-round);
  }
  .prag-mit-zuzablog-container05 {
    width: 100%;
  }
  .prag-mit-zuzablog-image3 {
    display: none;
  }
  .prag-mit-zuzablog-container06 {
    width: 100%;
  }
  .prag-mit-zuzablog-contact20 {
    padding-top: 0px;
  }
  .prag-mit-zuzablog-max-width1 {
    display: flex;
    padding-bottom: var(--dl-space-space-halfunit);
  }
  .prag-mit-zuzablog-container07 {
    width: 100%;
    height: 100%;
    display: none;
    align-items: center;
    flex-direction: column;
    justify-content: flex-start;
    background-color: rgba(58, 58, 58, 0.23);
  }
  .prag-mit-zuzablog-container09 {
    align-items: center;
    padding-top: var(--dl-space-space-halfunit);
    padding-left: var(--dl-space-space-tripleunit);
    padding-right: var(--dl-space-space-tripleunit);
    padding-bottom: var(--dl-space-space-halfunit);
  }
  .prag-mit-zuzablog-image {
    box-shadow: 5px 5px 10px 0px #d4d4d4;
    border-color: var(--dl-color-gray-black);
    border-style: solid;
    border-width: 3px;
    border-radius: var(--dl-radius-radius-round);
  }
  .prag-mit-zuzablog-text17 {
    fill: var(--dl-color-primary-main1);
    color: var(--dl-color-primary-main1);
    font-family: Roboto Mono;
  }
  .prag-mit-zuzablog-text18 {
    padding-left: var(--dl-space-space-halfunit);
    padding-right: var(--dl-space-space-halfunit);
    background-color: rgb(217, 217, 217);
  }
  .prag-mit-zuzablog-container10 {
    align-items: center;
    padding-top: var(--dl-space-space-halfunit);
    padding-left: var(--dl-space-space-tripleunit);
    padding-right: var(--dl-space-space-tripleunit);
    padding-bottom: var(--dl-space-space-halfunit);
  }
  .prag-mit-zuzablog-text19 {
    fill: var(--dl-color-primary-main1);
    color: var(--dl-color-primary-main1);
    font-family: Roboto Mono;
  }
  .prag-mit-zuzablog-text20 {
    padding-left: var(--dl-space-space-halfunit);
    padding-right: var(--dl-space-space-halfunit);
    background-color: rgb(217, 217, 217);
  }
  .prag-mit-zuzablog-text21 {
    font-style: italic;
    padding-top: 0px;
    padding-bottom: var(--dl-space-space-halfunit);
  }
  .prag-mit-zuzablog-text22 {
    fill: var(--dl-color-primary-main1);
    color: var(--dl-color-primary-main1);
    font-family: Pano-Regular;
  }
  .prag-mit-zuzablog-container12 {
    display: none;
  }
  .prag-mit-zuzablog-text23 {
    fill: var(--dl-color-primary-main1);
    color: var(--dl-color-primary-main1);
    display: none;
    font-family: Roboto Mono;
  }
  .prag-mit-zuzablog-container13 {
    padding-bottom: 43px;
  }
  .prag-mit-zuzablog-container14 {
    display: none;
    padding-bottom: 48px;
  }
  .prag-mit-zuzablog-container18 {
    height: 279px;
    padding-top: 41px;
    padding-bottom: 0px;
  }
  .prag-mit-zuzablog-container19 {
    width: 630px;
    padding-left: 382px;
  }
  .prag-mit-zuzablog-container20 {
    display: none;
  }
  .prag-mit-zuzablog-text24 {
    font-style: italic;
  }
  .prag-mit-zuzablog-container22 {
    width: 630px;
    padding-top: 42px;
    padding-right: 379px;
  }
  .prag-mit-zuzablog-container33 {
    width: 170px;
    height: 65px;
    padding-right: 10px;
  }
  .prag-mit-zuzablog-container37 {
    display: none;
  }
  .prag-mit-zuzablog-container38 {
    flex-direction: column;
  }
  .prag-mit-zuzablog-placeholder-image {
    width: 100%;
  }
}
@media(max-width: 767px) {
  .prag-mit-zuzablog-top-container {
    width: auto;
    height: auto;
  }
  .prag-mit-zuzablog-right-side {
    display: none;
  }
  .prag-mit-zuzablog-burger-menu {
    display: flex;
  }
  .prag-mit-zuzablog-main {
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
  }
  .prag-mit-zuzablog-section-title {
    gap: 24px;
  }
  .prag-mit-zuzablog-max-width1 {
    display: flex;
    flex-direction: column;
  }
  .prag-mit-zuzablog-container12 {
    display: none;
  }
  .prag-mit-zuzablog-text23 {
    display: none;
  }
  .prag-mit-zuzablog-image2 {
    display: flex;
  }
  .prag-mit-zuzablog-container18 {
    align-items: center;
    padding-top: 15px;
    justify-content: center;
  }
  .prag-mit-zuzablog-container19 {
    width: 302px;
    height: 256px;
    align-self: flex-start;
    padding-top: var(--dl-space-space-doubleunit);
    padding-left: 0px;
    padding-right: 0px;
    justify-content: flex-start;
  }
  .prag-mit-zuzablog-text24 {
    font-style: italic;
  }
  .prag-mit-zuzablog-text25 {
    font-style: italic;
  }
  .prag-mit-zuzablog-container22 {
    width: 302px;
    height: 256px;
    align-self: flex-start;
    padding-top: var(--dl-space-space-doubleunit);
    padding-right: var(--dl-space-space-halfunit);
    justify-content: flex-start;
  }
  .prag-mit-zuzablog-text30 {
    font-style: italic;
  }
  .prag-mit-zuzablog-text32 {
    font-weight: 700;
  }
  .prag-mit-zuzablog-container23 {
    display: none;
  }
  .prag-mit-zuzablog-menu {
    flex-direction: column;
  }
  .prag-mit-zuzablog-links-container2 {
    margin-top: var(--dl-space-space-tripleunit);
    margin-bottom: var(--dl-space-space-tripleunit);
    justify-content: space-between;
  }
  .prag-mit-zuzablog-container34 {
    margin-left: 0px;
  }
  .prag-mit-zuzablog-container35 {
    margin-left: 0px;
  }
  .prag-mit-zuzablog-container38 {
    display: none;
  }
}
@media(max-width: 479px) {
  .prag-mit-zuzablog-top-container {
    width: auto;
    height: auto;
  }
  .prag-mit-zuzablog-navbar {
    height: 112px;
  }
  .prag-mit-zuzablog-main {
    height: auto;
    padding-top: 14px;
    padding-bottom: 0px;
  }
  .prag-mit-zuzablog-text07 {
    fill: var(--dl-color-primary-main1);
    color: var(--dl-color-primary-main1);
    text-align: justify;
    padding-top: var(--dl-space-space-doubleunit);
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    padding-bottom: var(--dl-space-space-unit);
  }
  .prag-mit-zuzablog-max-width {
    padding-left: var(--dl-space-space-halfunit);
    padding-right: var(--dl-space-space-halfunit);
  }
  .prag-mit-zuzablog-image1 {
    display: none;
  }
  .prag-mit-zuzablog-container04 {
    flex-direction: column;
  }
  .prag-mit-zuzablog-contact20 {
    padding-top: 196px;
  }
  .prag-mit-zuzablog-max-width1 {
    display: flex;
  }
  .prag-mit-zuzablog-container07 {
    width: 378px;
    height: auto;
    background-color: rgba(58, 58, 58, 0.19);
  }
  .prag-mit-zuzablog-container09 {
    display: none;
  }
  .prag-mit-zuzablog-text21 {
    padding-top: var(--dl-space-space-unit);
  }
  .prag-mit-zuzablog-container12 {
    display: flex;
  }
  .prag-mit-zuzablog-text23 {
    color: var(--dl-color-primary-main1);
    display: flex;
    text-align: justify;
    font-family: Roboto Mono;
    font-weight: 900;
  }
  .prag-mit-zuzablog-image2 {
    display: flex;
  }
  .prag-mit-zuzablog-container13 {
    padding-bottom: 0px;
  }
  .prag-mit-zuzablog-container14 {
    width: 478px;
    height: 570px;
    padding-bottom: 24px;
  }
  .prag-mit-zuzablog-container17 {
    width: 100%;
    align-items: center;
    justify-content: center;
  }
  .prag-mit-zuzablog-container18 {
    width: 433px;
    height: 177px;
    align-items: center;
    padding-top: 0px;
    justify-content: center;
  }
  .prag-mit-zuzablog-container19 {
    width: 348px;
    height: 100%;
    align-self: flex-start;
    align-items: flex-start;
    padding-top: 0px;
    padding-left: var(--dl-space-space-halfunit);
    padding-right: var(--dl-space-space-halfunit);
    justify-content: flex-start;
  }
  .prag-mit-zuzablog-container20 {
    display: flex;
    padding-top: 25px;
  }
  .prag-mit-zuzablog-text24 {
    align-self: flex-start;
    font-style: italic;
    text-align: justify;
  }
  .prag-mit-zuzablog-container21 {
    padding-top: 43px;
  }
  .prag-mit-zuzablog-text25 {
    display: none;
    align-self: flex-start;
    font-style: italic;
    text-align: justify;
  }
  .prag-mit-zuzablog-text28 {
    font-weight: 700;
  }
  .prag-mit-zuzablog-container22 {
    width: 328px;
    height: 321px;
    display: none;
    align-self: center;
    align-items: flex-start;
    padding-top: 103px;
    padding-left: 35px;
  }
  .prag-mit-zuzablog-text30 {
    align-self: center;
    text-align: justify;
  }
  .prag-mit-zuzablog-container23 {
    display: none;
  }
  .prag-mit-zuzablog-text34 {
    fill: var(--dl-color-primary-main1);
    color: var(--dl-color-primary-main1);
    display: none;
    text-align: justify;
    padding-top: var(--dl-space-space-doubleunit);
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    padding-bottom: var(--dl-space-space-unit);
  }
  .prag-mit-zuzablog-links-container2 {
    margin-top: var(--dl-space-space-doubleunit);
    align-items: center;
    justify-content: center;
  }
  .prag-mit-zuzablog-container34 {
    justify-content: center;
  }
  .prag-mit-zuzablog-navlink2 {
    margin-bottom: var(--dl-space-space-halfunit);
  }
  .prag-mit-zuzablog-container35 {
    display: none;
  }
  .prag-mit-zuzablog-navlink3 {
    margin-bottom: var(--dl-space-space-halfunit);
  }
  .prag-mit-zuzablog-container36 {
    height: 100%;
    align-items: flex-start;
    margin-left: 50px;
    justify-content: center;
  }
  .prag-mit-zuzablog-navlink4 {
    align-self: flex-start;
  }
  .prag-mit-zuzablog-navlink5 {
    margin-bottom: var(--dl-space-space-halfunit);
  }
  .prag-mit-zuzablog-follow-container1 {
    margin-left: 0px;
  }
}

.prag-mit-zuza-review-container {
  width: 100%;
  display: flex;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
}
.prag-mit-zuza-review-navbar {
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-doubleunit);
  max-width: 1000px;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.prag-mit-zuza-review-navlink {
  display: contents;
}
.prag-mit-zuza-review-logo {
  fill: var(--dl-color-primary-main1);
  color: var(--dl-color-primary-main1);
  font-family: "Pano-Regular";
  text-decoration: none;
}
.prag-mit-zuza-review-right-side {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.prag-mit-zuza-review-links-container {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.prag-mit-zuza-review-navlink1 {
  margin-right: var(--dl-space-space-doubleunit);
  text-decoration: none;
}
.prag-mit-zuza-review-navlink2 {
  margin-right: var(--dl-space-space-doubleunit);
  text-decoration: none;
}
.prag-mit-zuza-review-navlink3 {
  margin-right: var(--dl-space-space-doubleunit);
  text-decoration: none;
}
.prag-mit-zuza-review-container01 {
  display: flex;
  position: relative;
}
.prag-mit-zuza-review-navlink4 {
  color: var(--dl-color-gray-white);
  border: none;
  transition: 0.3s;
  padding-top: var(--dl-space-space-halfunit);
  padding-left: var(--dl-space-space-doubleunit);
  border-radius: 50px;
  padding-right: var(--dl-space-space-doubleunit);
  padding-bottom: var(--dl-space-space-halfunit);
  text-decoration: none;
  background-color: #108a14;
}
.prag-mit-zuza-review-navlink4:hover {
  color: var(--dl-color-primary-100);
  border: 2px;
  border-color: var(--dl-color-primary-108a14);
  background-color: var(--dl-color-theme-secondary2);
}
.prag-mit-zuza-review-burger-menu {
  display: none;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.prag-mit-zuza-review-burger-menu1 {
  width: 24px;
  height: 24px;
}
.prag-mit-zuza-review-mobile-menu {
  top: 0px;
  flex: 0 0 auto;
  left: 0px;
  width: 100%;
  height: 100%;
  display: none;
  padding: var(--dl-space-space-doubleunit);
  z-index: 100;
  position: absolute;
  flex-direction: column;
  justify-content: space-between;
  background-color: #fff;
}
.prag-mit-zuza-review-nav {
  flex: 0 0 auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.prag-mit-zuza-review-top {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-doubleunit);
  flex-direction: row;
  justify-content: space-between;
}
.prag-mit-zuza-review-close-menu {
  flex: 0 0 auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.prag-mit-zuza-review-icon03 {
  width: 24px;
  height: 24px;
}
.prag-mit-zuza-review-right-side1 {
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: space-between;
}
.prag-mit-zuza-review-links-container1 {
  display: flex;
  align-items: flex-start;
  margin-bottom: 16px;
  flex-direction: column;
  justify-content: space-between;
}
.prag-mit-zuza-review-text02 {
  margin-bottom: 8px;
}
.prag-mit-zuza-review-text03 {
  margin-bottom: 8px;
}
.prag-mit-zuza-review-text04 {
  margin-bottom: 8px;
}
.prag-mit-zuza-review-follow-container {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: space-between;
}
.prag-mit-zuza-review-text06 {
  padding-bottom: var(--dl-space-space-halfunit);
}
.prag-mit-zuza-review-icons-container {
  width: 100px;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  justify-content: space-between;
}
.prag-mit-zuza-review-link {
  display: contents;
}
.prag-mit-zuza-review-icon05 {
  width: 24px;
  height: 24px;
}
.prag-mit-zuza-review-link1 {
  display: contents;
}
.prag-mit-zuza-review-icon07 {
  width: 24px;
  height: 24px;
}
.prag-mit-zuza-review-link2 {
  display: contents;
}
.prag-mit-zuza-review-icon09 {
  width: 24px;
  height: 24px;
}
.prag-mit-zuza-review-container02 {
  gap: 48px;
  width: 100%;
  display: flex;
  position: relative;
  align-items: center;
  flex-direction: column;
}
.prag-mit-zuza-review-max-width {
  gap: 32px;
  width: 100%;
  display: flex;
  align-self: center;
  align-items: center;
  padding-top: 46px;
  flex-direction: column;
  justify-content: center;
}
.prag-mit-zuza-review-text07 {
  width: 45%;
  text-align: center;
  font-family: "Roboto Mono";
}
.prag-mit-zuza-review-container03 {
  flex: 0 0 auto;
  width: 100%;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.prag-mit-zuza-review-container04 {
  display: flex;
  align-items: center;
  padding-left: var(--dl-space-space-quadruple);
  padding-right: var(--dl-space-space-quadruple);
  flex-direction: column;
  justify-content: center;
}
.prag-mit-zuza-review-link3 {
  display: contents;
}
.prag-mit-zuza-review-logo3 {
  width: 200px;
  object-fit: contain;
  text-decoration: none;
}
.prag-mit-zuza-review-container05 {
  display: flex;
  align-items: center;
  padding-left: var(--dl-space-space-quadruple);
  padding-right: var(--dl-space-space-quadruple);
  flex-direction: column;
  justify-content: center;
}
.prag-mit-zuza-review-logo4 {
  width: 200px;
  object-fit: contain;
}
.prag-mit-zuza-review-text11 {
  width: 45%;
  text-align: center;
  font-family: "Roboto Mono";
  padding-top: 36px;
}
.prag-mit-zuza-review-max-width1 {
  gap: 80px;
  width: 100%;
  display: flex;
  overflow: hidden;
  position: relative;
  align-items: center;
  flex-shrink: 0;
  padding-top: 48px;
  flex-direction: column;
}
.prag-mit-zuza-review-icon11 {
  width: 24px;
  height: 24px;
}
.prag-mit-zuza-review-section-title {
  gap: 24px;
  width: 881px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.prag-mit-zuza-review-title {
  width: 100%;
  font-style: italic;
  text-align: center;
}
.prag-mit-zuza-review-subtitle {
  text-align: center;
}
.prag-mit-zuza-review-text12 {
  font-style: italic;
}
.prag-mit-zuza-review-text13 {
  font-style: italic;
  font-weight: 700;
}
.prag-mit-zuza-review-text14 {
  font-weight: 700;
}
.prag-mit-zuza-review-container06 {
  gap: 32px;
  width: 1200px;
  padding-bottom: 45px;
  justify-content: center;
}
.prag-mit-zuza-review-content {
  gap: 32px;
  width: 400px;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.prag-mit-zuza-review-stars {
  fill: var(--dl-color-theme-primary1);
  display: flex;
  align-items: center;
  flex-direction: row;
}
.prag-mit-zuza-review-text16 {
  text-align: center;
}
.prag-mit-zuza-review-link4 {
  display: contents;
}
.prag-mit-zuza-review-avatar {
  gap: 24px;
  display: flex;
  align-items: center;
  flex-direction: column;
  text-decoration: none;
}
.prag-mit-zuza-review-avatar-image {
  width: 56px;
  height: 56px;
  object-fit: cover;
}
.prag-mit-zuza-review-avatar-content {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.prag-mit-zuza-review-text17 {
  font-style: normal;
  font-weight: 600;
}
.prag-mit-zuza-review-logo2 {
  height: 2rem;
}
.prag-mit-zuza-review-content1 {
  gap: 32px;
  width: 400px;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.prag-mit-zuza-review-stars1 {
  fill: var(--dl-color-theme-primary1);
  display: flex;
  align-items: center;
  flex-direction: row;
}
.prag-mit-zuza-review-text19 {
  text-align: center;
}
.prag-mit-zuza-review-link5 {
  display: contents;
}
.prag-mit-zuza-review-avatar1 {
  gap: 24px;
  display: flex;
  align-items: center;
  flex-direction: column;
  text-decoration: none;
}
.prag-mit-zuza-review-avatar-image1 {
  width: 56px;
  height: 56px;
  object-fit: cover;
}
.prag-mit-zuza-review-avatar-content1 {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.prag-mit-zuza-review-text20 {
  font-style: normal;
  font-weight: 600;
}
.prag-mit-zuza-review-logo3 {
  height: 2rem;
}
.prag-mit-zuza-review-icon33 {
  width: 24px;
  height: 24px;
}
.prag-mit-zuza-review-contact1 {
  width: 1100px;
  display: flex;
  position: relative;
  align-items: center;
  padding-top: 65px;
  padding-left: var(--dl-space-space-halfunit);
  padding-right: var(--dl-space-space-halfunit);
  flex-direction: column;
  padding-bottom: 0px;
}
.prag-mit-zuza-review-max-width2 {
  align-items: flex-start;
  margin-bottom: 33px;
}
.prag-mit-zuza-review-section-title1 {
  gap: var(--dl-space-space-unit);
  width: 40%;
  align-items: flex-start;
}
.prag-mit-zuza-review-content2 {
  gap: 24px;
  display: flex;
  align-self: stretch;
  flex-direction: column;
}
.prag-mit-zuza-review-text22 {
  font-family: "Noto Sans";
  padding-right: 20px;
}
.prag-mit-zuza-review-content3 {
  gap: 16px;
  display: flex;
  padding: 8px 0;
  align-items: flex-start;
  flex-direction: column;
}
.prag-mit-zuza-review-row {
  gap: 16px;
  display: flex;
  align-items: flex-start;
}
.prag-mit-zuza-review-row1 {
  gap: 16px;
  display: flex;
  align-items: flex-start;
}
.prag-mit-zuza-review-row2 {
  gap: 16px;
  display: flex;
  align-items: flex-start;
}
.prag-mit-zuza-review-form {
  gap: 24px;
  width: 60%;
}
.prag-mit-zuza-review-container07 {
  gap: var(--dl-space-space-unit);
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
}
.prag-mit-zuza-review-input {
  gap: var(--dl-space-space-halfunit);
  width: 50%;
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-direction: column;
}
.prag-mit-zuza-review-input1 {
  gap: var(--dl-space-space-halfunit);
  width: 50%;
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-direction: column;
}
.prag-mit-zuza-review-container08 {
  gap: var(--dl-space-space-unit);
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
}
.prag-mit-zuza-review-input2 {
  gap: var(--dl-space-space-halfunit);
  width: 50%;
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-direction: column;
}
.prag-mit-zuza-review-input3 {
  gap: var(--dl-space-space-halfunit);
  width: 50%;
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-direction: column;
}
.prag-mit-zuza-review-container09 {
  flex: 0 0 auto;
  width: 50%;
  display: flex;
  align-self: flex-start;
  align-items: flex-start;
  flex-direction: column;
}
.prag-mit-zuza-review-input4 {
  gap: var(--dl-space-space-halfunit);
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-direction: column;
}
.prag-mit-zuza-review-container10 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-self: flex-start;
  align-items: flex-start;
  flex-direction: column;
}
.prag-mit-zuza-review-input5 {
  gap: var(--dl-space-space-halfunit);
  width: 100%;
  display: flex;
  align-self: flex-start;
  align-items: flex-start;
  flex-direction: column;
}
.prag-mit-zuza-review-checkbox {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-self: flex-start;
  align-items: center;
}
.prag-mit-zuza-review-button {
  align-self: flex-start;
  font-family: "Roboto Mono";
  padding-left: var(--dl-space-space-doubleunit);
  padding-right: var(--dl-space-space-doubleunit);
}
.prag-mit-zuza-review-footer {
  color: var(--dl-color-gray-white);
  width: 3200px;
  display: flex;
  padding: var(--dl-space-space-tripleunit);
  z-index: 100;
  box-shadow: 5px 5px 10px 0px #000000;
  align-items: flex-end;
  flex-direction: row;
  justify-content: center;
  background-color: var(--dl-color-gray-black);
}
.prag-mit-zuza-review-menu {
  width: 100%;
  display: flex;
  max-width: 1000px;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.prag-mit-zuza-review-logo4 {
  fill: var(--dl-color-primary-main1);
  color: var(--dl-color-primary-main1);
  font-family: "Pano-Regular";
}
.prag-mit-zuza-review-links-container2 {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.prag-mit-zuza-review-container11 {
  display: flex;
  align-items: flex-start;
  margin-left: 100px;
  flex-direction: column;
}
.prag-mit-zuza-review-navlink5 {
  align-self: center;
  text-align: center;
  padding-top: var(--dl-space-space-halfunit);
  padding-bottom: var(--dl-space-space-halfunit);
  text-decoration: none;
}
.prag-mit-zuza-review-navlink6 {
  align-self: stretch;
  padding-top: var(--dl-space-space-halfunit);
  padding-bottom: var(--dl-space-space-halfunit);
  text-decoration: none;
}
.prag-mit-zuza-review-container12 {
  display: flex;
  margin-top: var(--dl-space-space-halfunit);
  align-items: flex-start;
  margin-left: 100px;
  margin-bottom: var(--dl-space-space-halfunit);
  flex-direction: column;
}
.prag-mit-zuza-review-navlink7 {
  margin-top: var(--dl-space-space-halfunit);
  margin-bottom: var(--dl-space-space-halfunit);
  text-decoration: none;
}
.prag-mit-zuza-review-navlink8 {
  margin-top: var(--dl-space-space-halfunit);
  margin-bottom: var(--dl-space-space-halfunit);
  text-decoration: none;
}
.prag-mit-zuza-review-follow-container1 {
  display: flex;
  align-items: center;
  margin-left: 50px;
  flex-direction: column;
  justify-content: space-between;
}
.prag-mit-zuza-review-text32 {
  text-align: center;
  padding-bottom: var(--dl-space-space-halfunit);
}
.prag-mit-zuza-review-icons-container1 {
  width: 100px;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  justify-content: space-between;
}
.prag-mit-zuza-review-link6 {
  display: contents;
}
.prag-mit-zuza-review-icon41 {
  fill: var(--dl-color-gray-white);
  width: 24px;
  height: 24px;
}
.prag-mit-zuza-review-link7 {
  display: contents;
}
.prag-mit-zuza-review-icon43 {
  fill: var(--dl-color-gray-white);
  width: 24px;
  height: 24px;
}
@media(max-width: 991px) {
  .prag-mit-zuza-review-text07 {
    width: 849px;
    text-align: center;
  }
  .prag-mit-zuza-review-text11 {
    width: 100%;
    text-align: center;
  }
  .prag-mit-zuza-review-testimonial1 {
    width: auto;
  }
  .prag-mit-zuza-review-max-width1 {
    padding-top: 66px;
    padding-right: 0px;
    justify-content: center;
  }
  .prag-mit-zuza-review-section-title {
    align-items: center;
  }
  .prag-mit-zuza-review-title {
    text-decoration: underline;
  }
  .prag-mit-zuza-review-container06 {
    width: auto;
    height: 580px;
    align-items: flex-start;
    margin-left: var(--dl-space-space-halfunit);
    margin-right: var(--dl-space-space-halfunit);
    justify-content: center;
  }
  .prag-mit-zuza-review-content1 {
    width: 400px;
    height: auto;
    align-items: center;
    padding-right: 0px;
  }
  .prag-mit-zuza-review-text19 {
    font-family: Noto Sans;
  }
  .prag-mit-zuza-review-contact1 {
    width: 100%;
  }
  .prag-mit-zuza-review-max-width2 {
    width: 991px;
    align-items: flex-start;
    margin-left: var(--dl-space-space-doubleunit);
    margin-right: var(--dl-space-space-doubleunit);
    padding-left: var(--dl-space-space-doubleunit);
    padding-right: var(--dl-space-space-doubleunit);
    flex-direction: column;
  }
  .prag-mit-zuza-review-section-title1 {
    width: 100%;
  }
  .prag-mit-zuza-review-text22 {
    font-family: Fira Sans;
  }
  .prag-mit-zuza-review-email {
    font-family: Noto Sans;
  }
  .prag-mit-zuza-review-phone {
    font-family: Noto Sans;
  }
  .prag-mit-zuza-review-address {
    font-family: Noto Sans;
  }
  .prag-mit-zuza-review-form {
    width: 100%;
  }
  .prag-mit-zuza-review-container07 {
    gap: 32px;
    width: 100%;
    flex-direction: row;
  }
  .prag-mit-zuza-review-input {
    width: 50%;
  }
  .prag-mit-zuza-review-text23 {
    font-family: Noto Sans;
  }
  .prag-mit-zuza-review-input1 {
    width: 50%;
    flex-direction: column;
  }
  .prag-mit-zuza-review-text24 {
    font-family: Noto Sans;
  }
  .prag-mit-zuza-review-container08 {
    gap: 32px;
    width: 100%;
    flex-direction: row;
  }
  .prag-mit-zuza-review-input2 {
    width: 50%;
  }
  .prag-mit-zuza-review-text25 {
    font-family: Noto Sans;
  }
  .prag-mit-zuza-review-input3 {
    width: 50%;
  }
  .prag-mit-zuza-review-text26 {
    font-family: Noto Sans;
  }
  .prag-mit-zuza-review-container09 {
    gap: 32px;
    width: 100%;
    flex-direction: row;
  }
  .prag-mit-zuza-review-input4 {
    gap: var(--dl-space-space-halfunit);
    width: 50%;
    flex-direction: column;
  }
  .prag-mit-zuza-review-text27 {
    font-family: Noto Sans;
  }
  .prag-mit-zuza-review-container10 {
    gap: 32px;
    width: 100%;
    flex-direction: row;
  }
  .prag-mit-zuza-review-text28 {
    font-family: Noto Sans;
  }
  .prag-mit-zuza-review-checkbox {
    align-self: flex-start;
  }
}
@media(max-width: 767px) {
  .prag-mit-zuza-review-right-side {
    display: none;
  }
  .prag-mit-zuza-review-burger-menu {
    display: flex;
  }
  .prag-mit-zuza-review-container02 {
    gap: 32px;
  }
  .prag-mit-zuza-review-max-width {
    gap: 32px;
  }
  .prag-mit-zuza-review-text07 {
    width: 90%;
    padding-left: 0px;
    padding-right: 0px;
  }
  .prag-mit-zuza-review-text11 {
    font-family: Roboto Mono;
  }
  .prag-mit-zuza-review-max-width1 {
    padding: 48px;
    padding-top: 48px;
    padding-left: 48px;
    padding-right: 48px;
    padding-bottom: 48px;
  }
  .prag-mit-zuza-review-section-title {
    width: 100%;
    max-width: 800px;
  }
  .prag-mit-zuza-review-container06 {
    height: auto;
    flex-direction: column;
  }
  .prag-mit-zuza-review-content {
    width: auto;
    height: auto;
    padding-bottom: 140px;
  }
  .prag-mit-zuza-review-content1 {
    width: auto;
    padding-bottom: 122px;
  }
  .prag-mit-zuza-review-contact1 {
    width: 100%;
    height: 899px;
    padding-right: var(--dl-space-space-halfunit);
  }
  .prag-mit-zuza-review-max-width2 {
    width: 100%;
  }
  .prag-mit-zuza-review-section-title1 {
    padding-bottom: 38px;
  }
  .prag-mit-zuza-review-text22 {
    font-family: Pano-Regular;
    padding-left: 48px;
    padding-right: 57px;
  }
  .prag-mit-zuza-review-content3 {
    padding-left: 49px;
  }
  .prag-mit-zuza-review-container07 {
    padding-left: var(--dl-space-space-tripleunit);
    padding-right: var(--dl-space-space-tripleunit);
  }
  .prag-mit-zuza-review-container08 {
    padding-left: var(--dl-space-space-tripleunit);
    padding-right: var(--dl-space-space-tripleunit);
  }
  .prag-mit-zuza-review-container09 {
    padding-left: var(--dl-space-space-tripleunit);
    padding-right: var(--dl-space-space-tripleunit);
  }
  .prag-mit-zuza-review-container10 {
    padding-left: var(--dl-space-space-tripleunit);
    padding-right: var(--dl-space-space-tripleunit);
  }
  .prag-mit-zuza-review-checkbox {
    margin-left: var(--dl-space-space-tripleunit);
    padding-left: var(--dl-space-space-tripleunit);
    padding-right: var(--dl-space-space-tripleunit);
  }
  .prag-mit-zuza-review-button {
    margin-left: var(--dl-space-space-tripleunit);
    padding-left: var(--dl-space-space-tripleunit);
    padding-right: var(--dl-space-space-tripleunit);
  }
  .prag-mit-zuza-review-menu {
    flex-direction: column;
  }
  .prag-mit-zuza-review-links-container2 {
    margin-top: var(--dl-space-space-tripleunit);
    margin-bottom: var(--dl-space-space-tripleunit);
    justify-content: space-between;
  }
  .prag-mit-zuza-review-container11 {
    margin-left: 0px;
  }
}
@media(max-width: 479px) {
  .prag-mit-zuza-review-container04 {
    width: 100px;
    padding-left: 160px;
  }
  .prag-mit-zuza-review-text11 {
    fill: var(--dl-color-primary-main1);
    color: var(--dl-color-primary-main1);
    font-style: italic;
    font-family: Roboto Mono;
  }
  .prag-mit-zuza-review-max-width1 {
    padding: 24px;
    padding-top: 24px;
    padding-left: 24px;
    padding-right: 24px;
    padding-bottom: 24px;
  }
  .prag-mit-zuza-review-title {
    font-style: italic;
  }
  .prag-mit-zuza-review-avatar {
    flex-direction: column;
  }
  .prag-mit-zuza-review-avatar-content {
    align-items: center;
    justify-content: center;
  }
  .prag-mit-zuza-review-content1 {
    height: 560px;
  }
  .prag-mit-zuza-review-avatar1 {
    flex-direction: column;
  }
  .prag-mit-zuza-review-avatar-content1 {
    align-items: center;
    justify-content: center;
  }
  .prag-mit-zuza-review-contact1 {
    width: auto;
    height: 1212px;
    margin-right: 0px;
  }
  .prag-mit-zuza-review-form {
    padding: var(--dl-space-space-unit);
  }
  .prag-mit-zuza-review-container07 {
    align-items: stretch;
    flex-direction: column;
  }
  .prag-mit-zuza-review-input {
    width: 100%;
  }
  .prag-mit-zuza-review-input1 {
    width: 100%;
  }
  .prag-mit-zuza-review-container08 {
    align-items: stretch;
    flex-direction: column;
  }
  .prag-mit-zuza-review-input2 {
    width: 100%;
  }
  .prag-mit-zuza-review-input3 {
    width: 100%;
  }
  .prag-mit-zuza-review-links-container2 {
    margin-top: var(--dl-space-space-doubleunit);
    align-items: flex-start;
    justify-content: flex-start;
  }
  .prag-mit-zuza-review-navlink5 {
    margin-bottom: var(--dl-space-space-halfunit);
  }
  .prag-mit-zuza-review-navlink6 {
    margin-bottom: var(--dl-space-space-halfunit);
  }
  .prag-mit-zuza-review-container12 {
    height: 100%;
    align-items: flex-start;
    margin-left: 50px;
  }
  .prag-mit-zuza-review-navlink7 {
    margin-bottom: var(--dl-space-space-halfunit);
  }
  .prag-mit-zuza-review-navlink8 {
    margin-bottom: var(--dl-space-space-halfunit);
  }
  .prag-mit-zuza-review-follow-container1 {
    margin-left: 0px;
  }
}

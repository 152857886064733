.prag-mit-zuza-exkluziv-private-touren-contactpage-container {
  width: 100%;
  display: flex;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
  background-image: radial-gradient(circle at center, rgb(0, 0, 0) 2.00%,rgb(3, 3, 3) 50.00%,rgb(26, 0, 37) 99.00%);
}
.prag-mit-zuza-exkluziv-private-touren-contactpage-navbar {
  width: 100%;
  display: flex;
  max-width: 1000px;
  align-items: center;
  padding-top: var(--dl-space-space-doubleunit);
  padding-left: var(--dl-space-space-doubleunit);
  padding-right: var(--dl-space-space-doubleunit);
  flex-direction: row;
  padding-bottom: var(--dl-space-space-unit);
  justify-content: space-between;
}
.prag-mit-zuza-exkluziv-private-touren-contactpage-navlink {
  display: contents;
}
.prag-mit-zuza-exkluziv-private-touren-contactpage-logo {
  fill: var(--dl-color-theme-secondary1);
  color: var(--dl-color-theme-secondary1);
  font-family: "Pano-Regular";
  text-decoration: none;
}
.prag-mit-zuza-exkluziv-private-touren-contactpage-right-side {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.prag-mit-zuza-exkluziv-private-touren-contactpage-links-container {
  display: none;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.prag-mit-zuza-exkluziv-private-touren-contactpage-navlink1 {
  fill: var(--dl-color-theme-secondary1);
  color: var(--dl-color-theme-secondary1);
  margin-right: var(--dl-space-space-doubleunit);
  text-decoration: none;
}
.prag-mit-zuza-exkluziv-private-touren-contactpage-burger-menu {
  display: none;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.prag-mit-zuza-exkluziv-private-touren-contactpage-burger-menu1 {
  width: 24px;
  height: 24px;
}
.prag-mit-zuza-exkluziv-private-touren-contactpage-mobile-menu {
  top: 0px;
  flex: 0 0 auto;
  left: 0px;
  width: 100%;
  height: 100%;
  display: none;
  padding: var(--dl-space-space-doubleunit);
  z-index: 100;
  position: absolute;
  flex-direction: column;
  justify-content: space-between;
  background-color: #fff;
}
.prag-mit-zuza-exkluziv-private-touren-contactpage-nav {
  flex: 0 0 auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.prag-mit-zuza-exkluziv-private-touren-contactpage-top {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-doubleunit);
  flex-direction: row;
  justify-content: space-between;
}
.prag-mit-zuza-exkluziv-private-touren-contactpage-close-menu {
  flex: 0 0 auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.prag-mit-zuza-exkluziv-private-touren-contactpage-icon03 {
  width: 24px;
  height: 24px;
}
.prag-mit-zuza-exkluziv-private-touren-contactpage-right-side1 {
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: space-between;
}
.prag-mit-zuza-exkluziv-private-touren-contactpage-links-container1 {
  display: flex;
  align-items: flex-start;
  margin-bottom: 16px;
  flex-direction: column;
  justify-content: space-between;
}
.prag-mit-zuza-exkluziv-private-touren-contactpage-text02 {
  margin-bottom: 8px;
}
.prag-mit-zuza-exkluziv-private-touren-contactpage-text03 {
  margin-bottom: 8px;
}
.prag-mit-zuza-exkluziv-private-touren-contactpage-text04 {
  margin-bottom: 8px;
}
.prag-mit-zuza-exkluziv-private-touren-contactpage-follow-container {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: space-between;
}
.prag-mit-zuza-exkluziv-private-touren-contactpage-text06 {
  padding-bottom: var(--dl-space-space-halfunit);
}
.prag-mit-zuza-exkluziv-private-touren-contactpage-icons-container {
  width: 100px;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  justify-content: space-between;
}
.prag-mit-zuza-exkluziv-private-touren-contactpage-link {
  display: contents;
}
.prag-mit-zuza-exkluziv-private-touren-contactpage-icon05 {
  width: 24px;
  height: 24px;
}
.prag-mit-zuza-exkluziv-private-touren-contactpage-link01 {
  display: contents;
}
.prag-mit-zuza-exkluziv-private-touren-contactpage-icon07 {
  width: 24px;
  height: 24px;
}
.prag-mit-zuza-exkluziv-private-touren-contactpage-link02 {
  display: contents;
}
.prag-mit-zuza-exkluziv-private-touren-contactpage-icon09 {
  width: 24px;
  height: 24px;
}
.prag-mit-zuza-exkluziv-private-touren-contactpage-container01 {
  width: 100%;
  padding-top: 28px;
  margin-bottom: 59px;
  flex-direction: column;
  padding-bottom: 28px;
  background-color: #D9D9D9;
}
.prag-mit-zuza-exkluziv-private-touren-contactpage-max-width {
  gap: 64px;
  width: 898px;
  height: 547px;
  display: flex;
  align-items: center;
}
.prag-mit-zuza-exkluziv-private-touren-contactpage-container02 {
  width: 1053px;
  height: 543px;
  max-width: 50%;
}
.prag-mit-zuza-exkluziv-private-touren-contactpage-image {
  width: 414px;
  height: 761px;
}
.prag-mit-zuza-exkluziv-private-touren-contactpage-container03 {
  width: 600px;
  display: none;
  align-items: center;
  padding-top: var(--dl-space-space-doubleunit);
  padding-left: var(--dl-space-space-doubleunit);
  border-radius: var(--dl-radius-radius-cardradius);
  padding-right: var(--dl-space-space-doubleunit);
  padding-bottom: var(--dl-space-space-doubleunit);
  justify-content: center;
  background-color: #3a3a3a;
}
.prag-mit-zuza-exkluziv-private-touren-contactpage-container04 {
  display: flex;
  align-items: center;
  justify-content: center;
}
.prag-mit-zuza-exkluziv-private-touren-contactpage-container05 {
  flex: 0 0 auto;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.prag-mit-zuza-exkluziv-private-touren-contactpage-image1 {
  width: 200px;
  object-fit: cover;
}
.prag-mit-zuza-exkluziv-private-touren-contactpage-link03 {
  display: contents;
}
.prag-mit-zuza-exkluziv-private-touren-contactpage-text08 {
  text-decoration: none;
}
.prag-mit-zuza-exkluziv-private-touren-contactpage-container06 {
  flex: 0 0 auto;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.prag-mit-zuza-exkluziv-private-touren-contactpage-image2 {
  width: 200px;
  object-fit: cover;
}
.prag-mit-zuza-exkluziv-private-touren-contactpage-link04 {
  display: contents;
}
.prag-mit-zuza-exkluziv-private-touren-contactpage-text10 {
  text-decoration: none;
}
.prag-mit-zuza-exkluziv-private-touren-contactpage-container07 {
  flex: 0 0 auto;
  width: auto;
  display: flex;
  align-self: stretch;
  align-items: stretch;
  flex-direction: column;
  justify-content: flex-start;
}
.prag-mit-zuza-exkluziv-private-touren-contactpage-text11 {
  align-self: flex-end;
  text-align: justify;
}
.prag-mit-zuza-exkluziv-private-touren-contactpage-text12 {
  text-align: center;
  font-family: "Pano-Regular";
}
.prag-mit-zuza-exkluziv-private-touren-contactpage-container08 {
  display: flex;
  align-items: center;
  justify-content: center;
}
.prag-mit-zuza-exkluziv-private-touren-contactpage-container09 {
  width: 343px;
  display: flex;
  align-items: center;
}
.prag-mit-zuza-exkluziv-private-touren-contactpage-container10 {
  width: 309px;
  height: 540px;
  align-self: center;
  align-items: flex-start;
  margin-left: auto;
}
.prag-mit-zuza-exkluziv-private-touren-contactpage-text14 {
  fill: var(--dl-color-theme-neutral-dark);
  color: var(--dl-color-theme-neutral-dark);
  font-style: italic;
  font-family: "Montserrat";
  font-weight: bold;
}
.prag-mit-zuza-exkluziv-private-touren-contactpage-text17 {
  fill: var(--dl-color-primary-mud);
  color: var(--dl-color-primary-mud);
  font-family: "Fira Sans";
  font-weight: bold;
}
.prag-mit-zuza-exkluziv-private-touren-contactpage-container11 {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.prag-mit-zuza-exkluziv-private-touren-contactpage-container12 {
  width: 100%;
  padding-top: 31px;
}
.prag-mit-zuza-exkluziv-private-touren-contactpage-container13 {
  width: 100%;
  height: 100%;
  align-items: flex-start;
  padding-right: 10px;
}
.prag-mit-zuza-exkluziv-private-touren-contactpage-text18 {
  fill: var(--dl-color-primary-main1);
  color: var(--dl-color-primary-main1);
  font-size: 18px;
  font-family: "Roboto Mono";
}
.prag-mit-zuza-exkluziv-private-touren-contactpage-text19 {
  color: var(--dl-color-primary-mud);
  font-size: 12px;
  font-family: "Roboto Mono";
}
.prag-mit-zuza-exkluziv-private-touren-contactpage-container14 {
  width: 100%;
  height: 100%;
  align-items: flex-start;
}
.prag-mit-zuza-exkluziv-private-touren-contactpage-text20 {
  font-size: 18px;
  font-family: "Roboto Mono";
}
.prag-mit-zuza-exkluziv-private-touren-contactpage-text21 {
  color: var(--dl-color-primary-mud);
  font-size: 12px;
  font-family: "Roboto Mono";
}
.prag-mit-zuza-exkluziv-private-touren-contactpage-container15 {
  width: 268px;
  height: 100%;
  display: flex;
  align-self: center;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.prag-mit-zuza-exkluziv-private-touren-contactpage-container16 {
  display: flex;
  align-items: center;
}
.prag-mit-zuza-exkluziv-private-touren-contactpage-container17 {
  display: flex;
  align-items: center;
  justify-content: center;
}
.prag-mit-zuza-exkluziv-private-touren-contactpage-container18 {
  flex: 0 0 auto;
  display: none;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.prag-mit-zuza-exkluziv-private-touren-contactpage-image3 {
  width: 200px;
  object-fit: cover;
  border-color: var(--dl-color-gray-black);
  border-width: 3px;
  border-radius: var(--dl-radius-radius-round);
}
.prag-mit-zuza-exkluziv-private-touren-contactpage-text23 {
  text-decoration: none;
}
.prag-mit-zuza-exkluziv-private-touren-contactpage-image4 {
  width: 200px;
  display: none;
  box-shadow: 5px 5px 10px 0px #d4d4d4;
  object-fit: cover;
  border-radius: var(--dl-radius-radius-buttonradius);
  text-decoration: none;
}
.prag-mit-zuza-exkluziv-private-touren-contactpage-container19 {
  flex: 0 0 auto;
  display: none;
  align-items: center;
  flex-direction: column;
  padding-bottom: 16px;
  justify-content: center;
}
.prag-mit-zuza-exkluziv-private-touren-contactpage-image5 {
  width: 138px;
  height: 138px;
  object-fit: cover;
  border-color: var(--dl-color-gray-black);
  border-width: 3px;
  border-radius: var(--dl-radius-radius-round);
  text-decoration: none;
}
.prag-mit-zuza-exkluziv-private-touren-contactpage-container20 {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  text-decoration: none;
}
.prag-mit-zuza-exkluziv-private-touren-contactpage-text24 {
  fill: var(--dl-color-primary-mud);
  color: var(--dl-color-primary-mud);
  font-style: italic;
  margin-top: var(--dl-space-space-doubleunit);
  font-family: "Fira Sans";
  padding-top: 0px;
  margin-bottom: var(--dl-space-space-halfunit);
  background-color: #ffffff;
}
.prag-mit-zuza-exkluziv-private-touren-contactpage-image6 {
  width: 200px;
  display: none;
  box-shadow: 5px 5px 10px 0px #d4d4d4;
  object-fit: cover;
  border-radius: var(--dl-radius-radius-buttonradius);
  text-decoration: none;
}
.prag-mit-zuza-exkluziv-private-touren-contactpage-container21 {
  flex: 0 0 auto;
  display: flex;
  align-items: center;
  padding-top: 144px;
  padding-left: 118px;
  flex-direction: column;
  justify-content: center;
}
.prag-mit-zuza-exkluziv-private-touren-contactpage-image7 {
  width: 177px;
  height: 177px;
  object-fit: cover;
  border-color: var(--dl-color-primary-main1);
  border-width: 3px;
  border-radius: var(--dl-radius-radius-round);
}
.prag-mit-zuza-exkluziv-private-touren-contactpage-container22 {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.prag-mit-zuza-exkluziv-private-touren-contactpage-link10 {
  display: contents;
}
.prag-mit-zuza-exkluziv-private-touren-contactpage-text25 {
  fill: var(--dl-color-primary-main1);
  color: var(--dl-color-primary-main1);
  width: 288px;
  font-size: 1em;
  font-style: italic;
  margin-top: var(--dl-space-space-unit);
  font-family: "Montserrat";
  padding-top: 0px;
  text-decoration: none;
  background-color: #ffffffff;
}
.prag-mit-zuza-exkluziv-private-touren-contactpage-link11 {
  display: contents;
}
.prag-mit-zuza-exkluziv-private-touren-contactpage-text26 {
  fill: var(--dl-color-primary-main1);
  color: var(--dl-color-primary-main1);
  width: 288px;
  font-size: 1em;
  font-style: italic;
  margin-top: var(--dl-space-space-unit);
  font-family: "Montserrat";
  padding-top: 0px;
  text-decoration: none;
  background-color: rgb(255, 255, 255);
}
.prag-mit-zuza-exkluziv-private-touren-contactpage-footer {
  color: var(--dl-color-gray-white);
  width: 100%;
  height: 247px;
  display: flex;
  padding: var(--dl-space-space-tripleunit);
  z-index: 100;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.prag-mit-zuza-exkluziv-private-touren-contactpage-menu {
  width: 100%;
  display: flex;
  max-width: 1000px;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.prag-mit-zuza-exkluziv-private-touren-contactpage-logo2 {
  fill: var(--dl-color-primary-main1);
  color: var(--dl-color-primary-main1);
  font-family: "Pano-Regular";
}
.prag-mit-zuza-exkluziv-private-touren-contactpage-links-container2 {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.prag-mit-zuza-exkluziv-private-touren-contactpage-container23 {
  display: flex;
  align-items: flex-start;
  margin-left: 100px;
  flex-direction: column;
}
.prag-mit-zuza-exkluziv-private-touren-contactpage-navlink2 {
  align-self: center;
  text-align: center;
  padding-top: var(--dl-space-space-halfunit);
  padding-bottom: var(--dl-space-space-halfunit);
  text-decoration: none;
}
.prag-mit-zuza-exkluziv-private-touren-contactpage-navlink3 {
  align-self: stretch;
  padding-top: var(--dl-space-space-halfunit);
  padding-bottom: var(--dl-space-space-halfunit);
  text-decoration: none;
}
.prag-mit-zuza-exkluziv-private-touren-contactpage-container24 {
  display: flex;
  margin-top: var(--dl-space-space-halfunit);
  align-items: flex-start;
  margin-left: 100px;
  margin-bottom: var(--dl-space-space-halfunit);
  flex-direction: column;
}
.prag-mit-zuza-exkluziv-private-touren-contactpage-navlink4 {
  margin-top: var(--dl-space-space-halfunit);
  margin-bottom: var(--dl-space-space-halfunit);
  text-decoration: none;
}
.prag-mit-zuza-exkluziv-private-touren-contactpage-text29 {
  margin-top: var(--dl-space-space-halfunit);
  margin-bottom: var(--dl-space-space-halfunit);
  text-decoration: none;
}
.prag-mit-zuza-exkluziv-private-touren-contactpage-follow-container1 {
  display: flex;
  align-items: center;
  margin-left: 50px;
  flex-direction: column;
  justify-content: space-between;
}
.prag-mit-zuza-exkluziv-private-touren-contactpage-text30 {
  text-align: center;
  padding-bottom: var(--dl-space-space-halfunit);
}
.prag-mit-zuza-exkluziv-private-touren-contactpage-icons-container1 {
  width: 100px;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  justify-content: space-between;
}
.prag-mit-zuza-exkluziv-private-touren-contactpage-icon11 {
  fill: var(--dl-color-gray-white);
  width: 24px;
  height: 24px;
}
.prag-mit-zuza-exkluziv-private-touren-contactpage-icon13 {
  fill: var(--dl-color-gray-white);
  width: 24px;
  height: 24px;
}
@media(max-width: 991px) {
  .prag-mit-zuza-exkluziv-private-touren-contactpage-container01 {
    padding-bottom: 233px;
  }
  .prag-mit-zuza-exkluziv-private-touren-contactpage-max-width {
    gap: 32px;
    align-items: center;
    flex-direction: column;
    padding-bottom: 0px;
  }
  .prag-mit-zuza-exkluziv-private-touren-contactpage-container02 {
    width: 100%;
    max-width: 100%;
  }
  .prag-mit-zuza-exkluziv-private-touren-contactpage-container03 {
    width: 100%;
    height: 100%;
    align-items: center;
    flex-direction: column;
    justify-content: flex-start;
    background-color: rgba(58, 58, 58, 0.23);
  }
  .prag-mit-zuza-exkluziv-private-touren-contactpage-container05 {
    align-items: center;
    padding-top: var(--dl-space-space-halfunit);
    padding-left: var(--dl-space-space-tripleunit);
    padding-right: var(--dl-space-space-tripleunit);
    padding-bottom: var(--dl-space-space-halfunit);
  }
  .prag-mit-zuza-exkluziv-private-touren-contactpage-image1 {
    box-shadow: 5px 5px 10px 0px #d4d4d4;
    border-color: var(--dl-color-gray-black);
    border-style: solid;
    border-width: 3px;
    border-radius: var(--dl-radius-radius-round);
  }
  .prag-mit-zuza-exkluziv-private-touren-contactpage-text07 {
    fill: var(--dl-color-primary-main1);
    color: var(--dl-color-primary-main1);
    font-family: Roboto Mono;
  }
  .prag-mit-zuza-exkluziv-private-touren-contactpage-text08 {
    padding-left: var(--dl-space-space-halfunit);
    padding-right: var(--dl-space-space-halfunit);
    background-color: rgb(217, 217, 217);
  }
  .prag-mit-zuza-exkluziv-private-touren-contactpage-container06 {
    align-items: center;
    padding-top: var(--dl-space-space-halfunit);
    padding-left: var(--dl-space-space-tripleunit);
    padding-right: var(--dl-space-space-tripleunit);
    padding-bottom: var(--dl-space-space-halfunit);
  }
  .prag-mit-zuza-exkluziv-private-touren-contactpage-image2 {
    width: 200px;
    height: 200px;
    box-shadow: 5px 5px 10px 0px #d4d4d4;
    border-color: var(--dl-color-gray-black);
    border-width: 3px;
    border-radius: var(--dl-radius-radius-round);
  }
  .prag-mit-zuza-exkluziv-private-touren-contactpage-text09 {
    fill: var(--dl-color-primary-main1);
    color: var(--dl-color-primary-main1);
    font-family: Roboto Mono;
  }
  .prag-mit-zuza-exkluziv-private-touren-contactpage-text10 {
    padding-left: var(--dl-space-space-halfunit);
    padding-right: var(--dl-space-space-halfunit);
    background-color: rgb(217, 217, 217);
  }
  .prag-mit-zuza-exkluziv-private-touren-contactpage-text11 {
    font-style: italic;
    padding-top: 0px;
    padding-bottom: var(--dl-space-space-halfunit);
  }
  .prag-mit-zuza-exkluziv-private-touren-contactpage-text12 {
    fill: var(--dl-color-primary-main1);
    color: var(--dl-color-primary-main1);
    font-family: Pano-Regular;
  }
  .prag-mit-zuza-exkluziv-private-touren-contactpage-container08 {
    display: none;
  }
  .prag-mit-zuza-exkluziv-private-touren-contactpage-text13 {
    fill: var(--dl-color-primary-main1);
    color: var(--dl-color-primary-main1);
    display: none;
    font-family: Roboto Mono;
  }
  .prag-mit-zuza-exkluziv-private-touren-contactpage-container09 {
    width: 746px;
    height: 526px;
    align-items: flex-end;
    padding-bottom: 0px;
    justify-content: center;
  }
  .prag-mit-zuza-exkluziv-private-touren-contactpage-container10 {
    width: 642px;
    height: 100%;
    align-self: center;
    align-items: center;
    margin-left: 0px;
    justify-content: flex-end;
  }
  .prag-mit-zuza-exkluziv-private-touren-contactpage-text14 {
    font-style: italic;
    font-family: Montserrat;
    font-weight: bold;
  }
  .prag-mit-zuza-exkluziv-private-touren-contactpage-text20 {
    fill: var(--dl-color-primary-main1);
    color: var(--dl-color-primary-main1);
    font-size: 18px;
    font-family: Roboto Mono;
  }
  .prag-mit-zuza-exkluziv-private-touren-contactpage-container18 {
    align-items: center;
    padding-top: var(--dl-space-space-halfunit);
    padding-left: var(--dl-space-space-tripleunit);
    padding-right: var(--dl-space-space-tripleunit);
    padding-bottom: var(--dl-space-space-halfunit);
  }
  .prag-mit-zuza-exkluziv-private-touren-contactpage-image3 {
    box-shadow: 5px 5px 10px 0px #d4d4d4;
    border-color: var(--dl-color-gray-black);
    border-style: solid;
    border-width: 3px;
    border-radius: var(--dl-radius-radius-round);
  }
  .prag-mit-zuza-exkluziv-private-touren-contactpage-text22 {
    fill: var(--dl-color-primary-main1);
    color: var(--dl-color-primary-main1);
    font-family: Roboto Mono;
  }
  .prag-mit-zuza-exkluziv-private-touren-contactpage-text23 {
    padding-left: var(--dl-space-space-halfunit);
    padding-right: var(--dl-space-space-halfunit);
    background-color: rgb(217, 217, 217);
  }
  .prag-mit-zuza-exkluziv-private-touren-contactpage-image4 {
    display: none;
  }
  .prag-mit-zuza-exkluziv-private-touren-contactpage-container19 {
    display: flex;
    align-items: center;
    padding-top: 0px;
    padding-left: var(--dl-space-space-tripleunit);
    padding-right: var(--dl-space-space-tripleunit);
    padding-bottom: 72px;
  }
  .prag-mit-zuza-exkluziv-private-touren-contactpage-image5 {
    width: 200px;
    height: 200px;
    box-shadow: 5px 5px 10px 0px #d4d4d4;
    border-color: var(--dl-color-gray-black);
    border-width: 3px;
    border-radius: var(--dl-radius-radius-round);
  }
  .prag-mit-zuza-exkluziv-private-touren-contactpage-container20 {
    padding-bottom: 10px;
  }
  .prag-mit-zuza-exkluziv-private-touren-contactpage-text24 {
    padding-left: var(--dl-space-space-halfunit);
    padding-right: var(--dl-space-space-halfunit);
    background-color: rgb(217, 217, 217);
  }
  .prag-mit-zuza-exkluziv-private-touren-contactpage-image6 {
    display: flex;
    padding-top: 36px;
  }
  .prag-mit-zuza-exkluziv-private-touren-contactpage-container21 {
    display: none;
    align-items: center;
    padding-top: var(--dl-space-space-halfunit);
    padding-left: var(--dl-space-space-tripleunit);
    padding-right: var(--dl-space-space-tripleunit);
    padding-bottom: var(--dl-space-space-halfunit);
  }
  .prag-mit-zuza-exkluziv-private-touren-contactpage-image7 {
    box-shadow: 5px 5px 10px 0px #d4d4d4;
    border-color: var(--dl-color-gray-black);
    border-style: solid;
    border-width: 3px;
    border-radius: var(--dl-radius-radius-round);
  }
  .prag-mit-zuza-exkluziv-private-touren-contactpage-text25 {
    width: 317px;
    padding-left: var(--dl-space-space-halfunit);
    padding-right: var(--dl-space-space-halfunit);
    background-color: rgb(217, 217, 217);
  }
  .prag-mit-zuza-exkluziv-private-touren-contactpage-text26 {
    width: 317px;
    padding-left: var(--dl-space-space-halfunit);
    padding-right: var(--dl-space-space-halfunit);
    background-color: rgb(217, 217, 217);
  }
}
@media(max-width: 767px) {
  .prag-mit-zuza-exkluziv-private-touren-contactpage-right-side {
    display: none;
  }
  .prag-mit-zuza-exkluziv-private-touren-contactpage-burger-menu {
    display: flex;
  }
  .prag-mit-zuza-exkluziv-private-touren-contactpage-container01 {
    padding-bottom: 0px;
  }
  .prag-mit-zuza-exkluziv-private-touren-contactpage-max-width {
    height: 1113px;
  }
  .prag-mit-zuza-exkluziv-private-touren-contactpage-container02 {
    height: 546px;
  }
  .prag-mit-zuza-exkluziv-private-touren-contactpage-image {
    width: 766px;
    height: 766px;
  }
  .prag-mit-zuza-exkluziv-private-touren-contactpage-container08 {
    display: none;
  }
  .prag-mit-zuza-exkluziv-private-touren-contactpage-text13 {
    display: none;
  }
  .prag-mit-zuza-exkluziv-private-touren-contactpage-container09 {
    width: 521px;
    height: 359px;
    padding-bottom: 0px;
  }
  .prag-mit-zuza-exkluziv-private-touren-contactpage-container10 {
    width: auto;
    height: auto;
    align-self: flex-start;
    align-items: flex-start;
    margin-left: 0px;
    margin-right: 0px;
    padding-left: 0px;
    padding-right: 0px;
    padding-bottom: 0px;
    justify-content: flex-start;
  }
  .prag-mit-zuza-exkluziv-private-touren-contactpage-text17 {
    width: auto;
    height: auto;
    text-align: justify;
    padding-top: 32px;
  }
  .prag-mit-zuza-exkluziv-private-touren-contactpage-container11 {
    display: none;
  }
  .prag-mit-zuza-exkluziv-private-touren-contactpage-container15 {
    height: 226px;
    padding-top: 0px;
    margin-bottom: 0px;
    padding-right: 0px;
    padding-bottom: 0px;
    justify-content: flex-start;
  }
  .prag-mit-zuza-exkluziv-private-touren-contactpage-container18 {
    display: flex;
    padding-left: 0px;
    padding-right: 0px;
  }
  .prag-mit-zuza-exkluziv-private-touren-contactpage-image3 {
    display: none;
  }
  .prag-mit-zuza-exkluziv-private-touren-contactpage-text22 {
    display: none;
  }
  .prag-mit-zuza-exkluziv-private-touren-contactpage-text23 {
    display: none;
  }
  .prag-mit-zuza-exkluziv-private-touren-contactpage-image4 {
    width: 501px;
    height: 116px;
    display: flex;
  }
  .prag-mit-zuza-exkluziv-private-touren-contactpage-container19 {
    display: none;
  }
  .prag-mit-zuza-exkluziv-private-touren-contactpage-image5 {
    display: none;
  }
  .prag-mit-zuza-exkluziv-private-touren-contactpage-text24 {
    margin-top: var(--dl-space-space-halfunit);
    padding-top: var(--dl-space-space-halfunit);
    background-color: var(--dl-color-theme-secondary1);
  }
  .prag-mit-zuza-exkluziv-private-touren-contactpage-image6 {
    display: flex;
  }
  .prag-mit-zuza-exkluziv-private-touren-contactpage-image7 {
    display: none;
  }
  .prag-mit-zuza-exkluziv-private-touren-contactpage-text25 {
    fill: var(--dl-color-primary-main1);
    color: var(--dl-color-primary-main1);
    font-size: 2em;
    padding-top: var(--dl-space-space-halfunit);
    background-color: transparent;
  }
  .prag-mit-zuza-exkluziv-private-touren-contactpage-text26 {
    fill: var(--dl-color-primary-main1);
    color: var(--dl-color-primary-main1);
    font-size: 2em;
    padding-top: var(--dl-space-space-halfunit);
    background-color: transparent;
  }
  .prag-mit-zuza-exkluziv-private-touren-contactpage-menu {
    flex-direction: column;
  }
  .prag-mit-zuza-exkluziv-private-touren-contactpage-links-container2 {
    margin-top: var(--dl-space-space-tripleunit);
    margin-bottom: var(--dl-space-space-tripleunit);
    justify-content: space-between;
  }
  .prag-mit-zuza-exkluziv-private-touren-contactpage-container23 {
    margin-left: 0px;
  }
}
@media(max-width: 479px) {
  .prag-mit-zuza-exkluziv-private-touren-contactpage-links-container {
    display: none;
  }
  .prag-mit-zuza-exkluziv-private-touren-contactpage-container01 {
    padding-bottom: 0px;
  }
  .prag-mit-zuza-exkluziv-private-touren-contactpage-max-width {
    padding-bottom: 123px;
  }
  .prag-mit-zuza-exkluziv-private-touren-contactpage-image {
    width: 100%;
  }
  .prag-mit-zuza-exkluziv-private-touren-contactpage-container03 {
    width: 378px;
    height: auto;
    background-color: rgba(58, 58, 58, 0.19);
  }
  .prag-mit-zuza-exkluziv-private-touren-contactpage-container05 {
    display: none;
  }
  .prag-mit-zuza-exkluziv-private-touren-contactpage-text11 {
    padding-top: var(--dl-space-space-unit);
  }
  .prag-mit-zuza-exkluziv-private-touren-contactpage-container08 {
    display: flex;
  }
  .prag-mit-zuza-exkluziv-private-touren-contactpage-text13 {
    color: var(--dl-color-primary-main1);
    display: flex;
    text-align: justify;
    font-family: Roboto Mono;
    font-weight: 900;
  }
  .prag-mit-zuza-exkluziv-private-touren-contactpage-container09 {
    width: 461px;
    padding-bottom: 0px;
    justify-content: center;
  }
  .prag-mit-zuza-exkluziv-private-touren-contactpage-container10 {
    margin-left: var(--dl-space-space-doubleunit);
    margin-right: var(--dl-space-space-doubleunit);
    margin-bottom: var(--dl-space-space-doubleunit);
  }
  .prag-mit-zuza-exkluziv-private-touren-contactpage-text14 {
    padding-top: 22px;
  }
  .prag-mit-zuza-exkluziv-private-touren-contactpage-container15 {
    align-self: center;
    padding-top: 0px;
    padding-right: 0px;
    padding-bottom: 0px;
    justify-content: flex-start;
  }
  .prag-mit-zuza-exkluziv-private-touren-contactpage-container18 {
    display: none;
  }
  .prag-mit-zuza-exkluziv-private-touren-contactpage-image4 {
    display: flex;
    padding-top: 22px;
  }
  .prag-mit-zuza-exkluziv-private-touren-contactpage-container19 {
    display: flex;
    padding-top: 27px;
    padding-bottom: 0px;
  }
  .prag-mit-zuza-exkluziv-private-touren-contactpage-container20 {
    padding-top: 0px;
    padding-bottom: 34px;
  }
  .prag-mit-zuza-exkluziv-private-touren-contactpage-text24 {
    padding-top: 0px;
    background-color: var(--dl-color-theme-secondary1);
  }
  .prag-mit-zuza-exkluziv-private-touren-contactpage-image6 {
    width: 291px;
    height: 67px;
    display: flex;
    padding-top: 0px;
  }
  .prag-mit-zuza-exkluziv-private-touren-contactpage-image7 {
    display: none;
  }
  .prag-mit-zuza-exkluziv-private-touren-contactpage-text25 {
    font-size: 1.3em;
    padding-top: var(--dl-space-space-halfunit);
    background-color: var(--dl-color-theme-secondary1);
  }
  .prag-mit-zuza-exkluziv-private-touren-contactpage-text26 {
    font-size: 1.3em;
    padding-top: var(--dl-space-space-halfunit);
    background-color: var(--dl-color-theme-secondary1);
  }
  .prag-mit-zuza-exkluziv-private-touren-contactpage-footer {
    height: 128px;
  }
  .prag-mit-zuza-exkluziv-private-touren-contactpage-menu {
    height: 166px;
  }
  .prag-mit-zuza-exkluziv-private-touren-contactpage-logo2 {
    display: none;
  }
  .prag-mit-zuza-exkluziv-private-touren-contactpage-links-container2 {
    margin-top: 19px;
    align-items: flex-start;
    justify-content: flex-start;
  }
  .prag-mit-zuza-exkluziv-private-touren-contactpage-container23 {
    align-items: center;
    justify-content: center;
  }
  .prag-mit-zuza-exkluziv-private-touren-contactpage-navlink2 {
    margin-bottom: var(--dl-space-space-halfunit);
  }
  .prag-mit-zuza-exkluziv-private-touren-contactpage-navlink3 {
    margin-bottom: var(--dl-space-space-halfunit);
  }
  .prag-mit-zuza-exkluziv-private-touren-contactpage-container24 {
    height: 100%;
    margin-top: 0px;
    align-items: flex-start;
    margin-left: 50px;
    justify-content: center;
  }
  .prag-mit-zuza-exkluziv-private-touren-contactpage-navlink4 {
    margin-bottom: var(--dl-space-space-halfunit);
  }
  .prag-mit-zuza-exkluziv-private-touren-contactpage-text29 {
    margin-bottom: var(--dl-space-space-halfunit);
  }
  .prag-mit-zuza-exkluziv-private-touren-contactpage-follow-container1 {
    margin-left: 0px;
  }
}

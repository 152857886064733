.prag-mit-zuza-exkluziv-private-touren-touren-container {
  width: 100%;
  display: flex;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
  background-image: linear-gradient(90deg, rgba(252, 0, 255, 0.23) 3.00%,rgba(252, 0, 255, 0) 52.00%,rgba(0, 219, 222, 0.3) 100.00%);
}
.prag-mit-zuza-exkluziv-private-touren-touren-navbar {
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-doubleunit);
  max-width: 1000px;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.prag-mit-zuza-exkluziv-private-touren-touren-navlink {
  display: contents;
}
.prag-mit-zuza-exkluziv-private-touren-touren-logo {
  fill: var(--dl-color-primary-main1);
  color: var(--dl-color-primary-main1);
  font-family: "Pano-Regular";
  text-decoration: none;
}
.prag-mit-zuza-exkluziv-private-touren-touren-right-side {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.prag-mit-zuza-exkluziv-private-touren-touren-links-container {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.prag-mit-zuza-exkluziv-private-touren-touren-navlink1 {
  margin-right: var(--dl-space-space-doubleunit);
  text-decoration: none;
}
.prag-mit-zuza-exkluziv-private-touren-touren-link {
  display: contents;
}
.prag-mit-zuza-exkluziv-private-touren-touren-image {
  width: 200px;
  object-fit: cover;
  text-decoration: none;
}
.prag-mit-zuza-exkluziv-private-touren-touren-burger-menu {
  display: none;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.prag-mit-zuza-exkluziv-private-touren-touren-burger-menu1 {
  width: 24px;
  height: 24px;
}
.prag-mit-zuza-exkluziv-private-touren-touren-mobile-menu {
  top: 0px;
  flex: 0 0 auto;
  left: 0px;
  width: 100%;
  height: 100%;
  display: none;
  padding: var(--dl-space-space-doubleunit);
  z-index: 100;
  position: absolute;
  flex-direction: column;
  justify-content: space-between;
  background-color: #fff;
}
.prag-mit-zuza-exkluziv-private-touren-touren-nav {
  flex: 0 0 auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.prag-mit-zuza-exkluziv-private-touren-touren-top {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-doubleunit);
  flex-direction: row;
  justify-content: space-between;
}
.prag-mit-zuza-exkluziv-private-touren-touren-close-menu {
  flex: 0 0 auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.prag-mit-zuza-exkluziv-private-touren-touren-icon03 {
  width: 24px;
  height: 24px;
}
.prag-mit-zuza-exkluziv-private-touren-touren-right-side1 {
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: space-between;
}
.prag-mit-zuza-exkluziv-private-touren-touren-links-container1 {
  display: flex;
  align-items: flex-start;
  margin-bottom: 16px;
  flex-direction: column;
  justify-content: space-between;
}
.prag-mit-zuza-exkluziv-private-touren-touren-text02 {
  margin-bottom: 8px;
}
.prag-mit-zuza-exkluziv-private-touren-touren-text03 {
  margin-bottom: 8px;
}
.prag-mit-zuza-exkluziv-private-touren-touren-text04 {
  margin-bottom: 8px;
}
.prag-mit-zuza-exkluziv-private-touren-touren-follow-container {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: space-between;
}
.prag-mit-zuza-exkluziv-private-touren-touren-text06 {
  padding-bottom: var(--dl-space-space-halfunit);
}
.prag-mit-zuza-exkluziv-private-touren-touren-icons-container {
  width: 100px;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  justify-content: space-between;
}
.prag-mit-zuza-exkluziv-private-touren-touren-link1 {
  display: contents;
}
.prag-mit-zuza-exkluziv-private-touren-touren-icon05 {
  width: 24px;
  height: 24px;
}
.prag-mit-zuza-exkluziv-private-touren-touren-link2 {
  display: contents;
}
.prag-mit-zuza-exkluziv-private-touren-touren-icon07 {
  width: 24px;
  height: 24px;
}
.prag-mit-zuza-exkluziv-private-touren-touren-link3 {
  display: contents;
}
.prag-mit-zuza-exkluziv-private-touren-touren-icon09 {
  width: 24px;
  height: 24px;
}
.prag-mit-zuza-exkluziv-private-touren-touren-container01 {
  width: 100%;
  align-self: flex-start;
  flex-direction: column;
}
.prag-mit-zuza-exkluziv-private-touren-touren-max-width {
  gap: 64px;
  width: 100%;
  height: 882px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.prag-mit-zuza-exkluziv-private-touren-touren-container02 {
  width: 1200px;
  display: flex;
  align-self: center;
  align-items: center;
  justify-content: center;
}
.prag-mit-zuza-exkluziv-private-touren-touren-container03 {
  max-width: 50%;
  align-self: flex-start;
  align-items: center;
  justify-content: flex-start;
}
.prag-mit-zuza-exkluziv-private-touren-touren-image1 {
  height: 814px;
  margin-top: var(--dl-space-space-halfunit);
}
.prag-mit-zuza-exkluziv-private-touren-touren-container04 {
  width: 1300px;
  align-self: center;
  align-items: flex-start;
  margin-left: var(--dl-space-space-doubleunit);
  margin-right: var(--dl-space-space-doubleunit);
}
.prag-mit-zuza-exkluziv-private-touren-touren-text07 {
  font-family: "Pano-Regular";
}
.prag-mit-zuza-exkluziv-private-touren-touren-container05 {
  width: 100%;
}
.prag-mit-zuza-exkluziv-private-touren-touren-container06 {
  width: 100%;
  height: 100%;
  align-items: flex-start;
}
.prag-mit-zuza-exkluziv-private-touren-touren-text09 {
  font-family: "Montserrat";
}
.prag-mit-zuza-exkluziv-private-touren-touren-container07 {
  width: 100%;
  height: 100%;
  align-items: flex-start;
}
.prag-mit-zuza-exkluziv-private-touren-touren-text11 {
  font-family: "Montserrat";
}
.prag-mit-zuza-exkluziv-private-touren-touren-container08 {
  width: 100%;
}
.prag-mit-zuza-exkluziv-private-touren-touren-container09 {
  width: 100%;
  height: 100%;
  align-items: flex-start;
  text-decoration: none;
}
.prag-mit-zuza-exkluziv-private-touren-touren-text13 {
  width: 123px;
  text-align: left;
  font-family: "Montserrat";
}
.prag-mit-zuza-exkluziv-private-touren-touren-text14 {
  text-align: left;
}
.prag-mit-zuza-exkluziv-private-touren-touren-container10 {
  width: 100%;
  height: 100%;
  align-items: flex-start;
}
.prag-mit-zuza-exkluziv-private-touren-touren-text15 {
  font-family: "Montserrat";
}
.prag-mit-zuza-exkluziv-private-touren-touren-container11 {
  width: 656px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.prag-mit-zuza-exkluziv-private-touren-touren-container12 {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.prag-mit-zuza-exkluziv-private-touren-touren-heading2 {
  align-self: flex-start;
  text-align: left;
  font-family: "Noto Sans";
  padding-top: 48px;
}
.prag-mit-zuza-exkluziv-private-touren-touren-content2 {
  height: auto;
  align-self: flex-start;
  text-align: left;
  padding-bottom: 22px;
}
.prag-mit-zuza-exkluziv-private-touren-touren-text19 {
  height: auto;
  align-self: flex-start;
  text-align: left;
  padding-bottom: 22px;
}
.prag-mit-zuza-exkluziv-private-touren-touren-text20 {
  height: auto;
  align-self: flex-start;
  text-align: left;
  padding-bottom: 22px;
}
.prag-mit-zuza-exkluziv-private-touren-touren-container13 {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  padding-bottom: 38px;
  justify-content: flex-start;
}
.prag-mit-zuza-exkluziv-private-touren-touren-heading21 {
  align-self: flex-start;
  text-align: left;
  font-family: "Noto Sans";
}
.prag-mit-zuza-exkluziv-private-touren-touren-content21 {
  align-self: flex-start;
  text-align: left;
}
.prag-mit-zuza-exkluziv-private-touren-touren-footer {
  color: var(--dl-color-gray-white);
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-tripleunit);
  z-index: 100;
  box-shadow: 5px 5px 10px 0px #000000;
  align-items: center;
  flex-direction: row;
  justify-content: center;
  background-color: var(--dl-color-gray-black);
}
.prag-mit-zuza-exkluziv-private-touren-touren-menu {
  width: 100%;
  display: flex;
  max-width: 1000px;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.prag-mit-zuza-exkluziv-private-touren-touren-navlink3 {
  display: contents;
}
.prag-mit-zuza-exkluziv-private-touren-touren-logo2 {
  fill: var(--dl-color-primary-main1);
  color: var(--dl-color-primary-main1);
  font-family: "Pano-Regular";
  text-decoration: none;
}
.prag-mit-zuza-exkluziv-private-touren-touren-links-container2 {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.prag-mit-zuza-exkluziv-private-touren-touren-container14 {
  display: flex;
  align-items: flex-start;
  margin-left: 100px;
  flex-direction: column;
}
.prag-mit-zuza-exkluziv-private-touren-touren-navlink4 {
  align-self: center;
  text-align: center;
  padding-top: var(--dl-space-space-halfunit);
  padding-bottom: var(--dl-space-space-halfunit);
  text-decoration: none;
}
.prag-mit-zuza-exkluziv-private-touren-touren-link4 {
  align-self: stretch;
  padding-top: var(--dl-space-space-halfunit);
  padding-bottom: var(--dl-space-space-halfunit);
  text-decoration: none;
}
.prag-mit-zuza-exkluziv-private-touren-touren-container15 {
  display: flex;
  margin-top: var(--dl-space-space-halfunit);
  align-items: flex-start;
  margin-left: 100px;
  margin-bottom: var(--dl-space-space-halfunit);
  flex-direction: column;
}
.prag-mit-zuza-exkluziv-private-touren-touren-navlink5 {
  margin-top: var(--dl-space-space-halfunit);
  margin-bottom: var(--dl-space-space-halfunit);
  text-decoration: none;
}
.prag-mit-zuza-exkluziv-private-touren-touren-navlink6 {
  margin-top: var(--dl-space-space-halfunit);
  margin-bottom: var(--dl-space-space-halfunit);
  text-decoration: none;
}
.prag-mit-zuza-exkluziv-private-touren-touren-follow-container1 {
  display: flex;
  align-items: center;
  margin-left: 50px;
  flex-direction: column;
  justify-content: space-between;
}
.prag-mit-zuza-exkluziv-private-touren-touren-text28 {
  text-align: center;
  padding-bottom: var(--dl-space-space-halfunit);
}
.prag-mit-zuza-exkluziv-private-touren-touren-icons-container1 {
  width: 100px;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  justify-content: space-between;
}
.prag-mit-zuza-exkluziv-private-touren-touren-link5 {
  display: contents;
}
.prag-mit-zuza-exkluziv-private-touren-touren-icon11 {
  fill: var(--dl-color-gray-white);
  width: 24px;
  height: 24px;
}
.prag-mit-zuza-exkluziv-private-touren-touren-link6 {
  display: contents;
}
.prag-mit-zuza-exkluziv-private-touren-touren-icon13 {
  fill: var(--dl-color-gray-white);
  width: 24px;
  height: 24px;
}
@media(max-width: 991px) {
  .prag-mit-zuza-exkluziv-private-touren-touren-container01 {
    height: 1217px;
    justify-content: flex-start;
  }
  .prag-mit-zuza-exkluziv-private-touren-touren-max-width {
    gap: 32px;
    align-items: center;
    padding-top: 70px;
    flex-direction: column;
    padding-bottom: var(--dl-space-space-halfunit);
  }
  .prag-mit-zuza-exkluziv-private-touren-touren-container02 {
    width: 990px;
    height: 927px;
    align-self: flex-start;
    align-items: flex-start;
    padding-right: 46px;
  }
  .prag-mit-zuza-exkluziv-private-touren-touren-container03 {
    width: 100%;
    max-width: 100%;
    align-self: flex-start;
  }
  .prag-mit-zuza-exkluziv-private-touren-touren-image1 {
    width: 296px;
    height: 1185px;
  }
  .prag-mit-zuza-exkluziv-private-touren-touren-container04 {
    height: 1500px;
    align-self: flex-start;
    align-items: flex-start;
    margin-left: 0px;
    padding-top: 0px;
    padding-left: 30px;
    justify-content: flex-start;
  }
  .prag-mit-zuza-exkluziv-private-touren-touren-text07 {
    font-family: Pano-Regular;
  }
  .prag-mit-zuza-exkluziv-private-touren-touren-text08 {
    width: 597px;
    height: 50px;
    padding-right: 245px;
  }
  .prag-mit-zuza-exkluziv-private-touren-touren-container05 {
    padding-top: 46px;
  }
  .prag-mit-zuza-exkluziv-private-touren-touren-container11 {
    width: 558px;
    align-items: flex-start;
    justify-content: flex-start;
  }
  .prag-mit-zuza-exkluziv-private-touren-touren-container12 {
    align-self: flex-start;
    padding-top: 52px;
  }
  .prag-mit-zuza-exkluziv-private-touren-touren-heading2 {
    padding-right: 72px;
  }
  .prag-mit-zuza-exkluziv-private-touren-touren-content2 {
    width: 537px;
    padding-right: 34px;
  }
  .prag-mit-zuza-exkluziv-private-touren-touren-heading21 {
    font-family: Noto Sans;
  }
  .prag-mit-zuza-exkluziv-private-touren-touren-content21 {
    text-align: justify;
    padding-right: 53px;
  }
}
@media(max-width: 767px) {
  .prag-mit-zuza-exkluziv-private-touren-touren-right-side {
    display: none;
  }
  .prag-mit-zuza-exkluziv-private-touren-touren-burger-menu {
    display: flex;
  }
  .prag-mit-zuza-exkluziv-private-touren-touren-container01 {
    height: 1688px;
  }
  .prag-mit-zuza-exkluziv-private-touren-touren-max-width {
    height: 880px;
    align-items: center;
    padding-bottom: 0px;
    justify-content: flex-start;
  }
  .prag-mit-zuza-exkluziv-private-touren-touren-container03 {
    width: 313px;
  }
  .prag-mit-zuza-exkluziv-private-touren-touren-image1 {
    width: 458px;
    height: 820px;
  }
  .prag-mit-zuza-exkluziv-private-touren-touren-container04 {
    width: 709px;
    height: 1643px;
    align-items: flex-start;
    margin-left: 0px;
    margin-right: 0px;
    padding-left: var(--dl-space-space-doubleunit);
    padding-right: var(--dl-space-space-doubleunit);
    padding-bottom: var(--dl-space-space-halfunit);
    justify-content: flex-start;
  }
  .prag-mit-zuza-exkluziv-private-touren-touren-text07 {
    width: 353px;
  }
  .prag-mit-zuza-exkluziv-private-touren-touren-text08 {
    padding-right: 110px;
  }
  .prag-mit-zuza-exkluziv-private-touren-touren-container05 {
    width: 401px;
  }
  .prag-mit-zuza-exkluziv-private-touren-touren-text09 {
    padding-top: 48px;
  }
  .prag-mit-zuza-exkluziv-private-touren-touren-container12 {
    width: 378px;
    align-self: flex-start;
    align-items: flex-start;
  }
  .prag-mit-zuza-exkluziv-private-touren-touren-heading2 {
    font-family: Noto Sans;
  }
  .prag-mit-zuza-exkluziv-private-touren-touren-content2 {
    align-self: flex-start;
    text-align: justify;
    padding-right: 144px;
  }
  .prag-mit-zuza-exkluziv-private-touren-touren-text19 {
    align-self: flex-start;
    text-align: left;
    padding-right: 144px;
  }
  .prag-mit-zuza-exkluziv-private-touren-touren-text20 {
    align-self: flex-start;
    text-align: left;
    font-weight: 700;
    padding-right: 144px;
  }
  .prag-mit-zuza-exkluziv-private-touren-touren-text23 {
    color: var(--dl-color-gray-500);
    font-size: 12px;
    font-style: italic;
  }
  .prag-mit-zuza-exkluziv-private-touren-touren-text24 {
    color: var(--dl-color-gray-500);
    font-size: 12px;
    font-style: italic;
  }
  .prag-mit-zuza-exkluziv-private-touren-touren-container13 {
    width: 380px;
    align-self: flex-start;
  }
  .prag-mit-zuza-exkluziv-private-touren-touren-content21 {
    text-align: justify;
    padding-right: 0px;
  }
  .prag-mit-zuza-exkluziv-private-touren-touren-menu {
    flex-direction: column;
  }
  .prag-mit-zuza-exkluziv-private-touren-touren-links-container2 {
    margin-top: var(--dl-space-space-tripleunit);
    margin-bottom: var(--dl-space-space-tripleunit);
    justify-content: space-between;
  }
  .prag-mit-zuza-exkluziv-private-touren-touren-container14 {
    margin-left: 0px;
  }
}
@media(max-width: 479px) {
  .prag-mit-zuza-exkluziv-private-touren-touren-container01 {
    height: 1754px;
    align-items: flex-start;
    padding-bottom: 0px;
  }
  .prag-mit-zuza-exkluziv-private-touren-touren-max-width {
    height: 1941px;
    align-items: center;
    padding-top: 0px;
    justify-content: flex-start;
  }
  .prag-mit-zuza-exkluziv-private-touren-touren-container02 {
    width: 478px;
    height: 1793px;
    padding-right: 0px;
  }
  .prag-mit-zuza-exkluziv-private-touren-touren-container03 {
    display: none;
  }
  .prag-mit-zuza-exkluziv-private-touren-touren-image1 {
    width: 100%;
  }
  .prag-mit-zuza-exkluziv-private-touren-touren-container04 {
    width: 448px;
    height: 1892px;
    align-self: flex-start;
    margin-left: var(--dl-space-space-doubleunit);
    margin-right: var(--dl-space-space-doubleunit);
    margin-bottom: var(--dl-space-space-doubleunit);
    justify-content: flex-start;
  }
  .prag-mit-zuza-exkluziv-private-touren-touren-text07 {
    width: 282px;
    height: 222px;
    font-size: 48px;
    font-family: Pano-Regular;
    font-weight: bold;
    padding-top: 53px;
    padding-bottom: 58px;
  }
  .prag-mit-zuza-exkluziv-private-touren-touren-text08 {
    display: none;
  }
  .prag-mit-zuza-exkluziv-private-touren-touren-text09 {
    fill: var(--dl-color-primary-main1);
    color: var(--dl-color-primary-main1);
  }
  .prag-mit-zuza-exkluziv-private-touren-touren-text13 {
    fill: var(--dl-color-primary-108a14);
    color: var(--dl-color-primary-108a14);
  }
  .prag-mit-zuza-exkluziv-private-touren-touren-container11 {
    width: 100%;
  }
  .prag-mit-zuza-exkluziv-private-touren-touren-container12 {
    height: 526px;
  }
  .prag-mit-zuza-exkluziv-private-touren-touren-heading2 {
    padding-top: 0px;
  }
  .prag-mit-zuza-exkluziv-private-touren-touren-content2 {
    width: 372px;
    padding-right: 28px;
    padding-bottom: 0px;
  }
  .prag-mit-zuza-exkluziv-private-touren-touren-text19 {
    padding-right: 0px;
    padding-bottom: 0px;
  }
  .prag-mit-zuza-exkluziv-private-touren-touren-text20 {
    padding-right: 0px;
    padding-bottom: 0px;
  }
  .prag-mit-zuza-exkluziv-private-touren-touren-text23 {
    color: var(--dl-color-gray-black);
    font-style: italic;
    font-family: Roboto Mono;
  }
  .prag-mit-zuza-exkluziv-private-touren-touren-text24 {
    color: var(--dl-color-gray-black);
  }
  .prag-mit-zuza-exkluziv-private-touren-touren-container13 {
    padding-top: 95px;
  }
  .prag-mit-zuza-exkluziv-private-touren-touren-heading21 {
    font-family: Noto Sans;
  }
  .prag-mit-zuza-exkluziv-private-touren-touren-content21 {
    height: 435px;
    padding-right: 29px;
  }
  .prag-mit-zuza-exkluziv-private-touren-touren-links-container2 {
    margin-top: var(--dl-space-space-doubleunit);
    align-items: flex-start;
    justify-content: flex-start;
  }
  .prag-mit-zuza-exkluziv-private-touren-touren-navlink4 {
    margin-bottom: var(--dl-space-space-halfunit);
  }
  .prag-mit-zuza-exkluziv-private-touren-touren-link4 {
    margin-bottom: var(--dl-space-space-halfunit);
  }
  .prag-mit-zuza-exkluziv-private-touren-touren-container15 {
    height: 100%;
    align-items: flex-start;
    margin-left: 50px;
  }
  .prag-mit-zuza-exkluziv-private-touren-touren-navlink5 {
    margin-bottom: var(--dl-space-space-halfunit);
  }
  .prag-mit-zuza-exkluziv-private-touren-touren-navlink6 {
    margin-bottom: var(--dl-space-space-halfunit);
  }
  .prag-mit-zuza-exkluziv-private-touren-touren-follow-container1 {
    margin-left: 0px;
  }
}
